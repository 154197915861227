import React, { useEffect, useState } from "react";
import {
  showErrorToaster as showErrorToastImport,
  showLocalizedToaster,
} from "../State/actions/toaster";
import { connect } from "react-redux";
import surveysService from "../services/surveysService";
import contentLibraryService from "../services/contentLibraryService";
import ArticlePreview from "../Shared/ContentLibrary/Preview/ArticlePreview";
import SurveyRendererOnePager from "../Shared/Surveys/renderer/SurveyRendererOnePager";
import SurveyRenderer from "../Shared/Surveys/renderer/SurveyRenderer";
import ModalPopup from "../components/ModalPopup";
import MPSBarLoader from "../components/MPSBarLoader";
import Messages from "../Shared/Messages";

const contentInitialState = () => ({
  name: "",
  response: null,
  Component: () => null,
})

const HTMLFormatter = (props) => {
  const { children, showToaster, showErrorToaster, patientId } = props;
  const [content, setContent] = useState(contentInitialState());
  const [contentLoading, setContentLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const submitAnswers = async (responseData) => {
    let statusId = null;

    setContentLoading(true);
    return surveysService
      .submitAnswers(statusId, responseData, patientId)
      .then((response) => {
        showToaster({ content: Messages.survey_submitted }, "success");
      })
      .catch((err) => {
        if (err.message) {
          showErrorToaster(err, err.message);
        } else {
          showToaster({ content: Messages.error }, "error");
        }
        console.log(err);
      })
      .finally(() => setContentLoading(false));
  };

  const showContent = async (contentType, contentName) => {
    let name = "";
    let response = null;
    let Component = null;
    setContentLoading(true);
    setShowModal(true);
    switch (contentType) {
      case "library":
        name = "Content Library";
        response = await contentLibraryService.loadLibraryByName(contentName);
        Component = () => <ArticlePreview article={response} embed />;
        break;
      case "survey":
        name = "Survey";
        response = await surveysService.loadSurveyByName(contentName);
        Component = () =>
          response && response.showInOnePageForWeb ? (
            <SurveyRendererOnePager
              survey={response}
              submitAnswers={submitAnswers}
              previewMode={!patientId}
              embedded
            />
          ) : (
            <SurveyRenderer
              survey={response}
              submitAnswers={submitAnswers}
              previewMode={!patientId}
              embedded
            />
          );
        break;

      default:
        break;
    }
    setContentLoading(false);
    setContent({ name, response, Component });
  };

  const prepareButtons = () => {
    const buttons = document.getElementsByClassName("content-button");
    if (buttons.length) {
      Array.from(buttons).forEach((button) => {
        const contentType = button.className.split(" ");
        const contentName = button.href.split("?name=");
        button.href = "#";
        button.target = "_self";
        button.className = button.className.replace(
          "content-button",
          "formatted-content-button"
        );
        button.addEventListener("click", () =>
          showContent(contentType[1], contentName[1])
        );
      });
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setContent(contentInitialState());
  };

  useEffect(() => {
    prepareButtons();
  }, []);

  return (
    <>
      {children}
      {showModal && (
        <ModalPopup title={content.name} onClose={onCloseModal}>
          {contentLoading ? (
            <div style={{ margin: "100px auto", width: 100 }}>
              <MPSBarLoader />
            </div>
          ) : (
            <content.Component />
          )}
        </ModalPopup>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps, _) => {
  const patientId =
    ownProps.patientId ||
    ownProps.match?.params?.id ||
    state.user?.data?.patientId;
  return {
    patientId,
  };
};

const mapDispatchToProps = {
  showToaster: showLocalizedToaster,
  showErrorToaster: showErrorToastImport,
};

export default connect(mapStateToProps, mapDispatchToProps)(HTMLFormatter);
