import React, { Component } from "react";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";
import MPSTextAreaWithCounters from "../../../components/Forms/MPSTextAreaWithCounters.js";

class QuestionString extends Component {
  addAnswer = () => {
    let { question, setData } = this.props;
    let { answers = [] } = question;
    answers = [...answers, { valueString: null }];
    setData(answers);
  };

  setData = (index, value) => {
    let { question, setData } = this.props;
    let { answers = [] } = question;
    answers[index] = {
      valueString: value,
    };
    setData(answers);
  };

  render() {
    let { question } = this.props;
    let { answers = [{ valueString: null }] } = question;
    const isMultiLine = question.isMultiLine;
    return (
      <div>
        {answers.map((answer, index) => {
          return isMultiLine ? ( //KUMAR added Jul 04, 2024
            <MPSTextAreaWithCounters
              key={index}
              value={answer.valueString || ""}
              onChange={(e) => this.setData(index, e.target.value)}
              defaultValue={question.initialValue}
              className="form-control mb-1 survey-question-multiline"
              rows={6}
              noOfLinesInMultiline={question.noOfLinesInMultiline}
              noOfWordsInMultiline={question.noOfWordsInMultiline}
            />
          ) : (
            <input
              key={index}
              value={answer && answer.valueString ? answer.valueString : ""}
              type="text"
              onChange={(e) => {
                this.setData(index, e.target.value);
              }}
              defaultValue={question.initialValue}
              className="form-control mb-1"
            />
          );
        })}

        {question.isMultipleAnswersPermitted && (
          <MPSButton
            type="link"
            onClick={this.addAnswer}
            style={{ fontWeight: 500 }}
          >
            +
          </MPSButton>
        )}
      </div>
    );
  }
}

export default QuestionString;
