export function getParsedNumericalValue(type, value) {
  if (type === "INTEGER") {
    return parseInt(value);
  }
  if (type === "DECIMAL") {
    return parseFloat(value);
  }

  return value;
}

export function getValueField(type) {
  if (type === "INTEGER" || type === "CHOICE_WITH_SCALE") {
    return "valueInteger";
  } else if (type === "DECIMAL") {
    return "valueDouble";
  } else if (type === "BOOLEAN") {
    return "valueBoolean";
  } else if (type === "DATE") {
    return "valueDate";
  } else if (type === "DATE_TIME") {
    return "valueDateTime";
  } else {
    return "valueString";
  }
}

//FOR CIAN: needs to use reference ranges coming here from QUESTION
export function validateQuestion(survey, group, question, i, that) {
  let answers = question.answers;
  let value =
    answers && answers[0] ? answers[0][getValueField(question.type)] : "";
  let valAvail = (value || value === 0) && (value + "").length >= 1;
  if (question.required && !valAvail) {
    that.setState({
      error: true,
    });
    return false;
  }
  if (question.required && value === "") {
    return false;
  } else if (!question.required && value === "") {
    return true;
  }
  if (
    question.type === "CHOICE" ||
    question.type === "DATE" ||
    question.type === "STRING" ||
    question.type === "DATE_TIME"
  ) {
    return true;
  }
  let ranges = null;
  if (Object.hasOwn(question, "referenceRanges") && question.referenceRanges.length > 0) {
    ranges = question.referenceRanges;
  } else if (Object.hasOwn(group, "referenceRanges")) {
    ranges = group.referenceRanges;
  }
  /*
  let rangeMin = Number.MAX_VALUE;
  let rangeMax = Number.MIN_SAFE_INTEGER;
  let ranges = null;
  if (Object.hasOwn(question, "referenceRanges")) {
    ranges = question.referenceRanges;
  } else if (Object.hasOwn(group, "referenceRanges")) {
    ranges = group.referenceRanges;
  }
  if (ranges != null) {
    for (let i = 0; i < ranges.length; i++) {
      const refRange = ranges[i];
      let refMin = Number.MAX_VALUE;
      let refMax = Number.MIN_SAFE_INTEGER;
      if (Object.hasOwn(refRange, "minDouble")) {
        refMin = refRange.minDouble;
        refMax = refRange.maxDouble;
      } else if (Object.hasOwn(refRange, "min")) {
        refMin = refRange.min;
        refMax = refRange.max;
      } else if (Object.hasOwn(refRange, "minValue")) {
        refMin = refRange.minValue;
        refMax = refRange.maxValue;
      }
      if (refMin < rangeMin) {
        rangeMin = refMin;
      }
      if (refMax > rangeMax) {
        rangeMax = refMax;
      }
    }
  }
  */
  const [rangeMin, rangeMax] = getReferenceRanges(survey, group, question);
  console.log("max: " + rangeMax + "min" + rangeMin);

  if (question.type === "HUMANOID") {
    if (answers && answers[0]) {
      let ans = answers[0];
      if (ans.setHumanoidScoreAsZero) {
        value = true;
      } else {
        if (ans.valueHumanoids && ans.valueHumanoids.length >= 1) {
          value = ans.valueHumanoids;
        }
      }
    }
  }
  value = Number(value);
  console.log("max: " + rangeMax + " min: " + rangeMin);

  if (ranges != null) {
    if (value < rangeMin || value > rangeMax) {
      return false;
    }
  }
  valAvail = (value || value === 0) && (value + "").length >= 1;

  if (question.required && !valAvail) {
    that.setState({ error: true });
    return false;
  } else if (question.type === "DECIMAL" && valAvail) {
    // value = parseFloat(value);
    value = Number(value);
    if (group.referenceRanges) {
      if (value < rangeMin || value > rangeMax) {
        return false;
      }
    }
    if (isNaN(value)) {
      that.setState({ error: true });
      return false;
    }
  } else if (question.type === "INTEGER" && valAvail) {
    // value = parseInt(value);
    value = Number(value);
    if (ranges) {
      if (value < rangeMin || value > rangeMax) {
        return false;
      }
    }
    if (isNaN(value) || value % 1 !== 0) {
      that.setState({ error: true });
      return false;
    }
  }

  //hard coded for peakflow survey
  if (
    survey.name.toLowerCase().indexOf("peakflow") !== -1 &&
    i === 0 &&
    valAvail
  ) {
    if (group.subscale && group.subscale.toLowerCase() === "pef") {
      let parsed = getParsedNumericalValue(question.type, value);
      if (parsed < 100 || parsed > 800) {
        that.setState({ pefError: true });
        return false;
      }
    }

    if (group.subscale && group.subscale.toLowerCase() === "fev1" && valAvail) {
      let parsed = getParsedNumericalValue(question.type, value);
      if (parsed < 1 || parsed > 6) {
        that.setState({ fevError: true });
        return false;
      }
    }
  }
  return true;
}
export function getReferenceRanges(survey, group, question) {
  let rangeMin = Number.MAX_VALUE;
  let rangeMax = Number.MIN_SAFE_INTEGER;
  let ranges = null;
  if (Object.hasOwn(question, "referenceRanges")) {
    ranges = question.referenceRanges;
  } else if (Object.hasOwn(group, "referenceRanges")) {
    ranges = group.referenceRanges;
  }
  if (ranges != null) {
    for (let i = 0; i < ranges.length; i++) {
      const refRange = ranges[i];
      let refMin = Number.MAX_VALUE;
      let refMax = Number.MIN_SAFE_INTEGER;
      if (Object.hasOwn(refRange, "minDouble")) {
        refMin = refRange.minDouble;
        refMax = refRange.maxDouble;
      } else if (Object.hasOwn(refRange, "min")) {
        refMin = refRange.min;
        refMax = refRange.max;
      } else if (Object.hasOwn(refRange, "minValue")) {
        refMin = refRange.minValue;
        refMax = refRange.maxValue;
      }
      if (refMin < rangeMin) {
        rangeMin = refMin;
      }
      if (refMax > rangeMax) {
        rangeMax = refMax;
      }
    }
  }
  return [rangeMin, rangeMax];
}
export function getSurveyDataReadyForSubmission(survey) {
  let groups = survey.items || [];
  groups.forEach((group) => {
    let questions = group.items || [];
    questions.forEach((question) => {
      if (!question.answers || question.answers.length === 0) {
        question.answers = [
          {
            valueString: "NO RESPONSE",
          },
        ];
      }
      question.answers.forEach((answer, index) => {
        let {
          valueString,
          valueInteger,
          valueBoolean,
          valueImage,
          valueDateTime,
          valueDate,
          valueLocation,
          valueDouble,
          valueHumanoids,
        } = answer;
        if (
          !valueString &&
          !valueInteger &&
          !valueBoolean &&
          valueBoolean !== false &&
          !valueImage &&
          !valueDateTime &&
          !valueDate &&
          !valueLocation &&
          !valueDouble &&
          !valueHumanoids
        ) {
          question.answers[index] = {
            valueString: "NO RESPONSE",
          };
        }
      });

      if (question.type === "DATE_TIME") {
        question.answers.forEach((answer) => {
          if (answer.valueDateTime) {
            let valueDateTime = answer.valueDateTime;
            answer.valueDateTime = new Date(
              valueDateTime.date +
                ` ${valueDateTime.hour || "00"}:${valueDateTime.minute || "00"}`
            ).getTime();
          }
        });
      }
    });
  });
}

export function setDataInGroup(groups, gIndex, qIndex, value) {
  groups = [...groups];
  let group = { ...groups[gIndex] };
  let questions = [...group.items];
  questions[qIndex] = {
    ...questions[qIndex],
    answers: value ? (Array.isArray(value) ? value : [{ ...value }]) : [],
  };
  group.items = questions;
  groups[gIndex] = group;
  return groups;
}
