import React from "react";
import { Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Calendar from "./index";
import { isItCountSlot, isItSlot } from "../../Shared/Utils";
import appointmentsService from "../../services/appointmentsService";

class AppointmentsSlotsCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.currentView
    };
  }

  onNavigate = (view, date) => {
    if (view === Views.MONTH) {
      this.navigateMonth(view, date);
    } else if (view === Views.AGENDA) {
      let agendaLength = this.props.agendaLength || 30;
      // let start = moment.utc("2000-01-01").format("DDMMYYYY");
      let start = null;
      if(this.props.events.length > 0){
        start = moment(this.props.events[0].start).format("DDMMYYYY");
        date = this.props.events[0].start;
      }
      else{
        start = moment(date).format("DDMMYYYY")
      }
      let end = moment(date)
        .add(agendaLength, "d")
        .format("DDMMYYYY");
      // date = "2000-01-01T10:58:22.129Z";

      this.fetchAppointmentsSlots(view, date, start, end);
    } else {
      this.navigateWeek(view, date);
    }
  };

  navigateMonth = (view, date) => {
    let start = moment(date)
      .startOf("month")
      .add(-6, "d")
      .format("DDMMYYYY");
    let end = moment(date).endOf("month");
    end = end.add(13, "d").format("DDMMYYYY");
    this.fetchAppointmentsSlots(view, date, start, end);
  };

  navigateWeek = (view, date) => {
    let start = moment(date)
      .startOf("week")
      .format("DDMMYYYY");
    let end = moment(date)
      .endOf("week")
      .format("DDMMYYYY");
    this.fetchAppointmentsSlots(view, date, start, end);
  };

  fetchAppointmentsSlots = (view, date, start, end) => {
    let { start: currentStart, end: currentEnd } = this.state;
    if (start !== currentStart || end !== currentEnd) {
      this.setState(
        {
          view: view,
          date: date,
          start,
          end
        },
        () => {
          this.props.loadAppointmentsAndSlots(start, end, view === Views.MONTH);
        }
      );
    } else {
      this.setState({ view: view, date: date });
    }
  };

  onSelectEvent = event => {
    if (isItCountSlot(event)) {
      let date = event.start;
      let start = moment(date)
        .startOf("week")
        .format("DDMMYYYY");
      let end = moment(date)
        .endOf("week")
        .format("DDMMYYYY");
      this.fetchAppointmentsSlots(Views.DAY, date, start, end);
      return;
    }

    if (this.props.onSelectEvent) {
      this.props.onSelectEvent(event);
    }
  };

  render() {
    let { view, date } = this.state;
    let { events } = this.props;

    let scrollTime = null;
    if (events && events.length >= 1) {
      if (view === "day" && date) {
        events = events.filter(
          e => e.start.toLocaleDateString() === date.toLocaleDateString()
        );
      }

      if (view === "day" || view === "week" || view === "work_week") {
        let first = events[0] ? events[0] : null;
        if (first) {
          scrollTime = moment(first.start).toDate();
          let hours = first.start.getHours();
          scrollTime.setHours(hours - 1, 0, 0);
        }
      }

      let aStatus = appointmentsService.getAppointmentStatusMap();
      let sStatus = appointmentsService.getSlotStatusMap();
      events.forEach(event => {
        if (event.slotStatus != null) {
          event.slotStatusLabel = sStatus[event.slotStatus];
        } else if (event.appointmentStatus != null) {
          event.appointmentStatusLabel = aStatus[event.appointmentStatus];
        }
      });
    }

    if (!scrollTime && view !== "month") {
      scrollTime = new Date();
      scrollTime.setHours(8, 0, 0);
    }

    return (
      <Calendar
        {...this.props}
        onNavigate={this.onNavigate}
        currentView={view}
        onSelectEvent={this.onSelectEvent}
        currentDate={date}
        scrollToTime={scrollTime}
      />
    );
  }
}

export default AppointmentsSlotsCalendar;
