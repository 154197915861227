import React from "react";
import SurveyLocationMap from "./Assessments/SurveyLocationMap";
import { getInitials } from "../utils/strings";

export const GETPATIENTS_FILTERFIELDS = "GETPATIENTS_FILTERFIELDS";
export const GETPATIENTS_SORTFIELDS = "GETPATIENTS_SORTFIELDS";
export const GETPATIENTS_DISPLAYFIELDS = "GETPATIENTS_DISPLAYFIELDS";
export const GETPATIENTS_DISPLAYFIELDS_BASIC =
  "GETPATIENTS_DISPLAYFIELDS_BASIC";
export const GETPATIENTS_DISPLAYFIELDS_CLINICALDATA =
  "GETPATIENTS_DISPLAYFIELDS_CLINICALDATA";
export const GETPATIENTS_DISPLAYFIELDS_REPORT =
  "GETPATIENTS_DISPLAYFIELDS_REPORT";
export const GETTASKS_FILTERFIELDS = "GETTASKS_FILTERFIELDS";
export const GETTASKS_SORTFIELDS = "GETTASKS_SORTFIELDS";
export const GETTASKS_DISPLAYFIELDS = "GETTASKS_DISPLAYFIELDS";

// export const PATIENTS_FILTER = "PATIENTS_FILTER";
// export const PATIENTS_WATCHLIST_FILTER = "PATIENTS_WATCHLIST_FILTER";

export function getCurrentMilestone(carePlan) {
  if (!carePlan) {
    return null;
  }
  return carePlan.carePlanMileStones.filter((m) => {
    return m.current === true;
  })[0];
}

export function getCarePlanReadyForEdit(carePlan) {
  let mileStones = carePlan.carePlanMileStones || carePlan.mileStones || [];
  let mileStonesMap = {},
    milestoneIds = [];
  mileStones.forEach((milestone) => {
    milestone.id = milestone.id || milestone._id;
    mileStonesMap[milestone.id] = milestone;
    milestoneIds.push(milestone.id);
    let actions = milestone.actions || [];
    actions.forEach((action) => {
      action.id = action.id || action._id;
      prepareActivity(action.activity);
      parseCondition(action.condition);
    });
  });

  carePlan.mileStones = mileStonesMap;
  carePlan.keys = milestoneIds;
  return carePlan;
}

export function getStatusCount(statusStats, status) {
  let s = statusStats.filter((stat) => stat.stat === status)[0];
  return s ? s.count : 0;
}

export function getNumberRange(number) {
  if (number < 0) {
    return "< 0";
  }
  if (number >= 0 && number <= 10) {
    return "0 - 10";
  }
  if (number >= 11 && number <= 20) {
    return "11 - 20";
  }
  if (number >= 21 && number <= 30) {
    return "21 - 30";
  }
  if (number >= 31 && number <= 40) {
    return "31 - 40";
  }
  if (number >= 41 && number <= 50) {
    return "41 - 50";
  }
  if (number >= 51 && number <= 60) {
    return "51 - 60";
  }
  if (number >= 61 && number <= 70) {
    return "61 - 70";
  }
  if (number >= 71 && number <= 80) {
    return "71 - 80";
  }
  if (number >= 81 && number <= 90) {
    return "81 - 90";
  }
  if (number >= 91 && number <= 100) {
    return "91 - 100";
  }

  return "101+";
}

export function createAgeRangeMap(data = {}) {
  let keys = Object.keys(data);
  keys.sort((a, b) => {
    return parseInt(a) - parseInt(b);
  });

  let newMap = {};
  keys.forEach((key) => {
    let newKey = getNumberRange(parseInt(key));
    if (newMap[newKey]) {
      newMap[newKey] = newMap[newKey] + data[key];
    } else {
      newMap[newKey] = data[key];
    }
  });

  return newMap;
}
export function augmentActivityIds(activity) {
  if (!activity) {
    return;
  }

  if (activity.template) {
    activity.artifactId = activity.template.value;
    delete activity.template;
  }

  if (activity.surveyId) {
    activity.artifactId = activity.surveyId;
    delete activity.surveyId;
    delete activity.surveyTitle;
  }

  if (activity.libraryId) {
    activity.artifactId = activity.libraryId;
    delete activity.libraryId;
    delete activity.libraryName;
  }

  if (activity.carePlanId) {
    activity.artifactId = activity.carePlanId;
    delete activity.carePlanId;
    delete activity.carePlanTitle;
  }

  if (activity.consentDocumentId) {
    activity.artifactId = activity.consentDocumentId;
    delete activity.consentDocumentId;
    delete activity.consentDocumentTitle;
  }

  if (activity.taskTemplateId) {
    activity.artifactId = activity.taskTemplateId;
    delete activity.taskTemplateId;
    delete activity.taskTemplateTitle;
  }
}

export function formatCondition(condition) {
  if (!condition) {
    return condition;
  }
  let { parameters = [] } = condition;
  condition.parameters = parameters.map((p) => {
    if (p && typeof p === "object") {
      return p.value;
    }
    return p;
  });
  return condition;
}

export function prepareActivity(activity) {
  if (!activity) {
    return;
  }
  if (activity.type === "MESSAGE_TEMPLATE") {
    activity.template = { value: activity.artifactId };
  }
  if (activity.type === "SURVEY") {
    activity.surveyId = activity.artifactId;
  }
  if (activity.type === "LIBRARY") {
    activity.libraryId = activity.artifactId;
  }
  if (activity.type === "ASSIGN_CAREPLAN") {
    activity.carePlanId = activity.artifactId;
  }
  if (activity.type === "CONSENT_DOCUMENT") {
    activity.consentDocumentId = activity.artifactId;
  }

  if (activity.type === "TASK_TEMPLATE") {
    activity.taskTemplateId = activity.artifactId;
  }
}

export function parseCondition(condition) {
  if (!condition) {
    return condition;
  }
  let { parameters = [] } = condition;
  condition.parameters = parameters.map((p) => {
    return { value: p };
  });
  return condition;
}

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export function getParticipantsInfo(participants = []) {
  return participants.map((participant) => {
    if (participant.referenceType === "MASTERDATA_KEY") {
      return participant.masterDataValue.display;
    }
    return participant.displayHumanName
      ? participant.displayHumanName.text
      : "";
  });
}

export function getParticipantsInfoInitials(participants = []) {
  return participants.map((participant) => {
    if (participant.referenceType === "MASTERDATA_KEY") {
      return getInitials(participant.masterDataValue.display);
    }
    return participant.displayHumanName
      ? participant.displayHumanName.text
        ? getInitials(participant.displayHumanName.text)
        : ""
      : "";
  });
}

export function getAppointmentTimeOptions() {
  let options = [];
  for (let hour = 6; hour <= 23; hour++) {
    for (let min = 0; min <= 45; min = min + 15) {
      let hourStr = hour >= 10 ? `${hour}` : `0${hour}`;
      let minStr = min < 10 ? `0${min}` : `${min}`;
      let timeStr = `${hourStr}:${minStr}`;
      options.push({ value: timeStr, label: timeStr });
    }
  }
  // options.push({ value: `23:00`, label: `23:00` });
  return options;
}

export function getHourOptions() {
  let options = [];
  for (let hour = 0; hour <= 23; hour++) {
    let hourStr = hour >= 10 ? `${hour}` : `0${hour}`;
    options.push({ value: hourStr, label: hourStr });
  }
  return options;
}

export function getMinOptions() {
  let options = [];
  for (let min = 0; min <= 59; min++) {
    let minStr = min >= 10 ? `${min}` : `0${min}`;
    options.push({ value: minStr, label: minStr });
  }
  return options;
}

export function getSurveyAnswerValue(itemType, ans) {
  //(typeof itemType, itemType);
  if (itemType === "INTEGER") {
    return ans.valueInteger || ans.valueAsString;
  }
  if (itemType === "DECIMAL") {
    return ans.valueDouble || ans.valueAsString;
  }
  if (itemType === "CHOICE") {
    return ans.valueString;
  }
  if (itemType === "CHOICE_WITH_SCALE") {
    return ans.valueInteger || ans.valueAsString;
  }
  if (itemType === "BOOLEAN") {
    return ans.valueBoolean ? "True" : "False";
  }
  if (itemType === "DATE" && ans.valueDate) {
    return new Date(ans.valueDate).toLocaleDateString();
  }
  if (itemType === "DATE_TIME" && ans.valueDateTime) {
    return new Date(ans.valueDateTime).toLocaleString();
  }
  if (itemType === "LOCATION") {
    return <SurveyLocationMap ans={ans} />;
  }
  if (itemType === "TIME_SERIES_DOUBLE") {
    return ans.valueDouble || ans.valueAsString;
  }
  if (itemType === "TIME_SERIES_INTEGER") {
    return ans.valueInteger || ans.valueAsString;
  }
  if (itemType === "TIME_SERIES_STRING") {
    return ans.valueSring || ans.valueAsString;
  }
  return ans.valueString;
}

export function getSystemField(dataField) {
  if (dataField === "PatientContactPhone") {
    return "PHONE";
  } else if (dataField === "PatientContactTel") {
    return "TEL";
  } else if (dataField === "PatientContactEmail") {
    return "EMAIL";
  }else if (dataField === "GP Email"){
    return "EMAIL";
  }else if (dataField === "GP Phone"){
    return "PHONE";
  }
}

export function getSlotFilter(
  filter = {},
  scheduleId,
  start,
  end,
  countOnly,
  allStatus
) {
  let { location, staffId } = filter;

  if (!scheduleId) {
    scheduleId = filter.scheduleId;
  }

  let params = "";
  if (location) {
    params += `&location=${location}`;
  }
  if (staffId && staffId !== "ALL") {
    params += `&staffId=${staffId}`;
  }
  if (scheduleId) {
    params += `&scheduleId=${scheduleId}`;
  }
  if (start) {
    params += `&start=${start}`;
  }
  if (end) {
    params += `&end=${end}`;
  }
  if (countOnly) {
    params += `&countOnly=true`;
  }
  if (allStatus) {
    params += `&allStatus=${allStatus}`;
  }

  return params;
}

export function getAppointmentsFilter(
  filter = {},
  scheduleId,
  patientId,
  allStatus,
  start,
  end,
  seriesId
) {
  let { location, staffId } = filter;

  if (!scheduleId) {
    scheduleId = filter.scheduleId;
  }

  let params = "";
  if (location) {
    params += `&location=${location}`;
  }
  if (staffId && staffId !== "ALL") {
    params += `&staffId=${staffId}`;
  }

  if (scheduleId) {
    params += `&scheduleId=${scheduleId}`;
  }

  if (patientId) {
    params += `&patientId=${patientId}`;
  }
  if (allStatus) {
    params += `&allStatus=True`;
  }
  if (start) {
    params += `&start=${start}`;
  }
  if (end) {
    params += `&end=${end}`;
  }
  if (seriesId) {
    params += `&seriesId=${seriesId}`;
  }

  return params;
}

export function isUrlValid(url) {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res != null;
}

export function getAcceptableMediaTypes(type) {
  let acceptableMediaTypes = null;
  if (type === "IMAGE") {
    acceptableMediaTypes = ".png,.jpg,.gif";
  } else if (type === "AUDIO") {
    acceptableMediaTypes = ".mp3,.aac";
  } else if (type === "VIDEO") {
    acceptableMediaTypes = ".mp4,.avi";
  } else if (type === "PDF") {
    acceptableMediaTypes = ".pdf";
  }

  return acceptableMediaTypes;
}

export function getNoOfFreeSlots(appointment) {
  let { participants = [], maxPatientsAllowed = 1 } = appointment;
  let patients = participants.filter((p) => p.referenceType === "PATIENT");
  return maxPatientsAllowed - patients.length;
}

export function exportArtifact(artifact) {
  let dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(artifact));
  const link = window.document.createElement("a");
  link.href = dataStr;
  link.setAttribute("download", `${artifact.name}.json`);
  window.document.body.appendChild(link);
  link.click();
}

export const TIME_HOUR_OPTIONS = [
  { value: 0, label: "12 AM" },
  { value: 1, label: "1 AM" },
  { value: 2, label: "2 AM" },
  { value: 3, label: "3 AM" },
  { value: 4, label: "4 AM" },
  { value: 5, label: "5 AM" },
  { value: 6, label: "6 AM" },
  { value: 7, label: "7 AM" },
  { value: 8, label: "8 AM" },
  { value: 9, label: "9 AM" },
  { value: 10, label: "10 AM" },
  { value: 11, label: "11 AM" },
  { value: 12, label: "12 PM" },
  { value: 13, label: "1 PM" },
  { value: 14, label: "2 PM" },
  { value: 15, label: "3 PM" },
  { value: 16, label: "4 PM" },
  { value: 17, label: "5 PM" },
  { value: 18, label: "6 PM" },
  { value: 19, label: "7 PM" },
  { value: 20, label: "8 PM" },
  { value: 21, label: "9 PM" },
  { value: 22, label: "10 PM" },
  { value: 23, label: "11 PM" },
];

export function isItSlot(event) {
  return event.hasOwnProperty("slotStatus");
}

export function isItCountSlot(event) {
  return event.hasOwnProperty("count") && event.hasOwnProperty("statDate");
}

export function isMedicationDocument(document) {
  let title = document.title;
  if (title) {
    title = title.toLowerCase();
    if (title.indexOf("medication") > -1) {
      let contents = document.contents || [];
      if (contents[0] && document.contents[0].format === "IMAGE") {
        return true;
      }
    }
  }
  return false;
}

export const severityColorLevel = (value) => {
  const newValue = value?.trim("").toUpperCase();

  const levels = {
    NONE: "green",
    MILD: "#FFD63F",
    MODERATE: "orange",
    SEVERE: "red",
    "VERY SEVERE": "red",
    0: "green",
    1: "#FFD63F",
    2: "orange",
    3: "red",
  };

  return levels[newValue];
};

export const severityTextLevel = (value) => {
  const levels = {
    0: "None",
    1: "Mild",
    2: "Moderate",
    3: "Severe",
    4: "Very severe"
  };

  return levels[value];
};

export function hexToRGBA(hex, alpha = 1) {
  let r = 0,
    g = 0,
    b = 0;

  // Check if hex is null or not a valid hex string
  if (
    !hex ||
    !(hex.length === 4 || hex.length === 7) ||
    !/^#([0-9A-F]{3}){1,2}$/i.test(hex)
  ) {
    return "rgba(0, 0, 255, 0.4)"; // Default value
  }

  // 3 digits
  if (hex.length === 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];

    // 6 digits
  } else if (hex.length === 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }

  return `rgba(${+r}, ${+g}, ${+b}, ${alpha})`;
}
