import {
  API_CLIENT_ID,
  API_CLIENT_SECRET,
  API_ENDPOINTS,
  getAppURL
} from "../config";
import { API_URL_KEY, http } from "../utils/http";
import { Base64 } from "../utils/utils";
import { store } from "../State/store/configureStore";
import {
  isFacilityAdmin,
  isMpsAdmin,
  isMPSSupportStaff,
  isPatientOrMember,
  isStaff
} from "../security/roles";
import staffService from "./staffService";
import { doE164 } from "../utils/phone";
import StorageService from "./storageService";
import Messages from "../Shared/Messages";
import {
  SET_FACILITY_ID,
  UPDATE_PREFERRED_LOCALE,
  UPDATE_SPACE_INFO
} from "../State/actions/actionTypes";
import I18nProvider from "../../src/services/I18nProvider";
import StatusesService from "./statusesService";
import FeaturesService from "./featuresService";

export const PATIENT_VIEW_TABS = "PATIENT_VIEW_TABS";
export const ONBOARDING_LOCALE = "ONBOARDING_LOCALE";

function handleAuthSuccess(data) {
  if (data.access_token) {
    http.setToken(data.access_token);
    return { token: data.access_token };
  } else {
    throw new Error("No access token granted");
  }
}

function makeURLEncodedAuthRequest(body) {
  return http.request(API_ENDPOINTS.get_token, "post", body, {
    Authorization:
      "Basic " + Base64.encode(API_CLIENT_ID + ":" + API_CLIENT_SECRET),
    "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
  });
}

function convertToFormData(data) {
  return Object.entries(data)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
}

function makeLoginRequest(data) {
  data = convertToFormData(data);
  return new Promise((resolve, reject) => {
    makeURLEncodedAuthRequest(data)
      .then(response => {
        if (!response.error) {
          return resolve(handleAuthSuccess(response));
        }
        if (response.error === "mfa_required") {
          resolve({
            nextStep: "MFA",
            token: response.mfa_token,
            mechanism: response.mfa_mechanism
          });
        } else {
          throw new Error(response.error);
        }
      })
      .catch(error => {
        reject(error.message ? error.message.toString() : null);
      });
  });
}

class UserService {
  logIn(username, password) {
    const data = {
      grant_type: "password",
      client_id: API_CLIENT_ID,
      username: encodeURIComponent(username),
      password: encodeURIComponent(password)
    };
    return makeLoginRequest(data);
  }

  logInWithMfa(code, mfaToken, mfaMechanism) {
    const data = {
      grant_type: "mfa",
      mfa_code: code,
      mfa_token: mfaToken,
      mfa_mechanism: mfaMechanism
    };
    return makeLoginRequest(data);
  }

  updateToken(token) {
    // console.log('UPDATE TOKEN');
    http.setToken(token);
    this.getSpaceInfo(true);
  }

  removeToken() {
    http.removeToken();
  }

  getSpaceInfo(updateStore) {
    return http.request(API_ENDPOINTS.getSpaceInfo()).then(response => {
      response.preferredLocale =
        response.preferredLocale || response.defaultLocale || "en_US";

      if (updateStore) {
        store.dispatch({ type: UPDATE_SPACE_INFO, data: response });
      }
      if (isFacilityAdmin(response.role)) {
        let facilityId = response.facilityId;
        store.dispatch({ type: SET_FACILITY_ID, facilityId });
      }
      return response;
    });
  }

  acceptInvite(
    inviteCode,
    email,
    phone,
    challengeFields,
    preValidationRequired
  ) {
    let data = { inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    if (challengeFields && challengeFields.length > 0) {
      data.challengeFields = challengeFields;
    }
    data.acceptedTermsAndCondition = true;
    if (preValidationRequired) {
      data.acceptedPreValidationTC = true;
    }

    data.locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);

    return http.request(API_ENDPOINTS.acceptInvite(), "post", data);
  }

  fetchSpaceDetailsFromInviteCode(inviteCode, email, phone) {
    let data = { inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    return http.request(
      API_ENDPOINTS.fetchSpaceDetailsFromInviteCode(),
      "post",
      data
    );
  }

  acceptTerms(inviteCode, email, phone) {
    let data = { inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    return http.request(API_ENDPOINTS.acceptTerms(), "post", data);
  }

  confirmCode(confirmationCode, inviteCode, email, phone) {
    let data = { smsCode: confirmationCode, inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    data.acceptedTermsAndCondition = true;

    return http.request(
      API_ENDPOINTS.confirmationCodeWithTerms(),
      "post",
      data
    );
  }

  setCredentials(loginUserId, password, inviteCode, email, phone) {
    let data = { loginUserId, password, inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    data.locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.setCredentials(), "post", data);
  }

  processForgotPasswordRequest(data) {
    return http.request(API_ENDPOINTS.forgotPassword, "post", data);
  }

  resetPassword(data) {
    return http.request(API_ENDPOINTS.resetPassword, "post", data);
  }

  getAllSpaces() {
    return http.request(API_ENDPOINTS.allSpaces).then(data => {
      if (data && data._embedded && data._embedded.spaceDetails) {
        return data._embedded.spaceDetails || [];
      } else {
        return [];
      }
    });
  }

  changeSpace(newSpaceId) {
    return http.request(API_ENDPOINTS.changeSpace(newSpaceId), "post");
  }

  changePassword(password, newPassword) {
    return http.request(API_ENDPOINTS.changePassword, "post", {
      password,
      newPassword
    });
  }

  updateAvailabilityStatus = availabilityStatus => {
    return http.request(API_ENDPOINTS.loadStaffProfile, "patch", {
      availabilityStatus
    });
  };

  loadProfile() {
    let role = store.getState().user.role;
    if (isStaff(role)) {
      return staffService.loadProfile();
    } else if (isFacilityAdmin(role)) {
      return http.request(API_ENDPOINTS.loadFacilityAdminProfile);
    } else if (isMpsAdmin(role)) {
      return http.request(API_ENDPOINTS.loadMpsAdminProfile);
    }
  }

  editProfile(data) {
    let role = store.getState().user.role;
    if (isStaff(role)) {
      return staffService.editProfile(data);
    } else if (isFacilityAdmin(role)) {
      return http.request(
        API_ENDPOINTS.loadFacilityAdminProfile,
        "patch",
        data
      );
    } else if (isMpsAdmin(role)) {
      return http.request(API_ENDPOINTS.loadMpsAdminProfile, "patch", data);
    }
  }

  sendSmsEMailCampaigns(data) {
    return http.request(API_ENDPOINTS.campaigns, "post", data);
  }

  fetchChallengeFields(code) {
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    let api = API_ENDPOINTS.challengeFields(code);
    if (locale) {
      api += "?locale=" + locale;
    }
    return http.request(api);
  }

  fetchChallengeFieldsFromFacilityCode(code) {
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    let api = API_ENDPOINTS.challengeFieldsFromFacilityCode(code);
    if (locale) {
      api += "?locale=" + locale;
    }
    return http.request(api);
  }

  fetchAddPatientProfileFromCode(code) {
    return http.request(
      API_ENDPOINTS.fetchAddPatientProfileFromCode(code),
      "get",
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  fetchOnboardingPatientDataTemplate(patientId, type) {
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    let api = API_ENDPOINTS.onboardingPatientDataTemplate(patientId, type);
    if (locale) {
      api += "&locale=" + locale;
    }
    return http.request(api);
  }

  onboardingConfirmIdentity(
    inviteCode,
    challengeFields,
    acceptedTermsAndCondition,
    onboardingType,
    isVerification,
    verificationData
  ) {
    let data = { acceptedTermsAndCondition };

    if (onboardingType === "signup") {
      data.facilityCode = inviteCode;
    } else {
      data.inviteCode = inviteCode;
    }

    if (challengeFields && challengeFields.length > 0) {
      let clonedChallengeFields = JSON.parse(JSON.stringify(challengeFields));
      for (let i = 0; i < clonedChallengeFields.length; i++) {
        let attribute = clonedChallengeFields[i];
        let av = attribute.value;
        if (attribute.type === "STRING") {
          if (av && av.valueString) {
            av.valueString = av.valueString.trim();
          }
        }

        if (attribute.type === "OBSERVATION") {
          if (
            av &&
            av.valueObservation &&
            av.valueObservation.value &&
            av.valueObservation.value.valueString
          ) {
            av.valueObservation.value.valueString = av.valueObservation.value.valueString.trim();
          }
        }
        if (
          attribute.value &&
          (attribute.dataField === "PatientContactPhone" ||
            attribute.dataField === "PatientContactTel")
        ) {
          let newAV = JSON.parse(JSON.stringify(attribute.value));
          let phone = newAV.valueContactPoints[0].value;
          let cc = newAV.valueContactPoints[0].countryCode;
          delete newAV.valueContactPoints[0].countryCode;
          newAV.valueContactPoints[0].value = doE164(cc, phone);
          attribute.value = newAV;
        }

        // if (attribute.type === "DATE") {
        //   let av = attribute.value;
        //   if (av && av.valueDate) {
        //     av.valueDate = new Date(av.valueDate).getTime();
        //   }
        // }
      }
      data.challengeFields = clonedChallengeFields;
    }

    let api = API_ENDPOINTS.acceptInviteV1;

    if (isVerification) {
      data.email = verificationData.email;
      data.phoneNumber = verificationData.phoneNumber;
      data.patientId = verificationData.patientId;
      api = api + "?verifyContactDetails=true";
    }

    data.locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);

    return http.request(api, "post", data);
  }

  onboardingSetCredentials(
    inviteCode,
    loginUserId,
    password,
    acceptedTermsAndCondition,
    onboardingType,
    patientId,
    attributes
  ) {
    let data = {
      loginUserId,
      password,
      acceptedTermsAndCondition
    };

    if (onboardingType === "signup") {
      data.patientId = patientId;
    } else {
      data.inviteCode = inviteCode;
    }

    if (attributes && attributes.length >= 1) {
      data.patientAttributes = attributes;
    }
    data.locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);

    return http.request(API_ENDPOINTS.setLoginCredentialsV1, "post", data);
  }

  getPatientViewTabs() {
    const intlProvider = I18nProvider.getIntlProvider();

    let userPrivileges =
      store.getState().user && store.getState().user.data
        ? store.getState().user.data.userPrivileges || []
        : [];

    let tabs = StorageService.getFromLocalStorage(PATIENT_VIEW_TABS);

    if (tabs) {
      if (userPrivileges.indexOf("SHOW_AUDIT_LOGS") === -1) {
        let index = tabs.findIndex(tab => tab.id === "audit-logs");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "audit-logs");
        if (index === -1) {
          tabs.push({
            id: "audit-logs",
            label: intlProvider.formatMessage(Messages.audit_logs_uc)
          });
        }
      }

      if (
        FeaturesService.canTasks() &&
        userPrivileges.indexOf("HIDE_TASK_MENU") === -1
      ) {
        let index = tabs.findIndex(tab => tab.id === "tasks");
        if (index === -1) {
          tabs.push({
            id: "tasks",
            label: intlProvider.formatMessage(Messages.tasks_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "tasks");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      if (
        FeaturesService.shouldHideStaffNotes() ||
        userPrivileges.indexOf("HIDE_STAFF_NOTES") !== -1
      ) {
        let sIndex = tabs.findIndex(tab => tab.id === "staff-notes");
        if (sIndex !== -1) {
          tabs.splice(sIndex, 1);
        }

        let pIndex = tabs.findIndex(tab => tab.id === "patient-interactions");
        if (pIndex !== -1) {
          tabs.splice(pIndex, 1);
        }
      } else {
        let sIndex = tabs.findIndex(tab => tab.id === "staff-notes");
        if (sIndex === -1) {
          tabs.push({
            id: "staff-notes",
            label: intlProvider.formatMessage(Messages.staff_notes_uc)
          });
        }

        let pIndex = tabs.findIndex(tab => tab.id === "patient-interactions");
        if (pIndex === -1) {
          if (userPrivileges.indexOf("HIDE_PATIENT_COMMS") === -1) {
            tabs.push({
              id: "patient-interactions",
              label: intlProvider.formatMessage(
                Messages.patient_interactions_uc
              )
            });
          }
        } else {
          if (userPrivileges.indexOf("HIDE_PATIENT_COMMS") !== -1) {
            tabs.splice(pIndex, 1);
          }
        }
      }

      if (
        FeaturesService.canDoAppointments() &&
        userPrivileges.indexOf("HIDE_APPOINTMENT_MENU") === -1
      ) {
        let index = tabs.findIndex(tab => tab.id === "appointments");
        if (index === -1) {
          tabs.push({
            id: "appointments",
            label: intlProvider.formatMessage(Messages.appointments_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "appointments");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      

      if (userPrivileges.indexOf("HIDE_ASSESSMENTS_MENU") === -1) {
        let index = tabs.findIndex(tab => tab.id === "assessments");
        if (index === -1) {
          tabs.push({
            id: "assessments",
            label: intlProvider.formatMessage(Messages.assessments_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "assessments");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      if (FeaturesService.isConsolidatedReportEnabled()) {
        let index = tabs.findIndex(tab => tab.id === "summary");
        if (index === -1) {
          tabs.push({
            id: "summary",
            label: intlProvider.formatMessage(Messages.summary_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "summary");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      if (userPrivileges.indexOf("HIDE_PATIENT_CARE_PLAN") === -1) {
        let index = tabs.findIndex(tab => tab.id === "care-plans");
        if (index === -1) {
          tabs.push({
            id: "care-plans",
            label: intlProvider.formatMessage(Messages.care_plan_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "care-plans");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      if (FeaturesService.canDoMedications()) {
        let index = tabs.findIndex(tab => tab.id === "medications");
        if (index === -1) {
          tabs.push({
            id: "medications",
            label: intlProvider.formatMessage(Messages.medications_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "medications");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      if (FeaturesService.enableChargeItems()) {
        let index = tabs.findIndex(tab => tab.id === "charge-items");
        if (index === -1) {
          tabs.push({
            id: "charge-items",
            label: intlProvider.formatMessage(Messages.charge_items_uc)
          });
        }
      } else {
        let index = tabs.findIndex(tab => tab.id === "charge-items");
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }
    }

    if (!tabs) {
      let conso = FeaturesService.isConsolidatedReportEnabled();

      if (conso && userPrivileges.indexOf("CONSOLIDATED_REPORT_FIRST") !== -1) {
        tabs = [
          {
            id: "summary",
            label: intlProvider.formatMessage(Messages.summary_uc)
          },
          {
            id: "statuses",
            label: intlProvider.formatMessage(Messages.statuses_uc)
          }
        ];
      } else {
        tabs = [
          {
            id: "statuses",
            label: intlProvider.formatMessage(Messages.statuses_uc)
          }
        ];
      }

      if (
        !FeaturesService.shouldHideStaffNotes() &&
        userPrivileges.indexOf("HIDE_STAFF_NOTES") === -1
      ) {
        tabs.push({
          id: "staff-notes",
          label: intlProvider.formatMessage(Messages.staff_notes_uc)
        });
        if (userPrivileges.indexOf("HIDE_PATIENT_COMMS") === -1) {
          tabs.push({
            id: "patient-interactions",
            label: intlProvider.formatMessage(Messages.patient_interactions_uc)
          });
        }
      }

      if (userPrivileges.indexOf("HIDE_PATIENT_CARE_PLAN") === -1) {
        tabs.push({
          id: "care-plans",
          label: intlProvider.formatMessage(Messages.care_plan_uc)
        });
      }

      if (userPrivileges.indexOf("HIDE_ASSESSMENTS_MENU") === -1) {
        tabs.push({
          id: "assessments",
          label: intlProvider.formatMessage(Messages.assessments_uc)
        });
      }

      if (conso && userPrivileges.indexOf("CONSOLIDATED_REPORT_FIRST") === -1) {
        tabs.push({
          id: "summary",
          label: intlProvider.formatMessage(Messages.summary_uc)
        });
      }

      if (
        FeaturesService.canTasks() &&
        userPrivileges.indexOf("HIDE_TASK_MENU") === -1
      ) {
        tabs.push({
          id: "tasks",
          label: intlProvider.formatMessage(Messages.tasks_uc)
        });
      }

      if (
        FeaturesService.showEpisodesOfCare() &&
        userPrivileges.indexOf("SHOW_EPISODES_OF_CARE") === -1
      )
      tabs.push({
        id: "episodes-of-care",
        label: intlProvider.formatMessage(Messages.episodes_of_care)
      });

      if (
        FeaturesService.canDoAppointments() &&
        userPrivileges.indexOf("HIDE_APPOINTMENT_MENU") === -1
      ) {
        tabs.push({
          id: "appointments",
          label: intlProvider.formatMessage(Messages.appointments_uc)
        });
      }

      tabs.push({
        id: "usage",
        label: intlProvider.formatMessage(Messages.usage_uc)
      });

      if (userPrivileges.indexOf("SHOW_AUDIT_LOGS") !== -1) {
        tabs.push({
          id: "audit-logs",
          label: intlProvider.formatMessage(Messages.audit_logs_uc)
        });
      }

      if (FeaturesService.canDoMedications()) {
        tabs.push({
          id: "medications",
          label: intlProvider.formatMessage(Messages.medications_uc)
        });
      }

      if (FeaturesService.enableChargeItems()) {
        tabs.push({
          id: "charge-items",
          label: intlProvider.formatMessage(Messages.charge_items_uc)
        });
      }
    }
  

    return tabs;
  }

  storePatientViewTabs(tabs) {
    StorageService.addToLocalStorage(PATIENT_VIEW_TABS, tabs);
  }

  signupPatient = data => {
    data.locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.patientSignUp, "post", data);
  };

  confirmEmail = (confirmationCode, linkType) => {
    // emailConfirmation: code => `/api/signup/confirm/${code}`,
    let api =
      API_ENDPOINTS.emailConfirmation(confirmationCode) + "?cp=" + linkType;
    return http.request(api, "post");
  };

  validateSignUpField = data => {
    data.locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.signUpValidation, "post", data);
  };

  fetchSignUpProfileFromCode(code) {
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    let api = API_ENDPOINTS.signupProfileFromCode(code);
    if (locale) {
      api += "?locale=" + locale;
    }
    return http.request(api);
  }

  setServerURL(url) {
    http.setBaseURL(url);
    StorageService.addToLocalStorage(API_URL_KEY, url);
  }

  unsubscribeNotifications(region, data) {
    return http.request(API_ENDPOINTS.unsubscribe, "post", data);
  }

  updatePreferredLocale(locale) {
    store.dispatch({
      type: UPDATE_PREFERRED_LOCALE,
      data: { preferredLocale: locale }
    });
    return http.request(API_ENDPOINTS.preferredLocale(locale), "post", {});
  }

  createNewVideoCallRoom(patientId, data = {}, type) {
    return http.request(API_ENDPOINTS.videoRoom(patientId, type), "post", data);
  }

  sendVideoCallStatusMessage = (userRole, patientId, roomName) => {
    let appURL = getAppURL();

    let submitData = {
      title: "Video call",
      type: "STATUS_UPDATE",
      senderCategory: "PATIENT",
      visibility: "ALL",
      detailMessage: `<p class="md-block-unstyled">Staff has initiated a video call with you. Please click <a class="md-inline-link" href="${appURL}/app/video-call/${roomName}" target="_blank" rel="opener">here</a> to join. <a class="md-inline-link" href="${appURL}/video-call/${roomName}" target="_blank" rel="noopener noreferrer">This</a> is for staff.</p>`
    };

    StatusesService.add(userRole, patientId, submitData);
  };

  joinVideoRoom(roomName) {
    return http.request(API_ENDPOINTS.joinRoom(roomName), "post", {});
  }

  joinVideoRoomAuth(roomName) {
    return http.request(API_ENDPOINTS.joinRoomAuth(roomName), "post", {});
  }

  unjoinVideoRoomAuth(roomName) {
    return http.request(API_ENDPOINTS.unjoinRoomAuth(roomName), "post", {});
  }

  fetchVideoRoom(roomName) {
    return http.request(API_ENDPOINTS.videoRoomDetails(roomName));
  }

  getCareTeams(forOrg) {
    let api = API_ENDPOINTS.careTeamsForAll() + "?page=0&size=500";
    if (forOrg) {
      api += "&forOrg=" + forOrg;
    }
    return http.request(api).then(data => {
      if (data && data._embedded && data._embedded.careTeams) {
        return data._embedded.careTeams || [];
      } else {
        return [];
      }
    });
  }

  getChannelTabs() {
    const intlProvider = I18nProvider.getIntlProvider();
    let tabs = [
      {
        id: "statuses",
        label: intlProvider.formatMessage(Messages.statuses_uc)
      }
    ];

    return tabs;
  }

  completePreValidationForInviteFlow(onboardingType, inviteCode) {
    if (onboardingType !== "invite" || !inviteCode) {
      return;
    }

    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
      inviteCode,
      userAction: "PRE_VALIDATION",
      locale
    });
  }

  completeSignupIntroForInviteFlow(onboardingType, inviteCode) {
    if (onboardingType !== "invite" || !inviteCode) {
      return;
    }
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
      inviteCode,
      userAction: "INTRO",
      locale
    });
  }

  completeSignupConsentForInviteFlow(onboardingType, inviteCode) {
    if (onboardingType !== "invite" || !inviteCode) {
      return;
    }
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
      inviteCode,
      userAction: "CONSENT",
      locale
    });
  }

  // acceptInviteForInviteFlow(inviteCode) {
  //   if (!inviteCode) {
  //     return;
  //   }
  //   return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
  //     inviteCode,
  //     userAction: "ACCEPT_INVITE"
  //   });
  // }

  dataEnteredByPatientForInviteFlow(inviteCode) {
    if (!inviteCode) {
      return;
    }
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
      inviteCode,
      userAction: "DATA_SUBMITTED",
      locale
    });
  }

  doLogout() {
    return http.request(API_ENDPOINTS.logout(), "post", {});
  }

  optInForMfa(mechanism) {
    return http.request(API_ENDPOINTS.mfaStatus(), "post", {
      mfa_mechanism: mechanism
    });
  }

  optOutForMfa() {
    return http.request(API_ENDPOINTS.mfaStatus(), "post", {
      mfa_mechanism: "OPTED_OUT_2FA"
    });
  }

  // setupCredentialsForInviteFlow(inviteCode) {
  //   if (!inviteCode) {
  //     return;
  //   }
  //   return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
  //     inviteCode,
  //     userAction: "SET_UP_CREDENTIALS"
  //   });
  // }

  identifySelfSignupPatient(facilityCode, challengeFields) {
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.identifySelfSignupPatient(), "post", {
      facilityCode,
      challengeFields,
      locale
    });
  }

  onboardingSetCredentialsV2(patientId, loginUserId, password) {
    let data = {
      patientId: patientId,
      loginUserId,
      password,
      acceptedTermsAndCondition: true,
      locale: StorageService.getFromSessionStorage(ONBOARDING_LOCALE)
    };
    return http.request(API_ENDPOINTS.setLoginCredentialsV2, "post", data);
  }

  addUserEvent(event) {
    let role = store.getState().user.role;
    if (!isPatientOrMember(role)) {
      return;
    }

    return http.request(API_ENDPOINTS.userEvents(), "post", event);
  }

  addOnboardingEvent(inviteCode, event) {
    let locale = StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
    return http.request(API_ENDPOINTS.inviteOnboardingEvents(), "post", {
      inviteCode,
      value: event,
      locale
    });
  }
  invalidateSessions(field, value) {
    return http.request(API_ENDPOINTS.forceRelogin(), "post", {
      [field]: value
    });
  }

  getAccessToken(type, roomName, auth) {
    if (!!auth) {
      return http.request(API_ENDPOINTS.accessTokensAuth(type));
    }
    return http.request(API_ENDPOINTS.accessTokens(type, roomName));
  }

  revokeWebexAccessToken() {
    return http.request(API_ENDPOINTS.revokeWebexAccess(),"DELETE");
  }

  fetchAccessToken(type, code) {
    return http.request(API_ENDPOINTS.accessTokenFromCode(type, code));
  }

  addParticipantsToVideoCall(data) {
    return http.request(API_ENDPOINTS.addVideoParticipants(), "post", data);
  }

  endVideoCall(data) {
    return http.request(API_ENDPOINTS.endVideoCall(), "post", data);
  }
}

const userService = new UserService();
export default userService;
