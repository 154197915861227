// @flow

import {
  DISMISS_ERROR,
  LOAD_PATIENT,
  LOAD_PATIENTS,
  LOAD_PATIENT_DONE,
  LOG_OUT,
  PATIENTS_LOADED,
  PATIENTS_RESET_LOADING,
  PATIENT_ADD,
  PATIENT_ADD_DONE,
  PATIENT_ADD_ERROR,
  PATIENT_UPDATE,
  PATIENT_UPDATE_DONE,
  SET_PATIENTS_FILTER,
  PATIENTS_RESET_TO_MODE,
  SET_ACTIVE_PATIENT,
  ADD_TO_FILTER_FROM_DASHBOARD,
  REFRESH_PATIENTS,
  SAVED_SEARCHES_LOADED,
  LOG_IN_SUCCESS
} from "../actions/actionTypes";
import { REHYDRATE } from "redux-persist";
import { findWithAttr } from "../../utils/utils";

// const PATIENTS_ALL_FILTER = "PATIENTS_ALL_FILTER";
// const PATIENTS_WATCHLIST_FILTER = "PATIENTS_WATCHLIST_FILTER";
// const PATIENTS__DASHBOARD_FILTER = "PATIENTS__DASHBOARD_FILTER";

const initialStateWoFilter = {
  mode: "all",
  fresh: true,
  list: [],
  loading: false,
  page: 0,
  loadMore: true,
  error: false,
  addSuccess: false,
  inviteSuccess: false,
  activePatientId: null
};

const initialState = {
  mode: "all",
  fresh: true,
  allFilter: null,
  watchlistFilter: null,
  dashboardFilter: null,
  list: [],
  loading: false,
  page: 0,
  loadMore: true,
  error: false,
  addSuccess: false,
  inviteSuccess: false,
  activePatientId: null
};

const patientsReducer = (state = initialState, action) => {
  let list, index;
  switch (action.type) {
    case PATIENTS_RESET_LOADING:
      return {
        ...state,
        list: [],
        loading: false,
        page: 0,
        loadMore: true,
        error: false,
        addSuccess: false,
        inviteSuccess: false,
        refresh: false
      };

    case PATIENT_UPDATE:
    case PATIENT_ADD:
    case LOAD_PATIENT:
    case LOAD_PATIENTS:
      return { ...state, loading: true };

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case PATIENT_ADD_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case LOAD_PATIENT_DONE:
    case PATIENT_UPDATE_DONE:
    case PATIENT_ADD_DONE:
      list = state.list.slice(0);

      const patientId = action.data.patientId || -1;
      index = findWithAttr(list, "patientId", patientId);

      // not found then return prev state
      if (index === -1)
        return {
          ...state,
          loading: false,
          list: [action.data].concat(list),
          addPatientId: patientId,
          addSuccess:
            action.type === PATIENT_ADD_DONE ||
            action.type === PATIENT_UPDATE_DONE
        };

      // if found, replace whole object
      list[index] = action.data;

      return {
        ...state,
        loading: false,
        list: list,
        addPatientId: patientId,
        addSuccess:
          action.type === PATIENT_ADD_DONE ||
          action.type === PATIENT_UPDATE_DONE
      };

    case PATIENTS_LOADED:
      return {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        total: action.total,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.list)
      };

    case LOG_IN_SUCCESS:
      return {
        ...state,
        fresh: true
      };

    case SET_PATIENTS_FILTER:
      let mode = action.mode;
      let newState = {
        ...state,
        fresh: false
      };
      if (mode === "all") {
        action.filter.searchTerm = "";
        // storageService.addToLocalStorage(
        //   PATIENTS_ALL_FILTER + "_" + action.staffId,
        //   action.filter
        // );
        newState.allFilter = action.filter;
      } else if (mode === "watchlist") {
        // storageService.addToLocalStorage(
        //   PATIENTS_WATCHLIST_FILTER,
        //   action.filter
        // );
        newState.watchlistFilter = action.filter;
      }
      return newState;

    case ADD_TO_FILTER_FROM_DASHBOARD:
      let filter =
        state.dashboardFilter || getDashboardFilterFromAllFilter(state) || {};
      if (
        filter.uiFieldsWithoutOperators &&
        filter.uiFieldsWithoutOperators.length >= 1
      ) {
        return getStateAfterAddingToFilter(
          state,
          action,
          "uiFieldsWithoutOperators"
        );
      } else if (
        filter.uiFieldsWithOperators &&
        filter.uiFieldsWithOperators.length >= 1
      ) {
        return getStateAfterAddingToFilter(
          state,
          action,
          "uiFieldsWithOperators"
        );
      } else {
        return { ...state };
      }

    case REFRESH_PATIENTS:
      return { ...state, fresh: true };

    case PATIENTS_RESET_TO_MODE:
      let rMode = action.mode;
      let dNewState = {
        ...initialStateWoFilter,
        savedSearches: state.savedSearches,
        mode: rMode
      };

      if (rMode === "all") {
        dNewState.allFilter = null;
      } else if (rMode === "watchlist") {
        dNewState.watchlistFilter = null;
      } else if (rMode === "dashboard") {
        dNewState.dashboardFilter = null;
      }
      return dNewState;

    case SET_ACTIVE_PATIENT:
      return { ...state, activePatientId: action.patientId };

    case LOG_OUT:
      return initialState;

    case REHYDRATE:
      // let storedAllFilter = null;
      // if (
      //   action.payload &&
      //   action.payload.user &&
      //   action.payload.user.data &&
      //   action.payload.user.data.staffId
      // ) {
      //   storedAllFilter = storageService.getFromLocalStorage(
      //     PATIENTS_ALL_FILTER + "_" + action.payload.user.data.staffId
      //   );
      // }
      // let storedWatchListFilter = storageService.getFromLocalStorage(
      //   PATIENTS_WATCHLIST_FILTER
      // );
      // let storedDashboardFilter = storageService.getFromLocalStorage(
      //   PATIENTS__DASHBOARD_FILTER
      // );
      if (action.payload && action.payload.patients) {
        return {
          ...state,
          ...action.payload.patients,
          // allFilter: storedAllFilter,
          // watchlistFilter: storedWatchListFilter,
          // dashboardFilter: storedDashboardFilter,
          loading: false
        };
      }
      return {
        ...state
        // allFilter: storedAllFilter
        // watchlistFilter: storedWatchListFilter,
        // dashboardFilter: storedDashboardFilter
      };

    case SAVED_SEARCHES_LOADED:
      return {
        ...state,
        savedSearches: action.savedSearches,
        savedSearchesLoaded: true
      };

    default:
      return state;
  }
};

function getStateAfterAddingToFilter(state, action, uiFieldsName) {
  let filter =
    state.dashboardFilter || getDashboardFilterFromAllFilter(state) || {};
  let uiFields = filter[uiFieldsName];
  uiFields.forEach(uiField => {
    uiField.value = null;
  });
  action.fields.forEach(aField => {
    let index = uiFields.findIndex(field => field.name === aField.fieldName);

    if (index !== -1) {
      let filterField = uiFields[index];
      filterField.value = {
        valueObservation: {
          value: {
            valueString: aField.fieldValue
          },
          code: {
            code: filterField.observationCode.code
          }
        }
      };
      uiFields[index] = {
        ...filterField
      };
      filter[uiFieldsName] = [...uiFields];
    }
  });

  // storageService.addToLocalStorage(PATIENTS__DASHBOARD_FILTER, filter);
  return {
    ...state,
    dashboardFilter: {
      ...filter
    },
    fresh: true
  };
}

function getDashboardFilterFromAllFilter(state) {
  let allFilter = state.allFilter || {};
  let uiFieldsWithoutOperators = allFilter.uiFieldsWithoutOperators || [];
  let uiFieldsWithOperators = allFilter.uiFieldsWithOperators || [];

  if (uiFieldsWithoutOperators.length >= 1) {
    uiFieldsWithoutOperators.forEach(field => {
      delete field.value;
    });
  }

  if (uiFieldsWithOperators.length >= 1) {
    uiFieldsWithOperators.forEach(field => {
      delete field.value;
    });
  }

  return allFilter;
}

export default patientsReducer;
