import React, { Component } from "react";
import JointsHumanoid from "../../../../components/Humanoids/JointsHumanoid";

class Humanoid extends Component {
  constructor(props) {
    super(props);
    this.state = { question: props.question };
  }

  ignoreClick = () => {};

  render() {
    let { humanoidType = "JOINTS" } = this.props;
    let HumanoidComponent = null;
    if (humanoidType === "JOINTS") {
      HumanoidComponent = JointsHumanoid;
    } else if (humanoidType === "NEW_HUMANOID_TYPE") {
    }

    if (HumanoidComponent)
      return (
        <HumanoidComponent
          valueHumanoids={this.props.valueHumanoids}
          submitted={this.props.submitted}
          selectable={this.props.selectable}
          setData={this.props.setData}
          readOnlyHumanoid={this.props.readOnlyHumanoid}
          setHumanoidScoreAsZero={this.props.setHumanoidScoreAsZero}
        />
      );
    return <div>Humanoid is not supported for this question.</div>;
  }
}

export default Humanoid;
