import React, { PureComponent, useEffect, useState } from "react";
import SurveyRendererContainer from "../../../Shared/Surveys/renderer/SurveyRendererContainer";
import Text from "../../../components/Text";
import Messages from "../../../Shared/Messages";
const ExternalSurveySubmission = ({ patientId, surveyId }) => {
  const [submitted, setSubmitted] = useState(false);

  if (!submitted) {
    return (
      <SurveyRendererContainer
        artifactId={surveyId}
        patientId={patientId}
        onComplete={() => setSubmitted(true)}
        external
        embedded
      />
    );
  } else {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "80px" }}
      >
        <Text content={Messages.thanks_external_submission} />
      </div>
    );
  }
};

export default ExternalSurveySubmission;
