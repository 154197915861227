import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import classnames from "classnames";
import MPSBarLoader from "../../components/MPSBarLoader";
import RegionSelector from "../RegionSelector";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();
const EVENT_KEY_DOWN = "keydown";
const EVENT_KEY_UP = "keyup";

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCapsLockActive: false,
      user: "",
      pass: ""
    };
  }

  componentDidMount() {
    this.props.onResetLoading();
    let pass = document.getElementById("login-txt-pwd");
    pass.addEventListener(EVENT_KEY_DOWN, this.wasCapsLockActivated);
    pass.addEventListener(EVENT_KEY_UP, this.wasCapsLockDeactivated);
  }

  componentWillUnmount() {
    let pass = document.getElementById("login-txt-pwd");
    pass.removeEventListener(EVENT_KEY_DOWN, this.wasCapsLockActivated);
    pass.removeEventListener(EVENT_KEY_UP, this.wasCapsLockDeactivated);
  }

  wasCapsLockActivated = event => {
    if (
      event.getModifierState &&
      event.getModifierState("CapsLock") &&
      this.state.isCapsLockActive === false
    ) {
      this.setState({ isCapsLockActive: true });
    }
  };

  wasCapsLockDeactivated = event => {
    if (
      event.getModifierState &&
      !event.getModifierState("CapsLock") &&
      this.state.isCapsLockActive === true
    ) {
      this.setState({ isCapsLockActive: false });
    }
  };

  onLogin = e => {
    e.preventDefault();
    this.props.onLogin(this.state.user, this.state.pass);
  };

  onUpdateUser = e => {
    this.setState({ user: e.target.value });
  };

  onUpdatePass = e => {
    this.setState({ pass: e.target.value });
  };

  toggleViewPassword = () => {
    this.setState({ viewPassword: !this.state.viewPassword });
  };

  render() {
    let { loading, loginError } = this.props;
    let disabled = loading;

    let { viewPassword } = this.state;

    return (
      <form onSubmit={this.onLogin}>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText
              className={classnames(loginError ? "is-invalid" : "")}
              style={{
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4
              }}
            >
              <i className="fa fa-user" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            value={this.state.user}
            onChange={this.onUpdateUser}
            type="text"
            autoCapitalize="none"
            placeholder={intl.formatMessage(Messages.user_name)}
            disabled={disabled}
            className={classnames(loginError ? "is-invalid" : "")}
            id="login-txt-username"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }}
          />
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText
              className={classnames(loginError ? "is-invalid" : "")}
              style={{
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4
              }}
            >
              <i className="fa fa-lock" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            value={this.state.pass}
            onChange={this.onUpdatePass}
            type={viewPassword ? "text" : "password"}
            placeholder={intl.formatMessage(Messages.password)}
            disabled={disabled}
            autoCapitalize="none"
            className={classnames(loginError ? "is-invalid" : "")}
            id="login-txt-pwd"
            style={{
              borderRadius: 0
            }}
          />
          <div className="input-group-append">
            <span
              className="input-group-text cursor-pointer"
              onClick={this.toggleViewPassword}
              style={{
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4
              }}
            >
              <i className={`fa ${viewPassword ? "fa-eye-slash" : "fa-eye"}`} />
            </span>
          </div>
        </InputGroup>

        <div className="mb-4 mt-1 text-info" style={{ fontWeight: 500 }}>
          {this.state.isCapsLockActive && (
            <Text content={Messages.caps_lock_on} />
          )}
        </div>

        <Row>
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-end"
          >
            {loading ? (
              <div
                className="d-flex align-items-center"
                style={{ width: 75, height: 37 }}
              >
                <MPSBarLoader width={75} />
              </div>
            ) : (
              <Button
                color="primary"
                className="px-4"
                disabled={disabled}
                id="login-btn-submit"
                style={{
                  width: "100%",
                  padding: "0.5rem 0.75rem",
                  fontWeight: 600
                }}
              >
                <Text content={Messages.login} />
              </Button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default LoginForm;
