import React, { Component } from "react";
import { Input } from "reactstrap";
import { generateUIAttributeNameText } from "../Utils";
import Messages from "../../../Messages";
import { injectIntl } from "react-intl";

class HumanNameAttribute extends Component {
  static getDisplayValue(av) {
    return av.valueHumanName
      ? generateUIAttributeNameText(av.valueHumanName)
      : null;
  }

  static getDisplayValueInFilter(av) {
    return av.valueHumanName ? av.valueHumanName.given : null;
  }

  render() {
    const { intl, attributeValue, setData } = this.props;
    console.log(intl.formatMessage(Messages.first_name));

    attributeValue.valueHumanName = attributeValue.valueHumanName || {};
    let hnVal = attributeValue.valueHumanName;
    return (
      <div className="d-flex">
        <Input
          className="ui-attribute-el"
          type="select"
          value={hnVal.prefix}
          onChange={(e) => {
            attributeValue.valueHumanName = attributeValue.valueHumanName || {};
            attributeValue.valueHumanName.prefix = e.target.value;
            setData(attributeValue);
          }}
          placeholder={"Prefix"}
          onBlur={this.props.onBlur}
        >
          <option value="">{intl.formatMessage(Messages.not_set)} </option>
          <option value={intl.formatMessage(Messages.sal_ms)}>
            {intl.formatMessage(Messages.sal_ms)}
          </option>
          <option value={intl.formatMessage(Messages.sal_miss)}>
            {intl.formatMessage(Messages.sal_miss)}
          </option>
          <option value={intl.formatMessage(Messages.sal_mrs)}>
            {intl.formatMessage(Messages.sal_mrs)}
          </option>
          <option value={intl.formatMessage(Messages.sal_mr)}>
            {intl.formatMessage(Messages.sal_mr)}
          </option>
          <option value={intl.formatMessage(Messages.sal_master)}>
            {intl.formatMessage(Messages.sal_master)}
          </option>
          <option value={intl.formatMessage(Messages.sal_fr)}>
            {intl.formatMessage(Messages.sal_fr)}
          </option>
          <option value={intl.formatMessage(Messages.sal_rev)}>
            {intl.formatMessage(Messages.sal_rev)}
          </option>
          <option value={intl.formatMessage(Messages.sal_dr)}>
            {intl.formatMessage(Messages.sal_dr)}
          </option>
          <option value={intl.formatMessage(Messages.sal_atty)}>
            {intl.formatMessage(Messages.sal_atty)}
          </option>
          <option value="Hon"> Honorable (Hon) </option>
          <option value="Prof"> Professor (Prof) </option>
          <option value="Pres"> President (Pres) </option>
          <option value="VP"> Vice President (VP) </option>
          <option value="Gov"> Governor (Gov) </option>
          <option value="Ofc"> Officer (Ofc) </option>
        </Input>

        <Input
          className="mx-2 ui-attribute-el"
          value={hnVal.given}
          onChange={(e) => {
            attributeValue.valueHumanName.given = e.target.value;
            setData(attributeValue);
          }}
          type="text"
          placeholder={intl.formatMessage(Messages.first_name)}
          autoComplete="off"
          onBlur={this.props.onBlur}
        />

        <Input
          className="ui-attribute-el"
          value={hnVal.family}
          onChange={(e) => {
            attributeValue.valueHumanName.family = e.target.value;
            setData(attributeValue);
          }}
          type="text"
          autoCapitalize="words"
          placeholder={intl.formatMessage(Messages.last_name)}
          autoComplete="off"
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default injectIntl(HumanNameAttribute);
