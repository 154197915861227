import React, { Component } from "react";
import Select, { Creatable } from "react-select";
import Messages from "../Shared/Messages";
import I18nProvider from "../services/I18nProvider";
import Text from "./Text";

class MPSSelect extends Component {
  render() {
    let { style = {}, creatable, error } = this.props;

    let placeholder = this.props.placeholder ? (
      <span>{this.props.placeholder}</span>
    ) : (
      <Text content={Messages.select} />
    );
    let SelectComponent = Select;
    if (creatable) {
      SelectComponent = Creatable;
    }

    return (
      <div className="Select">
        <SelectComponent
          noResultsText={<Text content={Messages.no_results_found} />}
          {...this.props}
          placeholder={placeholder}
          rtl={I18nProvider.isRTLLocale()}
          style={{ ...style, border: style.border || "1px solid #c0c0c0", borderColor: error ? "red" : "" }}
        />
        {error && <div className="text-danger">{error}</div>}
      </div>
    );
  }
}

export default MPSSelect;
