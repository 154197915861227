const dashboardMock = Promise.resolve({
    "facilityIcon": {
        "id": "665e420e053c804806e0436d",
        "format": "IMAGE",
        "fileName": "logogeronte.png",
        "contentStorageKey": "S3f3bfa89c-76ec-453e-bc4b-3d79c5ab9248.png",
        "thumbnailStorageKey": "S3d7c04081-0b1d-4cb2-9bef-9506327e4439.png",
        "iconStorageKey": "S317fc01cb-f697-4bc6-95e0-349176bcb7a6.png",
        "bucketName": "mpshome",
        "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3f3bfa89c-76ec-453e-bc4b-3d79c5ab9248.png",
        "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3d7c04081-0b1d-4cb2-9bef-9506327e4439.png",
        "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S317fc01cb-f697-4bc6-95e0-349176bcb7a6.png",
        "thumbnailFileName": "logogeronte_thumb.png",
        "iconFileName": "logogeronte_icon.png"
    },
    "facilityName": "Geronte",
    "patientName": {
        "text": "Luis Fourier ",
        "family": "Fourier",
        "given": "Luis"
    },
    "patientId": "665a2276d0e6230a22dc7e66",
    "birthDate": "1940-12-01T00:00:00.000+00:00",
    "showWelcomePopup": false,
    "items": [],
    "sections": [],
    "grids": [],
    "tabs": [
        {
            "screenName": "Patient report",
            "showWelcomePopup": false,
            "items": [],
            "sections": [],
            "grids": [
                {
                    "id": "665a1de2d0e6230a22d9580d",
                    "name": "Column 1",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "24032d00-676f-4db3-b1f6-2824aa8dc467",
                            "title": "Patient Details",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665d8f1b7b8b1a2fb8ed4e3b",
                                "format": "IMAGE",
                                "fileName": "29.png",
                                "contentStorageKey": "S37eef5a63-280a-429f-ad2f-fd450668c12d.png",
                                "thumbnailStorageKey": "S37edf9e0a-d970-4d65-bbe9-7ac3e94402aa.png",
                                "iconStorageKey": "S349b529e3-78f9-4359-b1c5-0b1a6b24b41f.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S37eef5a63-280a-429f-ad2f-fd450668c12d.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S37edf9e0a-d970-4d65-bbe9-7ac3e94402aa.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S349b529e3-78f9-4359-b1c5-0b1a6b24b41f.png",
                                "thumbnailFileName": "29_thumb.png",
                                "iconFileName": "29_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdcea",
                                    "name": "ShowPatientPhoto",
                                    "title": "ShowPatientPhoto",
                                    "type": "ATTRIBUTE",
                                    "predefinedItemCode": "SHOW_PHOTO",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665eda8572ce4d4eb10e6740",
                                        "name": "ShowPatientPhoto",
                                        "title": "ShowPatientPhoto",
                                        "attributeType": "PLACEHOLDER",
                                        "dataField": "PlaceHolder",
                                        "observationCode": {
                                            "code": "ShowPatientPhoto",
                                            "display": "ShowPatientPhoto",
                                            "id": "665eda8572ce4d4eb10e6741"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "predefinedItemCode": "SHOW_PHOTO",
                                        "observationType": false,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcec",
                                    "name": "MPS_PATIENT_NAME",
                                    "title": "Name",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c91a",
                                        "name": "MPS_PATIENT_NAME",
                                        "title": "Name",
                                        "attributeType": "HUMAN_NAME",
                                        "dataField": "PatientName",
                                        "value": {
                                            "valueHumanName": {
                                                "text": "Luis Fourier ",
                                                "family": "Fourier",
                                                "given": "Luis"
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": false,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcee",
                                    "name": "LivingSituation",
                                    "title": "Living Situation",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c99e",
                                        "name": "LivingSituation",
                                        "title": "Living Situation",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "LivingSituation",
                                            "display": "Living Situation",
                                            "id": "667b4a20a1932c599b59c1da"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a2276d0e6230a22dc7e5e",
                                                "code": {
                                                    "code": "LivingSituation",
                                                    "display": "Living Situation",
                                                    "id": "6659bb4af6c9157dc528ce0b"
                                                },
                                                "value": {
                                                    "valueAsString": "test",
                                                    "valueString": "test"
                                                },
                                                "observationDate": 1720091116558,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcf0",
                                    "name": "PrimaryCaregiver",
                                    "title": "Primary caregiver",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c99f",
                                        "name": "PrimaryCaregiver",
                                        "title": "Primary caregiver",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "PrimaryCaregiver",
                                            "display": "Primary caregiver",
                                            "id": "667b4a36a1932c599b5a0520"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a2276d0e6230a22dc7e61",
                                                "code": {
                                                    "code": "PrimaryCaregiver",
                                                    "display": "Primary caregiver",
                                                    "id": "6659bb55f6c9157dc52ae8ef"
                                                },
                                                "value": {
                                                    "valueAsString": "Children",
                                                    "valueString": "Children"
                                                },
                                                "observationDate": 1717183095149,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcf2",
                                    "name": "InformalCare",
                                    "title": "Informal care",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a0",
                                        "name": "InformalCare",
                                        "title": "Informal care",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "InformalCare",
                                            "display": "Informal care",
                                            "id": "667b4a46a1932c599b5a4866"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0163b43ea86f39c2d244",
                                                "code": {
                                                    "code": "InformalCare",
                                                    "display": "Informal care",
                                                    "id": "6659bb61f6c9157dc52c1a8c"
                                                },
                                                "value": {
                                                    "valueAsString": "Housekeeper ..",
                                                    "valueString": "Housekeeper .."
                                                },
                                                "observationDate": 1717502307700,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcf3",
                                    "name": "FormalCare",
                                    "title": "Formal care",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a1",
                                        "name": "FormalCare",
                                        "title": "Formal care",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "FormalCare",
                                            "display": "Formal care",
                                            "id": "667b4a5aa1932c599b5a8bac"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0176b43ea86f39c305ae",
                                                "code": {
                                                    "code": "FormalCare",
                                                    "display": "Formal care",
                                                    "id": "6659bb73f6c9157dc52cba4e"
                                                },
                                                "value": {
                                                    "valueAsString": "Nursing home support",
                                                    "valueString": "Nursing home support"
                                                },
                                                "observationDate": 1717502326581,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcf4",
                                    "name": "MemorableInformation",
                                    "title": "Memorable Information",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a2",
                                        "name": "MemorableInformation",
                                        "title": "Memorable Information",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "MemorableInformation",
                                            "display": "Memorable Information",
                                            "id": "667b4a68a1932c599b5acef2"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0184b43ea86f39c3e0ba",
                                                "code": {
                                                    "code": "MemorableInformation",
                                                    "display": "Memorable Information",
                                                    "id": "6659bb7ef6c9157dc52d69ae"
                                                },
                                                "value": {
                                                    "valueAsString": "test3",
                                                    "valueString": "test3"
                                                },
                                                "observationDate": 1720091285822,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 1,
                            "isEditable": true
                        },
                        {
                            "id": "2b683c8c-1ddb-4e17-9c2b-ac1725482c9a",
                            "title": "Tumour - Lung cancer",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665d8f237b8b1a2fb8ed4e40",
                                "format": "IMAGE",
                                "fileName": "30.png",
                                "contentStorageKey": "S38d323ef4-96e5-47bd-9664-c46502c74ef4.png",
                                "thumbnailStorageKey": "S30095c689-43db-43ac-a0bc-12baecd22848.png",
                                "iconStorageKey": "S311b27739-dac8-4129-aa32-a2a74574e24b.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S38d323ef4-96e5-47bd-9664-c46502c74ef4.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S30095c689-43db-43ac-a0bc-12baecd22848.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S311b27739-dac8-4129-aa32-a2a74574e24b.png",
                                "thumbnailFileName": "30_thumb.png",
                                "iconFileName": "30_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdcf7",
                                    "name": "Metastasis (M)",
                                    "title": "Metastasis",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9cb",
                                        "name": "Metastasis (M)",
                                        "title": "Metastasis",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Metastasis (M)",
                                            "display": "Metastasis",
                                            "id": "667aead6a1932c599b8ba590"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "6662f7c5b88fa433f576a9b7",
                                                "code": {
                                                    "code": "Metastasis (M)",
                                                    "display": "Metastasis",
                                                    "id": "66596ac3f6c9157dc572ebd7"
                                                },
                                                "value": {
                                                    "valueAsString": "M1",
                                                    "valueString": "M1"
                                                },
                                                "observationDate": 1718881670461,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "66586022f6c9157dc5ae51bc",
                                                    "code": {
                                                        "code": "Metastasis (M)",
                                                        "display": "Metastasis (M)",
                                                        "id": "66586022f6c9157dc5ae51bf"
                                                    },
                                                    "value": {
                                                        "valueAsString": "M0",
                                                        "valueString": "M0"
                                                    },
                                                    "observationDate": 1717067810055,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "Metastasis (M)",
                                                    "display": "Metastasis (M)",
                                                    "id": "66586022f6c9157dc5ae51bf"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66586022f6c9157dc5ae51be",
                                                    "code": {
                                                        "code": "Metastasis (M)",
                                                        "display": "Metastasis (M)",
                                                        "id": "66586022f6c9157dc5ae51bf"
                                                    },
                                                    "value": {
                                                        "valueAsString": "M1",
                                                        "valueString": "M1"
                                                    },
                                                    "observationDate": 1717067810055,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "Metastasis (M)",
                                                    "display": "Metastasis (M)",
                                                    "id": "66586022f6c9157dc5ae51bf"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcf8",
                                    "name": "cTNM Staging",
                                    "title": "cTNM Staging Mechanism ",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9cf",
                                        "name": "cTNM Staging",
                                        "title": "cTNM Staging Mechanism ",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "cTNM Staging",
                                            "display": "cTNM Staging Mechanism ",
                                            "id": "6672ba86209c7f203930bc5c"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f56cbc918c649aada5abf",
                                                "code": {
                                                    "code": "cTNM Staging",
                                                    "display": "cTNM Staging",
                                                    "id": "6659669af6c9157dc53417ac"
                                                },
                                                "value": {
                                                    "valueAsString": "cT1234",
                                                    "valueString": "cT1234"
                                                },
                                                "observationDate": 1717646054533,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcf9",
                                    "name": "Symptomatic",
                                    "title": "Symptomatic",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c981",
                                        "name": "Symptomatic",
                                        "title": "Symptomatic",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Symptomatic",
                                            "display": "Symptomatic",
                                            "id": "66829e6ad68d687017c21a3d"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f5701c918c649aadafb93",
                                                "code": {
                                                    "code": "Symptomatic",
                                                    "display": "Symptomatic",
                                                    "id": "6633638e672a3c0f9130f64c"
                                                },
                                                "value": {
                                                    "valueAsString": "Yes",
                                                    "valueString": "Yes"
                                                },
                                                "observationDate": 1717524225567,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "66829e6ad68d687017c21a3a",
                                                    "code": {
                                                        "code": "Symptomatic",
                                                        "display": "Symptomatic",
                                                        "id": "66829e6ad68d687017c21a3d"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Yes",
                                                        "valueString": "Yes"
                                                    },
                                                    "observationDate": 1719836266020,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66829e6ad68d687017c21a3c",
                                                    "code": {
                                                        "code": "Symptomatic",
                                                        "display": "Symptomatic",
                                                        "id": "66829e6ad68d687017c21a3d"
                                                    },
                                                    "value": {
                                                        "valueAsString": "No",
                                                        "valueString": "No"
                                                    },
                                                    "observationDate": 1719836266020,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcfb",
                                    "name": "Histology",
                                    "title": "Histology",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c982",
                                        "name": "Histology",
                                        "title": "Histology",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Histology",
                                            "display": "Histology",
                                            "id": "667b48a3a1932c599b541d1d"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdcfd",
                                    "name": "PDL1Status",
                                    "title": "PDL-1 Status",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c983",
                                        "name": "PDL1Status",
                                        "title": "PDL-1 Status",
                                        "attributeType": "OBSERVATION_DOUBLE",
                                        "dataField": "ObservationDouble",
                                        "observationCode": {
                                            "code": "PDL1Status",
                                            "display": "PDL-1 Status",
                                            "id": "6633652b672a3c0f915a0c35"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd00",
                                    "name": "EGFRm",
                                    "title": "EGFRm",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c985",
                                        "name": "EGFRm",
                                        "title": "EGFRm",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "EGFRm",
                                            "display": "EGFRm",
                                            "id": "66336634672a3c0f9169bd67"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f5726c918c649aadc5af3",
                                                "code": {
                                                    "code": "EGFRm",
                                                    "display": "EGFRm",
                                                    "id": "66336634672a3c0f9169bd67"
                                                },
                                                "value": {
                                                    "valueAsString": "Present",
                                                    "valueString": "Present"
                                                },
                                                "observationDate": 1717524262887,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "66336634672a3c0f9169bd62",
                                                    "code": {
                                                        "code": "EGFRm",
                                                        "display": "EGFRm",
                                                        "id": "66336634672a3c0f9169bd67"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Present",
                                                        "valueString": "Present"
                                                    },
                                                    "observationDate": 1714644532696,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "EGFRm",
                                                    "display": "EGFRm",
                                                    "id": "66336634672a3c0f9169bd67"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66336634672a3c0f9169bd64",
                                                    "code": {
                                                        "code": "EGFRm",
                                                        "display": "EGFRm",
                                                        "id": "66336634672a3c0f9169bd67"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Absent",
                                                        "valueString": "Absent"
                                                    },
                                                    "observationDate": 1714644532696,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "EGFRm",
                                                    "display": "EGFRm",
                                                    "id": "66336634672a3c0f9169bd67"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66336634672a3c0f9169bd66",
                                                    "code": {
                                                        "code": "EGFRm",
                                                        "display": "EGFRm",
                                                        "id": "66336634672a3c0f9169bd67"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Unknown",
                                                        "valueString": "Unknown"
                                                    },
                                                    "observationDate": 1714644532696,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "EGFRm",
                                                    "display": "EGFRm",
                                                    "id": "66336634672a3c0f9169bd67"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd01",
                                    "name": "ALKm",
                                    "title": "ALKm",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c986",
                                        "name": "ALKm",
                                        "title": "ALKm",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "ALKm",
                                            "display": "ALKm",
                                            "id": "66336696672a3c0f9172373d"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f5722c918c649aadc0e9c",
                                                "code": {
                                                    "code": "ALKm",
                                                    "display": "ALKm",
                                                    "id": "66336696672a3c0f9172373d"
                                                },
                                                "value": {
                                                    "valueAsString": "Absent",
                                                    "valueString": "Absent"
                                                },
                                                "observationDate": 1717524258779,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "66336696672a3c0f91723738",
                                                    "code": {
                                                        "code": "ALKm",
                                                        "display": "ALKm",
                                                        "id": "66336696672a3c0f9172373d"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Present",
                                                        "valueString": "Present"
                                                    },
                                                    "observationDate": 1714644630157,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "ALKm",
                                                    "display": "ALKm",
                                                    "id": "66336696672a3c0f9172373d"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66336696672a3c0f9172373a",
                                                    "code": {
                                                        "code": "ALKm",
                                                        "display": "ALKm",
                                                        "id": "66336696672a3c0f9172373d"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Absent",
                                                        "valueString": "Absent"
                                                    },
                                                    "observationDate": 1714644630157,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "ALKm",
                                                    "display": "ALKm",
                                                    "id": "66336696672a3c0f9172373d"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66336696672a3c0f9172373c",
                                                    "code": {
                                                        "code": "ALKm",
                                                        "display": "ALKm",
                                                        "id": "66336696672a3c0f9172373d"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Unknown",
                                                        "valueString": "Unknown"
                                                    },
                                                    "observationDate": 1714644630157,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "ALKm",
                                                    "display": "ALKm",
                                                    "id": "66336696672a3c0f9172373d"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd02",
                                    "name": "ROSm",
                                    "title": "ROSm",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c987",
                                        "name": "ROSm",
                                        "title": "ROSm",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "ROSm",
                                            "display": "ROSm",
                                            "id": "6633679e672a3c0f918102d8"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f572ac918c649aadca752",
                                                "code": {
                                                    "code": "ROSm",
                                                    "display": "ROSm",
                                                    "id": "6633679e672a3c0f918102d8"
                                                },
                                                "value": {
                                                    "valueAsString": "Absent",
                                                    "valueString": "Absent"
                                                },
                                                "observationDate": 1717524266604,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "6633679e672a3c0f918102d3",
                                                    "code": {
                                                        "code": "ROSm",
                                                        "display": "ROSm",
                                                        "id": "6633679e672a3c0f918102d8"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Present",
                                                        "valueString": "Present"
                                                    },
                                                    "observationDate": 1714644894871,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "ROSm",
                                                    "display": "ROSm",
                                                    "id": "6633679e672a3c0f918102d8"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6633679e672a3c0f918102d5",
                                                    "code": {
                                                        "code": "ROSm",
                                                        "display": "ROSm",
                                                        "id": "6633679e672a3c0f918102d8"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Absent",
                                                        "valueString": "Absent"
                                                    },
                                                    "observationDate": 1714644894871,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "ROSm",
                                                    "display": "ROSm",
                                                    "id": "6633679e672a3c0f918102d8"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6633679e672a3c0f918102d7",
                                                    "code": {
                                                        "code": "ROSm",
                                                        "display": "ROSm",
                                                        "id": "6633679e672a3c0f918102d8"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Unknown",
                                                        "valueString": "Unknown"
                                                    },
                                                    "observationDate": 1714644894871,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "ROSm",
                                                    "display": "ROSm",
                                                    "id": "6633679e672a3c0f918102d8"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd04",
                                    "name": "DateOfMetastases",
                                    "title": "Date Of Metastases",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c980",
                                        "name": "DateOfMetastases",
                                        "title": "Date Of Metastases",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "DateOfMetastases",
                                            "display": "Date Of Metastases",
                                            "id": "667b4878a1932c599b539689"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "isEditable": true
                        },
                        {
                            "id": "1b9a9ce8-bf0d-49b5-ba8a-62d6cd732404",
                            "title": "MTB Recommendations ",
                            "expandByDefault": false,
                            "headerColor": "#E0EFFE",
                            "type": "OPEN",
                            "icon": {
                                "id": "66612b1fb1a0524cade1122c",
                                "format": "IMAGE",
                                "fileName": "47.png",
                                "contentStorageKey": "S3c10c3a49-03cd-4a9b-9327-315196385f1b.png",
                                "thumbnailStorageKey": "S3f3c5a4e7-7155-4a26-8cee-ae4b103be999.png",
                                "iconStorageKey": "S31000562f-da4f-4028-9344-49c963df0e54.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3c10c3a49-03cd-4a9b-9327-315196385f1b.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3f3c5a4e7-7155-4a26-8cee-ae4b103be999.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S31000562f-da4f-4028-9344-49c963df0e54.png",
                                "thumbnailFileName": "47_thumb.png",
                                "iconFileName": "47_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd07",
                                    "name": "MTBRecommendations",
                                    "title": "MTB recommendation",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ad",
                                        "name": "MTBRecommendations",
                                        "title": "MTB recommendation",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "MTBRecommendations",
                                            "display": "MTB recommendation",
                                            "id": "66612c6fb1a0524cade67d35"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f01b0b43ea86f39c6a809",
                                                "code": {
                                                    "code": "MTBRecommendations",
                                                    "display": "MTB recommendations",
                                                    "id": "664b414e32ecdd7abcfad04b"
                                                },
                                                "value": {
                                                    "valueAsString": "This is our recommendation",
                                                    "valueString": "This is our recommendation"
                                                },
                                                "observationDate": 1718868707937,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "4c537f7c-ba78-44f0-8e09-89ef71dad5fd",
                            "title": "Non-cancer specific prognosis (Lee Index)",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665efffeb43ea86f39ba9518",
                                "format": "IMAGE",
                                "fileName": "Icons geronte (3).png",
                                "contentStorageKey": "S3d108afc1-5796-4340-b7c0-9c222e111bf6.png",
                                "thumbnailStorageKey": "S3df910380-5c18-42e2-a85b-a71986b89127.png",
                                "iconStorageKey": "S31dd6b413-ada1-4930-ad19-3124170eaf96.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3d108afc1-5796-4340-b7c0-9c222e111bf6.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3df910380-5c18-42e2-a85b-a71986b89127.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S31dd6b413-ada1-4930-ad19-3124170eaf96.png",
                                "thumbnailFileName": "Icons geronte (3)_thumb.png",
                                "iconFileName": "Icons geronte (3)_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd08",
                                    "name": "LeeIndex4YearSRDisplay",
                                    "title": "4 Year Survival Rate %",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "66600739c918c649aa8ed524",
                                        "name": "LeeIndex4YearSRDisplay",
                                        "title": "4 Year Survival Rate %",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "LeeIndex4YearSRDisplay",
                                            "display": "4 Year Survival Rate",
                                            "id": "667ae711c1a61e02fdf99da7"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "66605cfcb1a0524cad4bb228",
                                                "code": {
                                                    "code": "LeeIndex4YearSRDisplay",
                                                    "display": "4 Year Survival Rate",
                                                    "id": "66600739c918c649aa8ed525"
                                                },
                                                "value": {
                                                    "valueAsString": "36%",
                                                    "valueString": "36%"
                                                },
                                                "interpretation": {
                                                    "id": "666082edb1a0524cad284c29",
                                                    "min": 14,
                                                    "max": 30,
                                                    "values": [
                                                        "36%"
                                                    ],
                                                    "interpretation": "36%",
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 30,
                                                    "minValue": 14
                                                },
                                                "observationDate": 1717666942308,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "interpretation": {
                                            "id": "666082edb1a0524cad284c29",
                                            "min": 14,
                                            "max": 30,
                                            "values": [
                                                "36%"
                                            ],
                                            "interpretation": "36%",
                                            "associatedColor": "#F81703",
                                            "numberOfItems": 0,
                                            "maxValue": 30,
                                            "minValue": 14
                                        },
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd09",
                                    "name": "LeeIndex10YearSRDisplay",
                                    "title": "10 Year Survival Rate %",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "66600759c918c649aa8f9c24",
                                        "name": "LeeIndex10YearSRDisplay",
                                        "title": "10 Year Survival Rate %",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "LeeIndex10YearSRDisplay",
                                            "display": "10 Year Survival Rate",
                                            "id": "667ae700c1a61e02fdf91c9d"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "66605cfcb1a0524cad4bb22a",
                                                "code": {
                                                    "code": "LeeIndex10YearSRDisplay",
                                                    "display": "10 Year Survival Rate",
                                                    "id": "66600759c918c649aa8f9c25"
                                                },
                                                "value": {
                                                    "valueAsString": "9-17%",
                                                    "valueString": "9-17%"
                                                },
                                                "interpretation": {
                                                    "id": "66608393b1a0524cad32f6c2",
                                                    "min": 14,
                                                    "max": 30,
                                                    "values": [
                                                        "9-17%"
                                                    ],
                                                    "interpretation": "9-17%",
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 30,
                                                    "minValue": 14
                                                },
                                                "observationDate": 1717666942319,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "interpretation": {
                                            "id": "66608393b1a0524cad32f6c2",
                                            "min": 14,
                                            "max": 30,
                                            "values": [
                                                "9-17%"
                                            ],
                                            "interpretation": "9-17%",
                                            "associatedColor": "#F81703",
                                            "numberOfItems": 0,
                                            "maxValue": 30,
                                            "minValue": 14
                                        },
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "associatedSurvey": "665a0743d0e6230a22d2cde0",
                            "associatedSurveyTitle": "Lee Index",
                            "associatedSurveyLink": "/api/surveys/665a0743d0e6230a22d2cde0",
                            "isEditable": true
                        }
                    ]
                },
                {
                    "id": "665a1de2d0e6230a22d9584d",
                    "name": "Column 2",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "ee30cd13-f76d-4983-be15-032a91a5e2d7",
                            "title": "Comorbidities",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665d8f347b8b1a2fb8ed4e45",
                                "format": "IMAGE",
                                "fileName": "31.png",
                                "contentStorageKey": "S36d357e35-2bf9-4402-a02b-985b64a9ea99.png",
                                "thumbnailStorageKey": "S3acb3c419-3dbb-4785-8d06-94d1814bed65.png",
                                "iconStorageKey": "S31340830f-0180-4240-a822-1a89f78b0fb9.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S36d357e35-2bf9-4402-a02b-985b64a9ea99.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3acb3c419-3dbb-4785-8d06-94d1814bed65.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S31340830f-0180-4240-a822-1a89f78b0fb9.png",
                                "thumbnailFileName": "31_thumb.png",
                                "iconFileName": "31_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd0d",
                                    "name": "Multimorbity Profile",
                                    "title": "Multimorbity Profile",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c95b",
                                        "name": "Multimorbity Profile",
                                        "title": "Multimorbity Profile",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Multimorbity Profile",
                                            "display": "Multimorbity Profile",
                                            "id": "667b0c4ea1932c599b3a9f32"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eeb0172ce4d4eb131b418",
                                                "code": {
                                                    "code": "Multimorbity Profile",
                                                    "display": "Multimorbity Profile",
                                                    "id": "65ccca5b832ac40090ef63e1"
                                                },
                                                "value": {
                                                    "valueAsString": "Profile 3 - Psychosocial health and cognitive impairment",
                                                    "valueString": "Profile 3 - Psychosocial health and cognitive impairment"
                                                },
                                                "observationDate": 1719304600263,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "65ccc9fb832ac40090ebfec7",
                                                    "code": {
                                                        "code": "Multimorbity Profile",
                                                        "display": "Multimorbity Profile",
                                                        "id": "65ccc9fb832ac40090ebfed6"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Profile 1 - Cardiovascular,metabolic and pulmonary disease",
                                                        "valueString": "Profile 1 - Cardiovascular,metabolic and pulmonary disease"
                                                    },
                                                    "observationDate": 1707919867471,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "65ccc9fb832ac40090ebfec9",
                                                    "code": {
                                                        "code": "Multimorbity Profile",
                                                        "display": "Multimorbity Profile",
                                                        "id": "65ccc9fb832ac40090ebfed6"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Profile 2 - Disability, dependency and caregiver burden",
                                                        "valueString": "Profile 2 - Disability, dependency and caregiver burden"
                                                    },
                                                    "observationDate": 1707919867471,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "65ccca5b832ac40090ef63dc",
                                                    "code": {
                                                        "code": "Multimorbity Profile",
                                                        "display": "Multimorbity Profile",
                                                        "id": "65ccca5b832ac40090ef63e1"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Profile 3 - Psycosocial health and cognitive impairment",
                                                        "valueString": "Profile 3 - Psycosocial health and cognitive impairment"
                                                    },
                                                    "observationDate": 1707919963155,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "65ccca5b832ac40090ef63de",
                                                    "code": {
                                                        "code": "Multimorbity Profile",
                                                        "display": "Multimorbity Profile",
                                                        "id": "65ccca5b832ac40090ef63e1"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Profile 4 - Nutritional Status and Digestive system disease",
                                                        "valueString": "Profile 4 - Nutritional Status and Digestive system disease"
                                                    },
                                                    "observationDate": 1707919963155,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "65ccca5b832ac40090ef63e0",
                                                    "code": {
                                                        "code": "Multimorbity Profile",
                                                        "display": "Multimorbity Profile",
                                                        "id": "65ccca5b832ac40090ef63e1"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Profile 5 - Concurrent or previous cancer",
                                                        "valueString": "Profile 5 - Concurrent or previous cancer"
                                                    },
                                                    "observationDate": 1707919963155,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd0e",
                                    "name": "CoMorbHumanoid",
                                    "title": "Humanoid",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a1e2fd0e6230a22da1d08",
                                        "name": "CoMorbHumanoid",
                                        "title": "Humanoid",
                                        "attributeType": "OBSERVATION_HUMANOID",
                                        "dataField": "ObservationHumanoid",
                                        "observationCode": {
                                            "code": "CoMorbHumanoid",
                                            "display": "Humanoid",
                                            "id": "665b34e2d0e6230a2248726f"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665b3556d0e6230a224b04f2",
                                                "code": {
                                                    "code": "CoMorbHumanoid",
                                                    "display": "Humanoid",
                                                    "id": "665b34e2d0e6230a2248726f"
                                                },
                                                "value": {
                                                    "valueAsString": "2 values available",
                                                    "valueString": "2 values available",
                                                    "valueHumanoids": [
                                                        {
                                                            "id": "669e135f3cf7134c1153712b",
                                                            "title": "Congestive Heart Disease",
                                                            "position": "Upper-Torso",
                                                            "customData": {
                                                                "Severity": "moderate",
                                                                "Impact on Daily Functioning": "moderate",
                                                                "LVEF": "3",
                                                                "NYHA ": "II"
                                                            },
                                                            "interpretation": {
                                                                "id": "665d42dbd64e22283592ae34",
                                                                "interpretation": "Upper-Torso",
                                                                "comment": "POSITION",
                                                                "associatedImage": {
                                                                    "id": "665d874c7b8b1a2fb8e6e96f",
                                                                    "format": "IMAGE",
                                                                    "fileName": "25.png",
                                                                    "contentStorageKey": "S32f638796-ba7a-4efd-806f-246d2d742943.png",
                                                                    "thumbnailStorageKey": "S33af5bcf8-2eaf-4553-ae4e-9a3e7c53fe3e.png",
                                                                    "iconStorageKey": "S38acd47af-d84c-40ba-abd9-e42b09c5b34c.png",
                                                                    "bucketName": "mpshome",
                                                                    "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S32f638796-ba7a-4efd-806f-246d2d742943.png",
                                                                    "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S33af5bcf8-2eaf-4553-ae4e-9a3e7c53fe3e.png",
                                                                    "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S38acd47af-d84c-40ba-abd9-e42b09c5b34c.png",
                                                                    "thumbnailFileName": "25_thumb.png",
                                                                    "iconFileName": "25_icon.png"
                                                                },
                                                                "associatedColor": "",
                                                                "numberOfItems": 0,
                                                                "maxValue": 0,
                                                                "minValue": 0
                                                            }
                                                        },
                                                        {
                                                            "id": "669e135f3cf7134c1153712c",
                                                            "title": "Morbid Obesity",
                                                            "position": "Lower-Torso",
                                                            "customData": {
                                                                "Severity": "mild",
                                                                "Impact on Daily Functioning": "mild",
                                                                "Body Mass Index (BMI)": "23"
                                                            },
                                                            "interpretation": {
                                                                "id": "665d43fdd64e22283595507c",
                                                                "interpretation": "Lower-Torso",
                                                                "comment": "POSITION",
                                                                "associatedImage": {
                                                                    "id": "665d88377b8b1a2fb8e77672",
                                                                    "format": "IMAGE",
                                                                    "fileName": "16.png",
                                                                    "contentStorageKey": "S3ae552dbc-51b2-46b0-ba92-3274e94dc7a5.png",
                                                                    "thumbnailStorageKey": "S35d924a29-e70d-47b8-955f-0ab97d07586f.png",
                                                                    "iconStorageKey": "S33ec7863d-74d0-48b8-ae9c-668b510fcd4f.png",
                                                                    "bucketName": "mpshome",
                                                                    "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3ae552dbc-51b2-46b0-ba92-3274e94dc7a5.png",
                                                                    "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S35d924a29-e70d-47b8-955f-0ab97d07586f.png",
                                                                    "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S33ec7863d-74d0-48b8-ae9c-668b510fcd4f.png",
                                                                    "thumbnailFileName": "16_thumb.png",
                                                                    "iconFileName": "16_icon.png"
                                                                },
                                                                "numberOfItems": 0,
                                                                "maxValue": 0,
                                                                "minValue": 0
                                                            }
                                                        }
                                                    ]
                                                },
                                                "observationDate": 1721635679650,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "associatedSurvey": "665d4674d0e6230a2256ee31",
                            "associatedSurveyTitle": "Comorbidity Assessment",
                            "associatedSurveyLink": "/api/surveys/665d4674d0e6230a2256ee31",
                            "isEditable": true
                        },
                        {
                            "id": "2c380aaf-e069-4f9e-bc4b-521c2838e866",
                            "title": "Personal goals",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665f0192b43ea86f39c485c8",
                                "format": "IMAGE",
                                "fileName": "Icons geronte (4).png",
                                "contentStorageKey": "S380e48b35-302b-4a06-9485-d82f2e7bc670.png",
                                "thumbnailStorageKey": "S3b2070eb2-4673-4835-99de-8fa584262d1c.png",
                                "iconStorageKey": "S3f883a505-58ea-4166-a0e8-6012a2c399f7.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S380e48b35-302b-4a06-9485-d82f2e7bc670.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3b2070eb2-4673-4835-99de-8fa584262d1c.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S3f883a505-58ea-4166-a0e8-6012a2c399f7.png",
                                "thumbnailFileName": "Icons geronte (4)_thumb.png",
                                "iconFileName": "Icons geronte (4)_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd0f",
                                    "name": "Personal Goals Completed Date",
                                    "title": "Completed On : ",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "6674260e209c7f203990805c",
                                        "name": "Personal Goals Completed Date",
                                        "title": "Completed On : ",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Personal Goals Completed Date",
                                            "display": "Completed On : ",
                                            "id": "6682ae51c2893e00c6b4de2a"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "667531dc209c7f20392fca5f",
                                                "code": {
                                                    "code": "Personal Goals Completed Date",
                                                    "display": "Completed On : ",
                                                    "id": "6674260e209c7f203990805d"
                                                },
                                                "value": {
                                                    "valueAsString": "21/06/2024 07:55",
                                                    "valueString": "21/06/2024 07:55",
                                                    "valueDate": 1718956507680
                                                },
                                                "observationDate": 1718956508122,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd13",
                                    "name": "GoalExtendingLife",
                                    "title": "Extending Life",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a8",
                                        "name": "GoalExtendingLife",
                                        "title": "Extending Life",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalExtendingLife",
                                            "display": "Goal Extending Life",
                                            "id": "667b4af8a1932c599b5c62c4"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df4309",
                                                "code": {
                                                    "code": "GoalExtendingLife",
                                                    "display": "Goal Extending Life",
                                                    "id": "6659804af6c9157dc5cb3517"
                                                },
                                                "value": {
                                                    "valueAsString": "50",
                                                    "valueString": "50",
                                                    "valueInteger": 50
                                                },
                                                "interpretation": {
                                                    "id": "665a27b3d0e6230a22e1dc8d",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508095,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd14",
                                    "name": "GoalMaintainingIndependence",
                                    "title": "Maintaining Independence",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9aa",
                                        "name": "GoalMaintainingIndependence",
                                        "title": "Maintaining Independence",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalMaintainingIndependence",
                                            "display": "Maintaining Independence",
                                            "id": "667b4b1aa1932c599b5ce964"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df430b",
                                                "code": {
                                                    "code": "GoalMaintainingIndependence",
                                                    "display": "Goal Maintaining Independence",
                                                    "id": "664b09d206027618bf9c72e0"
                                                },
                                                "value": {
                                                    "valueAsString": "70",
                                                    "valueString": "70",
                                                    "valueInteger": 70
                                                },
                                                "interpretation": {
                                                    "id": "665a27ebd0e6230a22e3664a",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508104,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd15",
                                    "name": "GoalReducingPain",
                                    "title": "Reducing or eliminating pain and other symptoms",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a9",
                                        "name": "GoalReducingPain",
                                        "title": "Reducing or eliminating pain and other symptoms",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalReducingPain",
                                            "display": "Reducing or eliminating pain and other symptoms",
                                            "id": "667eaa8251fe293d8ac51c63"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df430d",
                                                "code": {
                                                    "code": "GoalReducingPain",
                                                    "display": "Reducing Pain",
                                                    "id": "66598058f6c9157dc5cd0d71"
                                                },
                                                "value": {
                                                    "valueAsString": "30",
                                                    "valueString": "30",
                                                    "valueInteger": 30
                                                },
                                                "interpretation": {
                                                    "id": "665a27ddd0e6230a22e2a16b",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508111,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd16",
                                    "name": "GoalReducingOtherSymptoms",
                                    "title": "Preventing negative effects of treatment",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ab",
                                        "name": "GoalReducingOtherSymptoms",
                                        "title": "Preventing negative effects of treatment",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalReducingOtherSymptoms",
                                            "display": "Preventing negative effects",
                                            "id": "667eaa5c51fe293d8ac15f0f"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df430f",
                                                "code": {
                                                    "code": "GoalReducingOtherSymptoms",
                                                    "display": "Goal Reducing Other Symptoms",
                                                    "id": "664b09f406027618bf9e4f0a"
                                                },
                                                "value": {
                                                    "valueAsString": "70",
                                                    "valueString": "70",
                                                    "valueInteger": 70
                                                },
                                                "interpretation": {
                                                    "id": "665a27f8d0e6230a22e42b2a",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508116,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "a8e5d830-016b-46e8-b630-38e54ed0e785",
                            "title": "Medications, allergies and substance abuse",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665efde8b43ea86f39a92237",
                                "format": "IMAGE",
                                "fileName": "52.png",
                                "contentStorageKey": "S3fdebda4c-ba1b-46b4-9675-4e240fedef60.png",
                                "thumbnailStorageKey": "S30ce4636f-5c70-4601-ad14-77fb0e931285.png",
                                "iconStorageKey": "S39871fcea-b43a-4c69-b4fe-fc153b5c32ca.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3fdebda4c-ba1b-46b4-9675-4e240fedef60.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S30ce4636f-5c70-4601-ad14-77fb0e931285.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S39871fcea-b43a-4c69-b4fe-fc153b5c32ca.png",
                                "thumbnailFileName": "52_thumb.png",
                                "iconFileName": "52_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd17",
                                    "name": "MedicationsDescription",
                                    "title": "Medications Description",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ac",
                                        "name": "MedicationsDescription",
                                        "title": "Medications Description",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "MedicationsDescription",
                                            "display": "Medications Description",
                                            "id": "667b4b3ca1932c599b5d7003"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f58b8c918c649aae9457e",
                                                "code": {
                                                    "code": "MedicationsDescription",
                                                    "display": "Medications Description",
                                                    "id": "665efdd5b43ea86f39a84c69"
                                                },
                                                "value": {
                                                    "valueAsString": "",
                                                    "valueString": ""
                                                },
                                                "isMultipleAnswersPermitted": true,
                                                "values": [
                                                    {
                                                        "valueAsString": "Metaformin 500 mg, 2 times day 2",
                                                        "valueString": "Metaformin 500 mg, 2 times day 2"
                                                    }
                                                ],
                                                "observationDate": 1717524664593,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd18",
                                    "name": "Allergies",
                                    "title": "Allergies",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c3",
                                        "name": "Allergies",
                                        "title": "Allergies",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Allergies",
                                            "display": "Allergies",
                                            "id": "664c976432ecdd7abc673aa9"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f5924c918c649aaec5659",
                                                "code": {
                                                    "code": "Allergies",
                                                    "display": "Allergies",
                                                    "id": "664c976432ecdd7abc673aa9"
                                                },
                                                "value": {
                                                    "valueAsString": "",
                                                    "valueString": ""
                                                },
                                                "isMultipleAnswersPermitted": true,
                                                "values": [
                                                    {
                                                        "valueAsString": "Pollen",
                                                        "valueString": "Pollen"
                                                    }
                                                ],
                                                "observationDate": 1717524772692,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd19",
                                    "name": "SubstanceAbuse",
                                    "title": "Substance Abuse",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c4",
                                        "name": "SubstanceAbuse",
                                        "title": "Substance Abuse",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "SubstanceAbuse",
                                            "display": "Substance Abuse",
                                            "id": "667aeb6ea1932c599b8d156d"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a17",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Nicotine",
                                                        "valueString": "Nicotine"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a19",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Alcohol",
                                                        "valueString": "Alcohol"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a1b",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Medication Abuse",
                                                        "valueString": "Medication Abuse"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a1d",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Other",
                                                        "valueString": "Other"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            }
                                        ],
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        }
                    ]
                },
                {
                    "id": "665a215ed0e6230a22dbb7ce",
                    "name": "Column 3",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "1f065da8-d989-4000-b95e-752eea591580",
                            "title": "Intrinsic Capacity",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665f481a63bbe5014e475b06",
                                "format": "IMAGE",
                                "fileName": "functionalIcon.png",
                                "contentStorageKey": "S370927e7b-0e5e-4c8f-b2bf-ad947e9ff295.png",
                                "thumbnailStorageKey": "S3f15c5a60-61ac-4aad-b48d-afc0bd8a060a.png",
                                "iconStorageKey": "S387ee2a64-c999-4c29-bd4a-65566fa48199.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S370927e7b-0e5e-4c8f-b2bf-ad947e9ff295.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3f15c5a60-61ac-4aad-b48d-afc0bd8a060a.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S387ee2a64-c999-4c29-bd4a-65566fa48199.png",
                                "thumbnailFileName": "functionalIcon_thumb.png",
                                "iconFileName": "functionalIcon_icon.png"
                            },
                            "items": [],
                            "criteriaToHide": [],
                            "associatedSurvey": "665a0743d0e6230a22d2ce5e",
                            "associatedSurveyTitle": "Intrinsic Capacity Assessment",
                            "associatedSurveyLink": "/api/surveys/665a0743d0e6230a22d2ce5e",
                            "isEditable": true
                        },
                        {
                            "id": "f09f6ab4-4861-4972-a2f7-73bc5a6a2edb",
                            "title": "Functional status",
                            "expandByDefault": false,
                            "headerColor": "#FFFFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "6660593eb1a0524cad3e2e79",
                                "contentURL": "",
                                "thumbnailURL": "",
                                "iconURL": ""
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd1d",
                                    "name": "ADLs",
                                    "title": "ADLs",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ae",
                                        "name": "ADLs",
                                        "title": "ADLs",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "ADLs",
                                            "display": "ADLs",
                                            "id": "66608f7bb1a0524cad1702f9"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e527ff",
                                                "code": {
                                                    "code": "ADLs",
                                                    "display": "ADLs",
                                                    "id": "664c5f3532ecdd7abc8dcd2d"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66608f7bb1a0524cad1702f7",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1720091519423,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd1e",
                                    "name": "Balance",
                                    "title": "Balance",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9af",
                                        "name": "Balance",
                                        "title": "Balance",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Balance",
                                            "display": "Balance",
                                            "id": "66829e1fd68d687017be598e"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52801",
                                                "code": {
                                                    "code": "Balance",
                                                    "display": "Balance",
                                                    "id": "664c5fa632ecdd7abc9a637e"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66609022b1a0524cad1e9c1f",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161870,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd1f",
                                    "name": "WalkingAid",
                                    "title": "Walking Aid",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b0",
                                        "name": "WalkingAid",
                                        "title": "Walking Aid",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "WalkingAid",
                                            "display": "Walking Aid",
                                            "id": "667b4b54a1932c599b5db35c"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52803",
                                                "code": {
                                                    "code": "WalkingAid",
                                                    "display": "Walking Aid",
                                                    "id": "664b496932ecdd7abc65ad8e"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0"
                                                },
                                                "interpretation": {
                                                    "id": "665a3234d0e6230a22ee49f6",
                                                    "min": 0,
                                                    "max": 0,
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161875,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "664b496932ecdd7abc65ad8b",
                                                    "code": {
                                                        "code": "WalkingAid",
                                                        "display": "Walking Aid",
                                                        "id": "664b496932ecdd7abc65ad8e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Yes",
                                                        "valueString": "Yes"
                                                    },
                                                    "observationDate": 1716210025634,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "WalkingAid",
                                                    "display": "Walking Aid",
                                                    "id": "664b496932ecdd7abc65ad8e"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "664b496932ecdd7abc65ad8d",
                                                    "code": {
                                                        "code": "WalkingAid",
                                                        "display": "Walking Aid",
                                                        "id": "664b496932ecdd7abc65ad8e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "No",
                                                        "valueString": "No"
                                                    },
                                                    "observationDate": 1716210025634,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "WalkingAid",
                                                    "display": "Walking Aid",
                                                    "id": "664b496932ecdd7abc65ad8e"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd20",
                                    "name": "AdherenceProblem",
                                    "title": "Adherence problem",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b1",
                                        "name": "AdherenceProblem",
                                        "title": "Adherence problem",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "AdherenceProblem",
                                            "display": "Adherence problem",
                                            "id": "667b4b63a1932c599b5df6af"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52805",
                                                "code": {
                                                    "code": "AdherenceProblem",
                                                    "display": "Adherence problem",
                                                    "id": "664c5fee32ecdd7abca25ff5"
                                                },
                                                "value": {
                                                    "valueAsString": "3",
                                                    "valueString": "3",
                                                    "valueInteger": 3
                                                },
                                                "interpretation": {
                                                    "id": "6660905eb1a0524cad21c57c",
                                                    "min": 3,
                                                    "max": 4,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#D50000",
                                                        "#D50000"
                                                    ],
                                                    "associatedColor": "#D50000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                "observationDate": 1717661161880,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd21",
                                    "name": "iADLs",
                                    "title": "iADLs",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b6",
                                        "name": "iADLs",
                                        "title": "iADLs",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "iADLs",
                                            "display": "iADLs",
                                            "id": "6660900bb1a0524cad1bb9b2"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52807",
                                                "code": {
                                                    "code": "iADLs",
                                                    "display": "iADLs",
                                                    "id": "664c603432ecdd7abcb6bfad"
                                                },
                                                "value": {
                                                    "valueAsString": "3",
                                                    "valueString": "3",
                                                    "valueInteger": 3
                                                },
                                                "interpretation": {
                                                    "id": "6660900bb1a0524cad1bb9b1",
                                                    "min": 3,
                                                    "max": 4,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#D50000",
                                                        "#D50000"
                                                    ],
                                                    "associatedColor": "#D50000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                "observationDate": 1717661161885,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd22",
                                    "name": "IntrinsicFalls",
                                    "title": "Falls",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c5",
                                        "name": "IntrinsicFalls",
                                        "title": "Falls",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "IntrinsicFalls",
                                            "display": "Intrinsic Falls",
                                            "id": "667be4dfb68cf6649386141c"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52809",
                                                "code": {
                                                    "code": "IntrinsicFalls",
                                                    "display": "Intrinsic Falls",
                                                    "id": "664db3f52d698321605a6509"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "6660902eb1a0524cad1f6365",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161891,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd23",
                                    "name": "ChairStandTestResult",
                                    "title": "Chair Stand Test Result",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c96d",
                                        "name": "ChairStandTestResult",
                                        "title": "Chair Stand Test Result",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "ChairStandTestResult",
                                            "display": "Chair Stand Test Result",
                                            "id": "668d5feef7fbda3460cffb05"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0db463bbe5014eb70872",
                                                "code": {
                                                    "code": "ChairStandTestResult",
                                                    "display": "Chair Stand Test Result",
                                                    "id": "661e51724c51bf0a283e4a56"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "661e51724c51bf0a283e4a52",
                                                    "min": 0,
                                                    "max": 15,
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 15,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717666969335,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd25",
                                    "name": "Continence",
                                    "title": "Continence",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b7",
                                        "name": "Continence",
                                        "title": "Continence",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Continence",
                                            "display": "Continence",
                                            "id": "6682ae0ec2893e00c6b4037d"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e5280b",
                                                "code": {
                                                    "code": "Continence",
                                                    "display": "Continence",
                                                    "id": "664c604232ecdd7abcb90977"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66608f8db1a0524cad17d056",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161896,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd27",
                                    "name": "PerformanceStatus",
                                    "title": "Performance status",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9bd",
                                        "name": "PerformanceStatus",
                                        "title": "Performance status",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "PerformanceStatus",
                                            "display": "Performance status",
                                            "id": "667b4c48a1932c599b5fce3c"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e5280d",
                                                "code": {
                                                    "code": "PerformanceStatus",
                                                    "display": "Performance status",
                                                    "id": "664c60bf32ecdd7abcc9d741"
                                                },
                                                "value": {
                                                    "valueAsString": "1",
                                                    "valueString": "1",
                                                    "valueInteger": 1
                                                },
                                                "interpretation": {
                                                    "id": "66609017b1a0524cad1dd4d8",
                                                    "min": 1,
                                                    "max": 1,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FFD740",
                                                        "#FFD740"
                                                    ],
                                                    "associatedColor": "#FFD740",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                "observationDate": 1717661161901,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd29",
                                    "name": "IntrinsicMobility",
                                    "title": "Mobility",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c7",
                                        "name": "IntrinsicMobility",
                                        "title": "Mobility",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "IntrinsicMobility",
                                            "display": "Intrinsic Mobility",
                                            "id": "667aeb2ba1932c599b8c7f35"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e5280f",
                                                "code": {
                                                    "code": "IntrinsicMobility",
                                                    "display": "Intrinsic Mobility",
                                                    "id": "664db4772d69832160639c39"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "6660903db1a0524cad2030c2",
                                                    "min": 0,
                                                    "max": 0,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161906,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd2a",
                                    "name": "IntrinsicFatigue",
                                    "title": "Fatigue",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c6",
                                        "name": "IntrinsicFatigue",
                                        "title": "Fatigue",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "IntrinsicFatigue",
                                            "display": "Intrinsic Fatigue",
                                            "id": "66609052b1a0524cad20fe37"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52811",
                                                "code": {
                                                    "code": "IntrinsicFatigue",
                                                    "display": "Intrinsic Fatigue",
                                                    "id": "664db4562d6983216061348f"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "66609052b1a0524cad20fe33",
                                                    "min": 0,
                                                    "max": 0,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161910,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 3
                        },
                        {
                            "id": "e41fabf1-4b8d-4bbc-9fbf-6ab005a293ee",
                            "title": "Nutritional status",
                            "expandByDefault": false,
                            "headerColor": "#FFFFFF",
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd2b",
                                    "name": "Nutrition",
                                    "title": "Nutrition",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b8",
                                        "name": "Nutrition",
                                        "title": "Nutrition",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Nutrition",
                                            "display": "Nutrition",
                                            "id": "6660906cb1a0524cad2292dc"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52813",
                                                "code": {
                                                    "code": "Nutrition",
                                                    "display": "Nutrition",
                                                    "id": "664c605232ecdd7abcbca4c4"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "6660906cb1a0524cad2292da",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161915,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd2c",
                                    "name": "BMI",
                                    "title": "BMI",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c95e",
                                        "name": "BMI",
                                        "title": "BMI",
                                        "attributeType": "OBSERVATION_DOUBLE",
                                        "dataField": "ObservationDouble",
                                        "observationCode": {
                                            "code": "BMI",
                                            "display": "BMI",
                                            "id": "6682ae1ec2893e00c6b44d48"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52815",
                                                "code": {
                                                    "code": "BMI",
                                                    "display": "BMI",
                                                    "id": "6658294a908b962ab85989bb"
                                                },
                                                "value": {
                                                    "valueDouble": 20,
                                                    "valueAsString": "20",
                                                    "valueString": "20"
                                                },
                                                "observationDate": 1717661161920,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 2
                        },
                        {
                            "id": "111144c6-7211-4ec0-a4df-294909afadde",
                            "title": "Cognitive and psychological status",
                            "expandByDefault": false,
                            "headerColor": "#FFFFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "6660593eb1a0524cad3e2e85",
                                "contentURL": "",
                                "thumbnailURL": "",
                                "iconURL": ""
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd2d",
                                    "name": "Cognition",
                                    "title": "Cognition",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b2",
                                        "name": "Cognition",
                                        "title": "Cognition",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Cognition",
                                            "display": "Cognition",
                                            "id": "6660907eb1a0524cad235a22"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52817",
                                                "code": {
                                                    "code": "Cognition",
                                                    "display": "Cognition",
                                                    "id": "664c5ffc32ecdd7abca9ed8d"
                                                },
                                                "value": {
                                                    "valueAsString": "3",
                                                    "valueString": "3",
                                                    "valueInteger": 3
                                                },
                                                "interpretation": {
                                                    "id": "6660907eb1a0524cad235a21",
                                                    "min": 3,
                                                    "max": 4,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#D50000",
                                                        "#D50000"
                                                    ],
                                                    "associatedColor": "#D50000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                "observationDate": 1717661161924,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd2e",
                                    "name": "Anxiety",
                                    "title": "Anxiety",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b3",
                                        "name": "Anxiety",
                                        "title": "Anxiety",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Anxiety",
                                            "display": "Anxiety",
                                            "id": "667b4baea1932c599b5e3a14"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52819",
                                                "code": {
                                                    "code": "Anxiety",
                                                    "display": "Anxiety",
                                                    "id": "664c600b32ecdd7abcac9a42"
                                                },
                                                "value": {
                                                    "valueAsString": "3",
                                                    "valueString": "3",
                                                    "valueInteger": 3
                                                },
                                                "interpretation": {
                                                    "id": "666090a1b1a0524cad27c11d",
                                                    "min": 3,
                                                    "max": 4,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#D50000",
                                                        "#D50000"
                                                    ],
                                                    "associatedColor": "#D50000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                "observationDate": 1717661161930,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd30",
                                    "name": "DeliriumRisk",
                                    "title": "Delirium risk",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b9",
                                        "name": "DeliriumRisk",
                                        "title": "Delirium risk",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "DeliriumRisk",
                                            "display": "Delirium risk",
                                            "id": "6682ae35c2893e00c6b4977a"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e5281b",
                                                "code": {
                                                    "code": "DeliriumRisk",
                                                    "display": "Delirium risk",
                                                    "id": "664c605e32ecdd7abcbea509"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "66609089b1a0524cad24d361",
                                                    "min": 0,
                                                    "max": 0,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161937,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd31",
                                    "name": "Loneliness",
                                    "title": "Loneliness",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ba",
                                        "name": "Loneliness",
                                        "title": "Loneliness",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Loneliness",
                                            "display": "Loneliness",
                                            "id": "667b4c02a1932c599b5f0443"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e5281d",
                                                "code": {
                                                    "code": "Loneliness",
                                                    "display": "Loneliness",
                                                    "id": "664c606c32ecdd7abcc033f6"
                                                },
                                                "value": {
                                                    "valueAsString": "1",
                                                    "valueString": "1",
                                                    "valueInteger": 1
                                                },
                                                "interpretation": {
                                                    "id": "666090adb1a0524cad288e8d",
                                                    "min": 1,
                                                    "max": 1,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FFD740",
                                                        "#FFD740"
                                                    ],
                                                    "associatedColor": "#FFD740",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                "observationDate": 1717661161941,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd33",
                                    "name": "Mood",
                                    "title": "Mood",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9be",
                                        "name": "Mood",
                                        "title": "Mood",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Mood",
                                            "display": "Mood",
                                            "id": "667b4c57a1932c599b60118f"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e5281f",
                                                "code": {
                                                    "code": "Mood",
                                                    "display": "Mood",
                                                    "id": "664c60d932ecdd7abccd9af1"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66609096b1a0524cad264cb9",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161946,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 3
                        },
                        {
                            "id": "7f6fc028-d2da-4be7-a8c6-a7cc1251d1f9",
                            "title": "Social status",
                            "expandByDefault": false,
                            "headerColor": "#FFFFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "6660593eb1a0524cad3e2e8c",
                                "contentURL": "",
                                "thumbnailURL": "",
                                "iconURL": ""
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd35",
                                    "name": "CaregiverBurden",
                                    "title": "Caregiver burden",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9bf",
                                        "name": "CaregiverBurden",
                                        "title": "Caregiver burden",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "CaregiverBurden",
                                            "display": "Caregiver burden",
                                            "id": "667aebb4a1932c599b8d9c28"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52821",
                                                "code": {
                                                    "code": "CaregiverBurden",
                                                    "display": "Caregiver burden",
                                                    "id": "664dc3b72d69832160a816c5"
                                                },
                                                "value": {
                                                    "valueAsString": "1",
                                                    "valueString": "1",
                                                    "valueInteger": 1
                                                },
                                                "interpretation": {
                                                    "id": "666090bab1a0524cad2955e6",
                                                    "min": 1,
                                                    "max": 1,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FFD740",
                                                        "#FFD740"
                                                    ],
                                                    "associatedColor": "#FFD740",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                "observationDate": 1717661161950,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd36",
                                    "name": "TransportationIssues",
                                    "title": "Transportation issues",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c0",
                                        "name": "TransportationIssues",
                                        "title": "Transportation issues",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "TransportationIssues",
                                            "display": "Transportation issues",
                                            "id": "667b4d68a1932c599b6054e2"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52823",
                                                "code": {
                                                    "code": "TransportationIssues",
                                                    "display": "Transportation issues",
                                                    "id": "664dc3972d69832160a59228"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66609112b1a0524cad2d9c78",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161959,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd37",
                                    "name": "SocialNetwork",
                                    "title": "Social Network",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b4",
                                        "name": "SocialNetwork",
                                        "title": "Social Network",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "SocialNetwork",
                                            "display": "Social Network",
                                            "id": "667b4bc5a1932c599b5e7d67"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52825",
                                                "code": {
                                                    "code": "SocialNetwork",
                                                    "display": "Social Network",
                                                    "id": "664c601e32ecdd7abcb18bca"
                                                },
                                                "value": {
                                                    "valueAsString": "1",
                                                    "valueString": "1",
                                                    "valueInteger": 1
                                                },
                                                "interpretation": {
                                                    "id": "666090c5b1a0524cad2a2360",
                                                    "min": 1,
                                                    "max": 1,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FFD740",
                                                        "#FFD740"
                                                    ],
                                                    "associatedColor": "#FFD740",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                "observationDate": 1717661161963,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd38",
                                    "name": "healthLiteracy",
                                    "title": "Health literacy",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "667d8ab6d04dd55b8a40b1ec",
                                        "name": "healthLiteracy",
                                        "title": "Health literacy",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "healthLiteracy",
                                            "display": "Health literacy",
                                            "id": "668d6085f7fbda3460e0b2b4"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52827",
                                                "code": {
                                                    "code": "HealthLiteracy",
                                                    "display": "Health literacy",
                                                    "id": "664c609a32ecdd7abcc535c7"
                                                },
                                                "value": {
                                                    "valueAsString": "1",
                                                    "valueString": "1",
                                                    "valueInteger": 1
                                                },
                                                "interpretation": {
                                                    "id": "668d6085f7fbda3460e0b2b1",
                                                    "min": 1,
                                                    "max": 1,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FFD740",
                                                        "#FFD740"
                                                    ],
                                                    "associatedColor": "#FFD740",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                "observationDate": 1717661161968,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 2
                        },
                        {
                            "id": "76d05c48-5d4f-4c3c-be87-161e734e6c81",
                            "title": "Sensory status",
                            "expandByDefault": false,
                            "headerColor": "#FFFFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "6660593eb1a0524cad3e2e92",
                                "contentURL": "",
                                "thumbnailURL": "",
                                "iconURL": ""
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd39",
                                    "name": "Vision",
                                    "title": "Vision",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b5",
                                        "name": "Vision",
                                        "title": "Vision",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Vision",
                                            "display": "Vision",
                                            "id": "6660911fb1a0524cad325867"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e52829",
                                                "code": {
                                                    "code": "Vision",
                                                    "display": "Vision",
                                                    "id": "664c602932ecdd7abcb3e155"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "6660911fb1a0524cad325865",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161977,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd3b",
                                    "name": "Hearing",
                                    "title": "Hearing",
                                    "display": "Text",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9bc",
                                        "name": "Hearing",
                                        "title": "Hearing",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Hearing",
                                            "display": "Hearing",
                                            "id": "667b4c28a1932c599b5f8ae9"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289bd0e6230a22e5282b",
                                                "code": {
                                                    "code": "Hearing",
                                                    "display": "Hearing",
                                                    "id": "664c60a332ecdd7abcc61aff"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "6660912bb1a0524cad35d2d9",
                                                    "min": 0,
                                                    "max": 0,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161982,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 2
                        },
                        {
                            "id": "67d23d74-0362-433f-ae16-1e83420fff22",
                            "title": "Clinical frailty scale",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665f0cb963bbe5014eb38f7a",
                                "format": "IMAGE",
                                "fileName": "38.png",
                                "contentStorageKey": "S32a838178-8ed7-4c42-be89-d915106fb99c.png",
                                "thumbnailStorageKey": "S3e49a87fd-5c08-4eb9-af83-d60827a3d090.png",
                                "iconStorageKey": "S3879d55f9-99d9-4538-b33e-635af14b1894.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S32a838178-8ed7-4c42-be89-d915106fb99c.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3e49a87fd-5c08-4eb9-af83-d60827a3d090.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S3879d55f9-99d9-4538-b33e-635af14b1894.png",
                                "thumbnailFileName": "38_thumb.png",
                                "iconFileName": "38_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd3d",
                                    "name": "Frailty",
                                    "title": "Current status",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a7",
                                        "name": "Frailty",
                                        "title": "Current status",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Frailty",
                                            "display": "Frailty",
                                            "id": "667d82e3d04dd55b8ae30c86"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0be863bbe5014eb1e7c8",
                                                "code": {
                                                    "code": "Frailty",
                                                    "display": "Frailty",
                                                    "id": "664776e206027618bf7fcf0a"
                                                },
                                                "value": {
                                                    "valueAsString": "Managing well",
                                                    "valueString": "Managing well"
                                                },
                                                "observationDate": 1717535142161,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "associatedSurvey": "665a0743d0e6230a22d2ce4e",
                            "associatedSurveyTitle": "Clinical Frailty Scale",
                            "associatedSurveyLink": "/api/surveys/665a0743d0e6230a22d2ce4e",
                            "isEditable": true
                        },
                        {
                            "id": "368f5186-a5f6-4ddb-b630-38b1f19a382f",
                            "title": "Control preferences",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "665f0cc063bbe5014eb38f7d",
                                "format": "IMAGE",
                                "fileName": "39.png",
                                "contentStorageKey": "S38a6a5865-2408-4635-9d47-93b3374c6a22.png",
                                "thumbnailStorageKey": "S378ab5134-3fa4-4ed1-a8bc-482d4460ae84.png",
                                "iconStorageKey": "S377d58181-2435-4d86-8c23-f8e8040d0c60.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S38a6a5865-2408-4635-9d47-93b3374c6a22.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S378ab5134-3fa4-4ed1-a8bc-482d4460ae84.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S377d58181-2435-4d86-8c23-f8e8040d0c60.png",
                                "thumbnailFileName": "39_thumb.png",
                                "iconFileName": "39_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd48",
                                    "name": "ControlPreferences",
                                    "title": "Control preferences",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c2",
                                        "name": "ControlPreferences",
                                        "title": "Control preferences",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "ControlPreferences",
                                            "display": "Control preferences",
                                            "id": "667aeb96a1932c599b8d58de"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0ad863bbe5014eaceb32",
                                                "code": {
                                                    "code": "ControlPreferences",
                                                    "display": "Control preferences",
                                                    "id": "664b58a932ecdd7abcf5cc4a"
                                                },
                                                "value": {
                                                    "valueAsString": "I prefer that my doctor and I share the responsibility for deciding which treatment is best",
                                                    "valueString": "I prefer that my doctor and I share the responsibility for deciding which treatment is best"
                                                },
                                                "observationDate": 1717504728429,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "isMultiLine": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "associatedSurvey": "665a0743d0e6230a22d2ce57",
                            "associatedSurveyTitle": "Control Preferences",
                            "associatedSurveyLink": "/api/surveys/665a0743d0e6230a22d2ce57",
                            "isEditable": true
                        },
                        {
                            "id": "fffe0c3e-2a28-47db-81bd-c2320986d799",
                            "title": "GP Input",
                            "expandByDefault": false,
                            "headerColor": "#DFEFFF",
                            "type": "OPEN",
                            "icon": {
                                "id": "6660860fb1a0524cad5a4690",
                                "format": "IMAGE",
                                "fileName": "41.png",
                                "contentStorageKey": "S3e3c8c5bc-a08f-4138-9fc6-2a5e3c61eeec.png",
                                "thumbnailStorageKey": "S3da2b308c-bf7f-4e39-a913-598cc0ee6b03.png",
                                "iconStorageKey": "S3623c50eb-2268-4e1c-800d-7411f4296231.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3e3c8c5bc-a08f-4138-9fc6-2a5e3c61eeec.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S3da2b308c-bf7f-4e39-a913-598cc0ee6b03.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S3623c50eb-2268-4e1c-800d-7411f4296231.png",
                                "thumbnailFileName": "41_thumb.png",
                                "iconFileName": "41_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd49",
                                    "name": "GPKnowledgeOfPatient",
                                    "title": "Knowledge Of Patient",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a3",
                                        "name": "GPKnowledgeOfPatient",
                                        "title": "Knowledge Of Patient",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "GPKnowledgeOfPatient",
                                            "display": "GP Knowledge Of Patient",
                                            "id": "667b4a7fa1932c599b5b1238"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eff4bb43ea86f39b436a2",
                                                "code": {
                                                    "code": "GPKnowledgeOfPatient",
                                                    "display": "GP Knowledge Of Patient",
                                                    "id": "664775e906027618bf77ba21"
                                                },
                                                "value": {
                                                    "valueAsString": "I have fair knowledge about them",
                                                    "valueString": "I have fair knowledge about them"
                                                },
                                                "observationDate": 1717501771885,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd4a",
                                    "name": "GPAnnualVisits",
                                    "title": "GP Annual Visits",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a4",
                                        "name": "GPAnnualVisits",
                                        "title": "GP Annual Visits",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GPAnnualVisits",
                                            "display": "GP Annual Visits",
                                            "id": "667b4a98a1932c599b5b5590"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eff4bb43ea86f39b436a4",
                                                "code": {
                                                    "code": "GPAnnualVisits",
                                                    "display": "GP Annual Visits",
                                                    "id": "6647761106027618bf78a38b"
                                                },
                                                "value": {
                                                    "valueAsString": "Between 6 times and 12 times",
                                                    "valueString": "Between 6 times and 12 times"
                                                },
                                                "observationDate": 1717501771917,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd4c",
                                    "name": "GPPatientSupportNeeds",
                                    "title": "GP Patient Support Needs",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a6",
                                        "name": "GPPatientSupportNeeds",
                                        "title": "GP Patient Support Needs",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "GPPatientSupportNeeds",
                                            "display": "GP Patient Support Needs",
                                            "id": "667b4abfa1932c599b5bdc1c"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd4e",
                                    "name": "GPPatientFitnessAssessment",
                                    "title": "Fitness Assessment",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a5",
                                        "name": "GPPatientFitnessAssessment",
                                        "title": "Fitness Assessment",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "GPPatientFitnessAssessment",
                                            "display": "GP Patient Fitness Assessment",
                                            "id": "667b4aaba1932c599b5b98d6"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eff4bb43ea86f39b436a6",
                                                "code": {
                                                    "code": "GPPatientFitnessAssessment",
                                                    "display": "GP Patient Fitness Assessment",
                                                    "id": "6647763506027618bf7a5f58"
                                                },
                                                "value": {
                                                    "valueAsString": "Weak, not generally Fit",
                                                    "valueString": "Weak, not generally Fit"
                                                },
                                                "observationDate": 1717501771925,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        }
                    ]
                }
            ],
            "attributes": [],
            "hidePeriodSelector": true,
            "taggedtoMe": false
        },
        {
            "screenName": "Symptoms",
            "showWelcomePopup": false,
            "items": [],
            "sections": [],
            "grids": [
                {
                    "id": "666c2d5ef5558662501dbbd4",
                    "name": "Daily Symptoms",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "77383f59-1b5b-452f-8b8c-37b29920eb2e",
                            "title": "Symptoms",
                            "expandByDefault": false,
                            "headerColor": "#E4F2FF",
                            "type": "OPEN",
                            "icon": {
                                "id": "6670292d209c7f20396fbe12",
                                "format": "IMAGE",
                                "fileName": "38.png",
                                "contentStorageKey": "S3b4a5e064-8d99-4d3d-a960-cac3fa2104a5.png",
                                "thumbnailStorageKey": "S33cf9fc3f-812a-40d7-bec9-c0c5409a4240.png",
                                "iconStorageKey": "S3d3707ddf-774a-439e-aab7-826d968bf0e1.png",
                                "bucketName": "mpshome",
                                "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3b4a5e064-8d99-4d3d-a960-cac3fa2104a5.png",
                                "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S33cf9fc3f-812a-40d7-bec9-c0c5409a4240.png",
                                "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S3d3707ddf-774a-439e-aab7-826d968bf0e1.png",
                                "thumbnailFileName": "38_thumb.png",
                                "iconFileName": "38_icon.png"
                            },
                            "items": [
                                {
                                    "id": "66a10cd847c69771f73fdd50",
                                    "name": "Dyspnoea",
                                    "title": "Dyspnoea",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c958",
                                        "name": "Dyspnoea",
                                        "title": "Dyspnoea",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Dyspnoea",
                                            "display": "Dyspnoea",
                                            "id": "668fc723ebe6540345e0e54e"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f9756c918c649aa331c57",
                                                "code": {
                                                    "code": "Dyspnoea",
                                                    "display": "Dyspnoea",
                                                    "id": "66226d95f8dbe9283c074df0"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "65ca3acf06302e49e9aa1a2d",
                                                    "min": 2,
                                                    "max": 2,
                                                    "gradientColors": [
                                                        "#FFA021",
                                                        "#FFA021"
                                                    ],
                                                    "associatedColor": "#FFB319",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1721317498580,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cd847c69771f73fdd51",
                                    "title": "Diarrhoea",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd847c69771f73fdd52",
                                            "statLabel": "Diarrhoea",
                                            "reportData": [
                                                {
                                                    "stat": "01/07/2024 11:25",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:56",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:48",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "11/07/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:33",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:56.278+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572b7",
                                                    "min": 0,
                                                    "max": 6,
                                                    "associatedColor": "#8efbb4",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "63c476ece5f05350b5a3cd60",
                                                    "interpretation": "Severe Diarrhoea",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#F86ABA",
                                                    "associatedLibrary": "665a0738d0e6230a22d2cb17",
                                                    "associatedLibraryTitle": " Diarrhoea",
                                                    "associatedLibraryLink": "/api/libraries/665a0738d0e6230a22d2cb17",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd847c69771f73fec9a",
                                    "title": "Vomiting",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd847c69771f73fec9b",
                                            "statLabel": "Vomiting",
                                            "reportData": [
                                                {
                                                    "stat": "01/07/2024 11:25",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:18",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "11/07/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:33",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:56.391+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572ba",
                                                    "max": 6,
                                                    "associatedColor": "#617ef5",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "667ab0d7c1a61e02fda3b477",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd847c69771f73ff74b",
                                    "title": "Pain",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd847c69771f73ff74c",
                                            "statLabel": "Pain",
                                            "reportData": [
                                                {
                                                    "stat": "24/06/2024 16:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "24/06/2024 16:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "24/06/2024 17:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:13",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:13",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:14",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:14",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:25",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 17:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:18",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:46",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "11/07/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:33",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "3"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:56.504+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572bd",
                                                    "max": 6,
                                                    "associatedColor": "#f5ec84",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "667ab0d7c1a61e02fda3b47f",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd847c69771f74001fc",
                                    "title": "Fever/feeling ill",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd847c69771f74001fd",
                                            "statLabel": "Fever/feeling ill",
                                            "reportData": [
                                                {
                                                    "stat": "24/06/2024 16:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "24/06/2024 16:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "24/06/2024 17:11",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:13",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:13",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:14",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:14",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:15",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:25",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 17:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:18",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "11/07/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:56.616+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572c0",
                                                    "max": 6,
                                                    "associatedColor": "#ef5af2",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "667ab0d7c1a61e02fda3b485",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd847c69771f7400cad",
                                    "title": "Sore/dry mouth",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd847c69771f7400cae",
                                            "statLabel": "Sore/dry mouth",
                                            "reportData": [
                                                {
                                                    "stat": "24/06/2024 16:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "24/06/2024 16:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "24/06/2024 17:11",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:13",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:13",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:14",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:14",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:16",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:17",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:25",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "01/07/2024 11:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 17:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:18",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:46",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 16:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "11/07/2024 10:57",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:33",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "4"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:56.728+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572c3",
                                                    "max": 6,
                                                    "associatedColor": "#6bd6b6",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666a9a4129370a0377472fdb",
                                                    "interpretation": "Errors",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#BA1B1B",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd847c69771f740175e",
                                    "title": "Nausea",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd847c69771f740175f",
                                            "statLabel": "Nausea",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:56.839+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572c6",
                                                    "max": 6,
                                                    "associatedColor": "#b036f2",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666193e6860ed0774609bc3f",
                                                    "interpretation": "Warning!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#D31313",
                                                    "associatedLibrary": "665a073ad0e6230a22d2cc43",
                                                    "associatedLibraryTitle": "GaryKelly",
                                                    "associatedLibraryLink": "/api/libraries/665a073ad0e6230a22d2cc43",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f7402e9e",
                                    "title": "Daily activities limited by bowel/urinary problems",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f7402e9f",
                                            "statLabel": "Daily activities limited by bowel/urinary problems",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.003+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572c9",
                                                    "max": 6,
                                                    "associatedColor": "#ccb524",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666193e6860ed0774609bc3f",
                                                    "interpretation": "Warning!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#D31313",
                                                    "associatedLibrary": "665a073ad0e6230a22d2cc43",
                                                    "associatedLibraryTitle": "GaryKelly",
                                                    "associatedLibraryLink": "/api/libraries/665a073ad0e6230a22d2cc43",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f74045de",
                                    "title": "Poor appetite",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f74045df",
                                            "statLabel": "Poor appetite",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.172+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572cc",
                                                    "max": 6,
                                                    "associatedColor": "#f40b0b",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f7405d1e",
                                    "title": "Weight change",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f7405d1f",
                                            "statLabel": "Weight change",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "65"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "50"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "87"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "88"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "88"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "83"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "82"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "101"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "101"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "101"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "101"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "89"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "100"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "90"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "90"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "82"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "80"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "65"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "65"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "81"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.335+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572cf",
                                                    "max": 6,
                                                    "associatedColor": "#0df21c",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f740745e",
                                    "title": "Fatigue",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f740745f",
                                            "statLabel": "Fatigue",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.503+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572d2",
                                                    "max": 6,
                                                    "associatedColor": "#cba0ee",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f7408b9e",
                                    "title": "Trouble sleeping/insomnia",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f7408b9f",
                                            "statLabel": "Trouble sleeping/insomnia",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.668+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572d5",
                                                    "max": 6,
                                                    "associatedColor": "#428a58",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f740a2de",
                                    "title": "Forced to spend time in bed",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f740a2df",
                                            "statLabel": "Forced to spend time in bed",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.830+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572d8",
                                                    "max": 6,
                                                    "associatedColor": "#4246ae",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cd947c69771f740ba1e",
                                    "title": "Skin issues/rash",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cd947c69771f740ba1f",
                                            "statLabel": "Skin issues/rash",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:57.997+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572db",
                                                    "max": 6,
                                                    "associatedColor": "#ffd438",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666aa7a020330609581916e1",
                                                    "interpretation": "Errors",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#B01F1F",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740d15e",
                                    "title": "Tingling hand/feet",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740d15f",
                                            "statLabel": "Tingling hand/feet",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.163+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572de",
                                                    "max": 6,
                                                    "associatedColor": "#ffadf8",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3013f5558662503b6e77",
                                                    "interpretation": "As you have reported severe symptoms in your response, we recommend you contact your healthcare provider and schedule an appointment.",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740e89e",
                                    "title": "Trouble remembering/thinking; confusion",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740e89f",
                                            "statLabel": "Confusion",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.327+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572e1",
                                                    "max": 6,
                                                    "associatedColor": "#dcf094",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666abc7d9f6fcb70553141fb",
                                                    "interpretation": "Errors!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#C74B4B",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740ecd1",
                                    "title": "Feeling depressed or irritable",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740ecd2",
                                            "statLabel": "Depressed / Irittable ",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.410+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572e4",
                                                    "max": 6,
                                                    "associatedColor": "#95b1f4",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666abc7d9f6fcb7055314203",
                                                    "interpretation": "Errors!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#A62E2E",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740f104",
                                    "title": "Feeling nervous, worried or uncertain",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740f105",
                                            "statLabel": "Worry",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.495+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572e7",
                                                    "max": 6,
                                                    "associatedColor": "#ff8800",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666abc7d9f6fcb705531420b",
                                                    "interpretation": "Errors",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#D30A0A",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740f537",
                                    "title": "Change in mobility",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740f538",
                                            "statLabel": "Change in mobility",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.579+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572ea",
                                                    "max": 6,
                                                    "associatedColor": "#ff0000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666abc7d9f6fcb7055314213",
                                                    "interpretation": "Errors!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#9A0D0D",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740f96a",
                                    "title": "Unsteady on your feet",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740f96b",
                                            "statLabel": "Unsteady on your feet",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.656+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572ed",
                                                    "max": 6,
                                                    "associatedColor": "#0008ff",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666abc7d9f6fcb705531421b",
                                                    "interpretation": "Errors!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#DB2929",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f740fd9d",
                                    "title": "Falls",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f740fd9e",
                                            "statLabel": "Falls",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.733+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572f0",
                                                    "max": 6,
                                                    "associatedColor": "#00ff9d",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666abc7d9f6fcb7055314223",
                                                    "interpretation": "Errors!",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f74101d0",
                                    "title": "Need help with daily activities",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f74101d1",
                                            "statLabel": "Need help with daily activities",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:30",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:32",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:36",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:38",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:39",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:40",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:41",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:42",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:45",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:07",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "03/07/2024 14:46",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:11",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:12",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:59",
                                                    "count": 0,
                                                    "countStr": "0.0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.813+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572f3",
                                                    "max": 6,
                                                    "associatedColor": "#fbff00",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "667a9d36c1a61e02fd1498fb",
                                                    "interpretation": "As you have reported severe symptoms in your response, we recommend you contact your healthcare provider and schedule an appointment",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#F21212",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cda47c69771f7410603",
                                    "title": "Stoma issues",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cda47c69771f7410604",
                                            "statLabel": "Stoma issues",
                                            "reportData": [
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "3"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:58.892+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572f6",
                                                    "max": 6,
                                                    "associatedColor": "#485ba8",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3bb0f55586625086c41c",
                                                    "interpretation": "Error",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#BA4848",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f7411d43",
                                    "title": "Wound problems",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f7411d44",
                                            "statLabel": "Wound problems",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.116+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572f9",
                                                    "max": 6,
                                                    "associatedColor": "#b85656",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3b1df555866250868e26",
                                                    "interpretation": "Error",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#B03131",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f7413483",
                                    "title": "Bloody stools",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f7413484",
                                            "statLabel": "Bloody stools",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.283+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572fc",
                                                    "max": 6,
                                                    "associatedColor": "#37672d",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3013f5558662503b6e75",
                                                    "interpretation": "ERRORS",
                                                    "comment": "ERROR_DEFAULT",
                                                    "associatedColor": "#B01F1F",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f7414bc3",
                                    "title": "Mucus in stool",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f7414bc4",
                                            "statLabel": "Mucus in stool",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 16:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:16",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 13:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:03",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:15",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:17",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:34",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:35",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:36",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:38",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:39",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:41",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:45",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:50",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:51",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 14:59",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:01",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:02",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:04",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:05",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:07",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:08",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:09",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:17",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:23",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:24",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:31",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:33",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:46",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:48",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:06",
                                                    "count": 0,
                                                    "countStr": "0"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.446+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c572ff",
                                                    "max": 6,
                                                    "associatedColor": "#7a5091",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3459f55586625064408c",
                                                    "interpretation": "Error",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f7416303",
                                    "title": "Cough",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f7416304",
                                            "statLabel": "Cough",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.609+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c57302",
                                                    "max": 6,
                                                    "associatedColor": "#a29076",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3659f55586625071afc0",
                                                    "interpretation": "Errors",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f7417a43",
                                    "title": "Cough up blood",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f7417a44",
                                            "statLabel": "Cough up blood",
                                            "reportData": [
                                                {
                                                    "stat": "25/06/2024 11:23",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:24",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:40",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 12:42",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 14:10",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 10:58",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:37",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:43",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:47",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:53",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:49",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:00",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:22",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:26",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:28",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:29",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:30",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:32",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 12:55",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:52",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:54",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "4"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:56",
                                                    "count": 0,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "26/06/2024 15:57",
                                                    "count": 0,
                                                    "countStr": "3"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "25/06/2024 11:20",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "26/06/2024 11:35",
                                                    "count": 0,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.773+00:00",
                                            "chartType": "SPARKLINE_BAR",
                                            "referenceRanges": [
                                                {
                                                    "id": "66880f7ed4288f2225c57305",
                                                    "max": 6,
                                                    "associatedColor": "#5aad43",
                                                    "numberOfItems": 0,
                                                    "maxValue": 6,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "666c3a77f55586625084f8c6",
                                                    "interpretation": "Error",
                                                    "comment": "ERROR_DEFAULT",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "SURVEY"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f7419183",
                                    "name": "Dyspnoea",
                                    "title": "Dyspnoea",
                                    "display": "sparklineBar",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c958",
                                        "name": "Dyspnoea",
                                        "title": "Dyspnoea",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Dyspnoea",
                                            "display": "Dyspnoea",
                                            "id": "668fc723ebe6540345e0e54e"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f9756c918c649aa331c57",
                                                "code": {
                                                    "code": "Dyspnoea",
                                                    "display": "Dyspnoea",
                                                    "id": "66226d95f8dbe9283c074df0"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "65ca3acf06302e49e9aa1a2d",
                                                    "min": 2,
                                                    "max": 2,
                                                    "gradientColors": [
                                                        "#FFA021",
                                                        "#FFA021"
                                                    ],
                                                    "associatedColor": "#FFB319",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1721317498580,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 6
                        }
                    ]
                }
            ],
            "attributes": [],
            "hidePeriodSelector": false,
            "taggedtoMe": false
        },
        {
            "screenName": "Treatment",
            "showWelcomePopup": false,
            "items": [],
            "sections": [],
            "grids": [
                {
                    "id": "6686c189cc593e38a4c15661",
                    "name": "Traitements oncologiques",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "2bc7f6ef-f320-49d6-a545-1088a99afcce",
                            "title": "Chemotherapy",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f7419184",
                                    "name": "Chemotherapy",
                                    "title": "Chemotherapy",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c93a",
                                        "name": "Chemotherapy",
                                        "title": "Chemotherapy",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Chemotherapy",
                                            "display": "Chemotherapy",
                                            "id": "666c1ca1b21f5b4d1e858610"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "6679514f652ec37b7079f3e2",
                                                "code": {
                                                    "code": "Chemotherapy",
                                                    "display": "Chemotherapy",
                                                    "id": "666c1ca1b21f5b4d1e858610"
                                                },
                                                "value": {
                                                    "valueAsString": "Yes",
                                                    "valueString": "Yes"
                                                },
                                                "observationDate": 1719226703847,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "666c1ca1b21f5b4d1e85860d",
                                                    "code": {
                                                        "code": "Chemotherapy",
                                                        "display": "Chemotherapy",
                                                        "id": "666c1ca1b21f5b4d1e858610"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Yes",
                                                        "valueString": "Yes"
                                                    },
                                                    "observationDate": 1718361249783,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "666c1ca1b21f5b4d1e85860f",
                                                    "code": {
                                                        "code": "Chemotherapy",
                                                        "display": "Chemotherapy",
                                                        "id": "666c1ca1b21f5b4d1e858610"
                                                    },
                                                    "value": {
                                                        "valueAsString": "No",
                                                        "valueString": "No"
                                                    },
                                                    "observationDate": 1718361249783,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f7419185",
                                    "name": "Chemotherapy Start Date",
                                    "title": "Chemotherapy Start Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717c03209c7f2039b0d694",
                                        "name": "Chemotherapy Start Date",
                                        "title": "Chemotherapy Start Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Chemotherapy Start Date",
                                            "display": "Chemotherapy Start Date",
                                            "id": "667ae62cc1a61e02fdf727c6"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f7419186",
                                    "name": "Chemotherapy End Date",
                                    "title": "Chemotherapy End Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717c16209c7f2039b119d8",
                                        "name": "Chemotherapy End Date",
                                        "title": "Chemotherapy End Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Chemotherapy End Date",
                                            "display": "Chemotherapy End Date",
                                            "id": "667ae61bc1a61e02fdf6e480"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "689db11f-1f59-4040-a60f-cb82895a42e9",
                            "title": "Immunotherapy",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f7419187",
                                    "name": "Immunotherapy Start Date",
                                    "title": "Immunotherapy Start Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717d12209c7f2039be4d60",
                                        "name": "Immunotherapy Start Date",
                                        "title": "Immunotherapy Start Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Immunotherapy Start Date",
                                            "display": "Immunotherapy Start Date",
                                            "id": "667ae59bc1a61e02fdf5941c"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f7419188",
                                    "name": "Immunotherapy End Date",
                                    "title": "Immunotherapy End Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717d25209c7f2039be90b6",
                                        "name": "Immunotherapy End Date",
                                        "title": "Immunotherapy End Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Immunotherapy End Date",
                                            "display": "Immunotherapy End Date",
                                            "id": "667ae586c1a61e02fdf550d6"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "05c893c8-f0a1-49b7-babd-c2b71269e889",
                            "title": "Radiotherapy",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f7419189",
                                    "name": "Radiation Therapy Start Date",
                                    "title": "Radiation Therapy Start Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717c52209c7f2039b463cf",
                                        "name": "Radiation Therapy Start Date",
                                        "title": "Radiation Therapy Start Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Radiation Therapy Start Date",
                                            "display": "Radiation Therapy Start Date",
                                            "id": "667ae604c1a61e02fdf6a13a"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f741918a",
                                    "name": "Radiation Therapy End Date",
                                    "title": "Radiation Therapy End Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717c97209c7f2039ba2e3d",
                                        "name": "Radiation Therapy End Date",
                                        "title": "Radiation Therapy End Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Radiation Therapy End Date",
                                            "display": "Radiation Therapy End Date",
                                            "id": "667ae5f3c1a61e02fdf65df4"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "8d79ca06-ab91-4f58-885b-64bbeddd4d72",
                            "title": "Targeted therapy",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f741918b",
                                    "name": "Targeted Therapy Start Date",
                                    "title": "Targeted Therapy Start Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717cc8209c7f2039bd7846",
                                        "name": "Targeted Therapy Start Date",
                                        "title": "Targeted Therapy Start Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Targeted Therapy Start Date",
                                            "display": "Targeted Therapy Start Date",
                                            "id": "667ae5dfc1a61e02fdf61aae"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f741918c",
                                    "name": "Targeted Therapy End Date",
                                    "title": "Targeted Therapy End Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717cd9209c7f2039bdbb96",
                                        "name": "Targeted Therapy End Date",
                                        "title": "Targeted Therapy End Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Targeted Therapy End Date",
                                            "display": "Targeted Therapy End Date",
                                            "id": "667ae5cbc1a61e02fdf5d768"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "d22851cf-cb66-4a5d-9f65-96683f3554d5",
                            "title": "Surgery",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f741918d",
                                    "name": "Surgery",
                                    "title": "Surgery",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c99b",
                                        "name": "Surgery",
                                        "title": "Surgery",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Surgery",
                                            "display": "Surgery",
                                            "id": "667b49bda1932c599b58f7f8"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "666c23b6b21f5b4d1eea155d",
                                                "code": {
                                                    "code": "Surgery",
                                                    "display": "Surgery",
                                                    "id": "66421d84b0525e752b689231"
                                                },
                                                "value": {
                                                    "valueAsString": "Yes",
                                                    "valueString": "Yes"
                                                },
                                                "observationDate": 1718363062815,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "66421d84b0525e752b68922e",
                                                    "code": {
                                                        "code": "Surgery",
                                                        "display": "Surgery",
                                                        "id": "66421d84b0525e752b689231"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Yes",
                                                        "valueString": "Yes"
                                                    },
                                                    "observationDate": 1715608964424,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "Surgery",
                                                    "display": "Surgery",
                                                    "id": "66421d84b0525e752b689231"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "66421d84b0525e752b689230",
                                                    "code": {
                                                        "code": "Surgery",
                                                        "display": "Surgery",
                                                        "id": "66421d84b0525e752b689231"
                                                    },
                                                    "value": {
                                                        "valueAsString": "No",
                                                        "valueString": "No"
                                                    },
                                                    "observationDate": 1715608964424,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "Surgery",
                                                    "display": "Surgery",
                                                    "id": "66421d84b0525e752b689231"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f741918e",
                                    "name": "Surgery Start Date",
                                    "title": "Surgery Start Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717ba9209c7f2039ad4973",
                                        "name": "Surgery Start Date",
                                        "title": "Surgery Start Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Surgery Start Date",
                                            "display": "Surgery Start Date",
                                            "id": "667ae68bc1a61e02fdf7ae7a"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f741918f",
                                    "name": "Surgery End Date",
                                    "title": "Surgery End Date",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66717bc2209c7f2039b04501",
                                        "name": "Surgery End Date",
                                        "title": "Surgery End Date",
                                        "attributeType": "OBSERVATION_DATE",
                                        "dataField": "ObservationDate",
                                        "observationCode": {
                                            "code": "Surgery End Date",
                                            "display": "Surgery End Date",
                                            "id": "667ae671c1a61e02fdf76b10"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        }
                    ]
                },
                {
                    "id": "6686c189cc593e38a4c156a3",
                    "name": "Non-Oncological treatments",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "ee665f54-28bb-4808-b66c-c08e122c7221",
                            "title": "Example treatment",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [],
                            "criteriaToHide": []
                        }
                    ]
                }
            ],
            "attributes": [],
            "hidePeriodSelector": false,
            "taggedtoMe": false
        },
        {
            "screenName": "HPC",
            "showWelcomePopup": false,
            "items": [],
            "sections": [],
            "grids": [
                {
                    "id": "6686c189cc593e38a4c156a6",
                    "name": "New column",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "c12abd67-d698-47ef-8260-7b8a827fb151",
                            "title": "Section",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f7419190",
                                    "type": "PATIENT_DOCUMENTS"
                                }
                            ]
                        }
                    ]
                }
            ],
            "attributes": [],
            "hidePeriodSelector": false,
            "taggedtoMe": false
        },
        {
            "screenName": "Symptoms2",
            "showWelcomePopup": false,
            "items": [],
            "sections": [],
            "grids": [
                {
                    "id": "668fad627859591751f97c35",
                    "name": "New column",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "48429fca-d877-458c-919f-2a3b32e8812b",
                            "title": "Symptoms",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f7419191",
                                    "title": "Dyspnoea",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f7419192",
                                            "statLabel": "Dysp",
                                            "reportData": [
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 2,
                                                    "countStr": "2"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 1,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:33",
                                                    "count": 1,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "11/07/2024 11:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "11/07/2024 10:57",
                                                    "count": 1,
                                                    "countStr": "1"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.939+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "6699351cc2248319d19d30df",
                                                    "min": 0,
                                                    "max": 4,
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "65ca3acf06302e49e9aa1a2c",
                                                    "min": 0,
                                                    "max": 0,
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                {
                                                    "id": "65ca3acf06302e49e9aa1a2d",
                                                    "min": 2,
                                                    "max": 2,
                                                    "gradientColors": [
                                                        "#FFA021",
                                                        "#FFA021"
                                                    ],
                                                    "associatedColor": "#FFB319",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                {
                                                    "id": "65cb79cd06302e49e90ca996",
                                                    "min": 3,
                                                    "max": 4,
                                                    "gradientColors": [
                                                        "#FF0000",
                                                        "#FF0000"
                                                    ],
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                {
                                                    "id": "65f4755c8f67055e240ee9d9",
                                                    "min": 1,
                                                    "max": 1,
                                                    "gradientColors": [
                                                        "#FFFD00",
                                                        "#FFFD00"
                                                    ],
                                                    "associatedColor": "#FFEC42",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191a2",
                                    "title": "Diarrhoea",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191a3",
                                            "statLabel": "Diar",
                                            "reportData": [
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 0,
                                                    "countStr": "0"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 2,
                                                    "countStr": "2"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.942+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "6699351cc2248319d19d30e2",
                                                    "min": 0,
                                                    "max": 4,
                                                    "associatedColor": "#205878",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "61e8997446e0fb00012cf7da",
                                                    "min": 0,
                                                    "max": 0,
                                                    "gradientColors": [
                                                        "#1AE613",
                                                        "#1AE613"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                {
                                                    "id": "61e8997446e0fb00012cf7db",
                                                    "min": 2,
                                                    "max": 2,
                                                    "gradientColors": [
                                                        "#FFA021",
                                                        "#FFA021"
                                                    ],
                                                    "associatedColor": "#FFB319",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                {
                                                    "id": "61e8997446e0fb00012cf7dc",
                                                    "min": 3,
                                                    "max": 4,
                                                    "gradientColors": [
                                                        "#FF0000",
                                                        "#FF0000"
                                                    ],
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                {
                                                    "id": "65f4751e8f67055e240c9d2e",
                                                    "min": 1,
                                                    "max": 1,
                                                    "gradientColors": [
                                                        "#FFFD00",
                                                        "#FFFD00"
                                                    ],
                                                    "associatedColor": "#FFEC42",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191aa",
                                    "title": "Vomiting",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191ab",
                                            "statLabel": "Vom",
                                            "reportData": [
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 1,
                                                    "countStr": "1"
                                                },
                                                {
                                                    "stat": "18/07/2024 16:44",
                                                    "count": 2,
                                                    "countStr": "2"
                                                }
                                            ],
                                            "reportGeneratedTime": "2024-07-24T14:16:59.945+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "6699351cc2248319d19d30e5",
                                                    "min": 0,
                                                    "max": 4,
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 0
                                                }
                                            ],
                                            "referencesRangesFromSurveyDefinition": [
                                                {
                                                    "id": "61e8997446e0fb00012cf7e0",
                                                    "min": 0,
                                                    "max": 0,
                                                    "gradientColors": [
                                                        "#1AE613",
                                                        "#1AE613"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                {
                                                    "id": "61e8997446e0fb00012cf7e1",
                                                    "min": 1,
                                                    "max": 1,
                                                    "gradientColors": [
                                                        "#FFFD00",
                                                        "#FFFD00"
                                                    ],
                                                    "associatedColor": "#FFEC42",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                {
                                                    "id": "65f474cc8f67055e2409e5df",
                                                    "min": 2,
                                                    "max": 2,
                                                    "gradientColors": [
                                                        "#FFA021",
                                                        "#FFA021"
                                                    ],
                                                    "associatedColor": "#FFB319",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                {
                                                    "id": "65f474cc8f67055e2409e5e0",
                                                    "min": 3,
                                                    "max": 4,
                                                    "gradientColors": [
                                                        "#FF0000",
                                                        "#FF0000"
                                                    ],
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191b2",
                                    "title": "Nausea",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191b3",
                                            "statLabel": "Naus",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.947+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "6699351cc2248319d19d30e8",
                                                    "min": 0,
                                                    "max": 4,
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191b4",
                                    "title": "Bowel/Urinary problems",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191b5",
                                            "statLabel": "BowUr",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.948+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "6699351cc2248319d19d30eb",
                                                    "min": 0,
                                                    "max": 4,
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191b6",
                                    "title": "Poor Appetite",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191b7",
                                            "statLabel": "Poor Appetite",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.949+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "6699351cc2248319d19d30ee",
                                                    "min": 0,
                                                    "max": 4,
                                                    "associatedColor": "#1e5472",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191b8",
                                    "title": "Weight change",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191b9",
                                            "statLabel": "Weight",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.952+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2206",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ba",
                                    "title": "Pain",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191bb",
                                            "statLabel": "Pain",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.953+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2209",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191bc",
                                    "title": "Fever",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191bd",
                                            "statLabel": "Fever",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.956+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a220c",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191be",
                                    "title": "Fatigue",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191bf",
                                            "statLabel": "Fatigue",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.957+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a220f",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191c0",
                                    "title": "Insomnia",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191c1",
                                            "statLabel": "Insomnia",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.959+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2212",
                                                    "associatedColor": "#1f5573",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191c2",
                                    "title": "Confusion",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191c3",
                                            "statLabel": "Confusion",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.961+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2215",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191c4",
                                    "title": "Depressed/Irritable",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191c5",
                                            "statLabel": "Depressed",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.963+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2218",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191c6",
                                    "title": "Anxiety",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191c7",
                                            "statLabel": "Anxiety",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.966+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a221b",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191c8",
                                    "title": "Change in mobility",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191c9",
                                            "statLabel": "Mobility",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.967+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a221e",
                                                    "associatedColor": "#1e5675",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ca",
                                    "title": "Unsteady on feet",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191cb",
                                            "statLabel": "Unsteady on feet",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.967+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2221",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191cc",
                                    "title": "Falls",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191cd",
                                            "statLabel": "Falls",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.968+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2224",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ce",
                                    "title": "Time in bed",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191cf",
                                            "statLabel": "Bed",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.969+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2227",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191d0",
                                    "title": "Need help daily activities",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191d1",
                                            "statLabel": "Need help with activities",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.971+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a222a",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191d2",
                                    "title": "Stoma issues",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191d3",
                                            "statLabel": "Stoma issues",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.972+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a222d",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191d4",
                                    "title": "Wound problems",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191d5",
                                            "statLabel": "Wound",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.974+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2230",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191d6",
                                    "title": "Bloody stools",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191d7",
                                            "statLabel": "Bloody stools",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.976+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2233",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191d8",
                                    "title": "Mucus in stool",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191d9",
                                            "statLabel": "Mucus stool",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.977+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2236",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191da",
                                    "title": "Skin issues/rash",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191db",
                                            "statLabel": "Rash",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.978+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2239",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191dc",
                                    "title": "Cough",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191dd",
                                            "statLabel": "Cough",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.980+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a223c",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191de",
                                    "title": "Cough up blood",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191df",
                                            "statLabel": "Cough blood",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.981+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a223f",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191e0",
                                    "title": "Sore/dry mouth",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191e1",
                                            "statLabel": "Sore mouth",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.983+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2242",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                },
                                {
                                    "id": "66a10cdb47c69771f74191e2",
                                    "title": "Tingling hands/feet",
                                    "type": "REPORT",
                                    "reportData": [
                                        {
                                            "id": "66a10cdb47c69771f74191e3",
                                            "statLabel": "Tingling hands/feet",
                                            "reportGeneratedTime": "2024-07-24T14:16:59.984+00:00",
                                            "chartType": "SPARKLINE_LINE",
                                            "referenceRanges": [
                                                {
                                                    "id": "669a5d86df5be15d5b4a2245",
                                                    "associatedColor": "#205979",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                }
                                            ],
                                            "artifactType": "ATTRIBUTE"
                                        }
                                    ]
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 6
                        }
                    ]
                }
            ],
            "attributes": [],
            "hidePeriodSelector": false,
            "taggedtoMe": false
        },
        {
            "screenName": "Patient report alt",
            "showWelcomePopup": false,
            "items": [],
            "sections": [],
            "grids": [
                {
                    "id": "669e304d3cf7134c118fb3f3",
                    "name": "New column",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "3b95b480-3111-4244-b47e-54396c22bf4e",
                            "title": "Comorbidities",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191e4",
                                    "name": "CoMorbHumanoid",
                                    "title": "Humanoid",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a1e2fd0e6230a22da1d08",
                                        "name": "CoMorbHumanoid",
                                        "title": "Humanoid",
                                        "attributeType": "OBSERVATION_HUMANOID",
                                        "dataField": "ObservationHumanoid",
                                        "observationCode": {
                                            "code": "CoMorbHumanoid",
                                            "display": "Humanoid",
                                            "id": "665b34e2d0e6230a2248726f"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665b3556d0e6230a224b04f2",
                                                "code": {
                                                    "code": "CoMorbHumanoid",
                                                    "display": "Humanoid",
                                                    "id": "665b34e2d0e6230a2248726f"
                                                },
                                                "value": {
                                                    "valueAsString": "2 values available",
                                                    "valueString": "2 values available",
                                                    "valueHumanoids": [
                                                        {
                                                            "id": "669e135f3cf7134c1153712b",
                                                            "title": "Congestive Heart Disease",
                                                            "position": "Upper-Torso",
                                                            "customData": {
                                                                "Severity": "moderate",
                                                                "Impact on Daily Functioning": "moderate",
                                                                "LVEF": "3",
                                                                "NYHA ": "II"
                                                            },
                                                            "interpretation": {
                                                                "id": "665d42dbd64e22283592ae34",
                                                                "interpretation": "Upper-Torso",
                                                                "comment": "POSITION",
                                                                "associatedImage": {
                                                                    "id": "665d874c7b8b1a2fb8e6e96f",
                                                                    "format": "IMAGE",
                                                                    "fileName": "25.png",
                                                                    "contentStorageKey": "S32f638796-ba7a-4efd-806f-246d2d742943.png",
                                                                    "thumbnailStorageKey": "S33af5bcf8-2eaf-4553-ae4e-9a3e7c53fe3e.png",
                                                                    "iconStorageKey": "S38acd47af-d84c-40ba-abd9-e42b09c5b34c.png",
                                                                    "bucketName": "mpshome",
                                                                    "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S32f638796-ba7a-4efd-806f-246d2d742943.png",
                                                                    "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S33af5bcf8-2eaf-4553-ae4e-9a3e7c53fe3e.png",
                                                                    "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S38acd47af-d84c-40ba-abd9-e42b09c5b34c.png",
                                                                    "thumbnailFileName": "25_thumb.png",
                                                                    "iconFileName": "25_icon.png"
                                                                },
                                                                "associatedColor": "",
                                                                "numberOfItems": 0,
                                                                "maxValue": 0,
                                                                "minValue": 0
                                                            }
                                                        },
                                                        {
                                                            "id": "669e135f3cf7134c1153712c",
                                                            "title": "Morbid Obesity",
                                                            "position": "Lower-Torso",
                                                            "customData": {
                                                                "Severity": "mild",
                                                                "Impact on Daily Functioning": "mild",
                                                                "Body Mass Index (BMI)": "23"
                                                            },
                                                            "interpretation": {
                                                                "id": "665d43fdd64e22283595507c",
                                                                "interpretation": "Lower-Torso",
                                                                "comment": "POSITION",
                                                                "associatedImage": {
                                                                    "id": "665d88377b8b1a2fb8e77672",
                                                                    "format": "IMAGE",
                                                                    "fileName": "16.png",
                                                                    "contentStorageKey": "S3ae552dbc-51b2-46b0-ba92-3274e94dc7a5.png",
                                                                    "thumbnailStorageKey": "S35d924a29-e70d-47b8-955f-0ab97d07586f.png",
                                                                    "iconStorageKey": "S33ec7863d-74d0-48b8-ae9c-668b510fcd4f.png",
                                                                    "bucketName": "mpshome",
                                                                    "contentURL": "https://d3binf6v0sj6x3.cloudfront.net/S3ae552dbc-51b2-46b0-ba92-3274e94dc7a5.png",
                                                                    "thumbnailURL": "https://d3binf6v0sj6x3.cloudfront.net/S35d924a29-e70d-47b8-955f-0ab97d07586f.png",
                                                                    "iconURL": "https://d3binf6v0sj6x3.cloudfront.net/S33ec7863d-74d0-48b8-ae9c-668b510fcd4f.png",
                                                                    "thumbnailFileName": "16_thumb.png",
                                                                    "iconFileName": "16_icon.png"
                                                                },
                                                                "numberOfItems": 0,
                                                                "maxValue": 0,
                                                                "minValue": 0
                                                            }
                                                        }
                                                    ]
                                                },
                                                "observationDate": 1721635679650,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "a3db0078-d91f-4a16-a6f8-aae8e8be61eb",
                            "title": "Intrinsic capacity",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [],
                            "criteriaToHide": []
                        },
                        {
                            "id": "4c7b2d4c-abfd-4bd7-8266-0e8690451ad4",
                            "title": "Functional status",
                            "expandByDefault": false,
                            "headerColor": "#FFFFFF",
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191e5",
                                    "name": "ADLs",
                                    "title": "ADLs",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ae",
                                        "name": "ADLs",
                                        "title": "ADLs",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "ADLs",
                                            "display": "ADLs",
                                            "id": "66608f7bb1a0524cad1702f9"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e527ff",
                                                "code": {
                                                    "code": "ADLs",
                                                    "display": "ADLs",
                                                    "id": "664c5f3532ecdd7abc8dcd2d"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66608f7bb1a0524cad1702f7",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1720091519423,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191e6",
                                    "name": "iADLs",
                                    "title": "iADLs",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b6",
                                        "name": "iADLs",
                                        "title": "iADLs",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "iADLs",
                                            "display": "iADLs",
                                            "id": "6660900bb1a0524cad1bb9b2"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52807",
                                                "code": {
                                                    "code": "iADLs",
                                                    "display": "iADLs",
                                                    "id": "664c603432ecdd7abcb6bfad"
                                                },
                                                "value": {
                                                    "valueAsString": "3",
                                                    "valueString": "3",
                                                    "valueInteger": 3
                                                },
                                                "interpretation": {
                                                    "id": "6660900bb1a0524cad1bb9b1",
                                                    "min": 3,
                                                    "max": 4,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#D50000",
                                                        "#D50000"
                                                    ],
                                                    "associatedColor": "#D50000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                "observationDate": 1717661161885,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191e7",
                                    "name": "Balance",
                                    "title": "Balance",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9af",
                                        "name": "Balance",
                                        "title": "Balance",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Balance",
                                            "display": "Balance",
                                            "id": "66829e1fd68d687017be598e"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52801",
                                                "code": {
                                                    "code": "Balance",
                                                    "display": "Balance",
                                                    "id": "664c5fa632ecdd7abc9a637e"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66609022b1a0524cad1e9c1f",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161870,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191e8",
                                    "name": "WalkingAid",
                                    "title": "Walking Aid",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b0",
                                        "name": "WalkingAid",
                                        "title": "Walking Aid",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "WalkingAid",
                                            "display": "Walking Aid",
                                            "id": "667b4b54a1932c599b5db35c"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52803",
                                                "code": {
                                                    "code": "WalkingAid",
                                                    "display": "Walking Aid",
                                                    "id": "664b496932ecdd7abc65ad8e"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0"
                                                },
                                                "interpretation": {
                                                    "id": "665a3234d0e6230a22ee49f6",
                                                    "min": 0,
                                                    "max": 0,
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161875,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "664b496932ecdd7abc65ad8b",
                                                    "code": {
                                                        "code": "WalkingAid",
                                                        "display": "Walking Aid",
                                                        "id": "664b496932ecdd7abc65ad8e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Yes",
                                                        "valueString": "Yes"
                                                    },
                                                    "observationDate": 1716210025634,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "WalkingAid",
                                                    "display": "Walking Aid",
                                                    "id": "664b496932ecdd7abc65ad8e"
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "664b496932ecdd7abc65ad8d",
                                                    "code": {
                                                        "code": "WalkingAid",
                                                        "display": "Walking Aid",
                                                        "id": "664b496932ecdd7abc65ad8e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "No",
                                                        "valueString": "No"
                                                    },
                                                    "observationDate": 1716210025634,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                },
                                                "valueCode": {
                                                    "code": "WalkingAid",
                                                    "display": "Walking Aid",
                                                    "id": "664b496932ecdd7abc65ad8e"
                                                }
                                            }
                                        ],
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191e9",
                                    "name": "AdherenceProblem",
                                    "title": "Adherence problem",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b1",
                                        "name": "AdherenceProblem",
                                        "title": "Adherence problem",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "AdherenceProblem",
                                            "display": "Adherence problem",
                                            "id": "667b4b63a1932c599b5df6af"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52805",
                                                "code": {
                                                    "code": "AdherenceProblem",
                                                    "display": "Adherence problem",
                                                    "id": "664c5fee32ecdd7abca25ff5"
                                                },
                                                "value": {
                                                    "valueAsString": "3",
                                                    "valueString": "3",
                                                    "valueInteger": 3
                                                },
                                                "interpretation": {
                                                    "id": "6660905eb1a0524cad21c57c",
                                                    "min": 3,
                                                    "max": 4,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#D50000",
                                                        "#D50000"
                                                    ],
                                                    "associatedColor": "#D50000",
                                                    "numberOfItems": 0,
                                                    "maxValue": 4,
                                                    "minValue": 3
                                                },
                                                "observationDate": 1717661161880,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ea",
                                    "name": "Falls",
                                    "title": "Falls",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c961",
                                        "name": "Falls",
                                        "title": "Falls",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Falls",
                                            "display": "Falls",
                                            "id": "667b0cd8a1932c599b3c32e2"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "6660975bb1a0524cad9652be",
                                                "code": {
                                                    "code": "Falls",
                                                    "display": "Falls",
                                                    "id": "65f476248f67055e24155c25"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "65f476248f67055e24155c21",
                                                    "min": 0,
                                                    "max": 0,
                                                    "gradientColors": [
                                                        "#1AE613",
                                                        "#1AE613"
                                                    ],
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1720015781921,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191eb",
                                    "name": "ChairStandTestResult",
                                    "title": "Chair Stand Test Result",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c96d",
                                        "name": "ChairStandTestResult",
                                        "title": "Chair Stand Test Result",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "ChairStandTestResult",
                                            "display": "Chair Stand Test Result",
                                            "id": "668d5feef7fbda3460cffb05"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0db463bbe5014eb70872",
                                                "code": {
                                                    "code": "ChairStandTestResult",
                                                    "display": "Chair Stand Test Result",
                                                    "id": "661e51724c51bf0a283e4a56"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "661e51724c51bf0a283e4a52",
                                                    "min": 0,
                                                    "max": 15,
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 15,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717666969335,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ec",
                                    "name": "Continence",
                                    "title": "Continence",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9b7",
                                        "name": "Continence",
                                        "title": "Continence",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Continence",
                                            "display": "Continence",
                                            "id": "6682ae0ec2893e00c6b4037d"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e5280b",
                                                "code": {
                                                    "code": "Continence",
                                                    "display": "Continence",
                                                    "id": "664c604232ecdd7abcb90977"
                                                },
                                                "value": {
                                                    "valueAsString": "2",
                                                    "valueString": "2",
                                                    "valueInteger": 2
                                                },
                                                "interpretation": {
                                                    "id": "66608f8db1a0524cad17d056",
                                                    "min": 2,
                                                    "max": 2,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FF7E01",
                                                        "#FF7E01"
                                                    ],
                                                    "associatedColor": "#FF7E01",
                                                    "numberOfItems": 0,
                                                    "maxValue": 2,
                                                    "minValue": 2
                                                },
                                                "observationDate": 1717661161896,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ed",
                                    "name": "PerformanceStatus",
                                    "title": "Performance status",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9bd",
                                        "name": "PerformanceStatus",
                                        "title": "Performance status",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "PerformanceStatus",
                                            "display": "Performance status",
                                            "id": "667b4c48a1932c599b5fce3c"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e5280d",
                                                "code": {
                                                    "code": "PerformanceStatus",
                                                    "display": "Performance status",
                                                    "id": "664c60bf32ecdd7abcc9d741"
                                                },
                                                "value": {
                                                    "valueAsString": "1",
                                                    "valueString": "1",
                                                    "valueInteger": 1
                                                },
                                                "interpretation": {
                                                    "id": "66609017b1a0524cad1dd4d8",
                                                    "min": 1,
                                                    "max": 1,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#FFD740",
                                                        "#FFD740"
                                                    ],
                                                    "associatedColor": "#FFD740",
                                                    "numberOfItems": 0,
                                                    "maxValue": 1,
                                                    "minValue": 1
                                                },
                                                "observationDate": 1717661161901,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ee",
                                    "name": "IntrinsicMobility",
                                    "title": "Mobility",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c7",
                                        "name": "IntrinsicMobility",
                                        "title": "Mobility",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "IntrinsicMobility",
                                            "display": "Intrinsic Mobility",
                                            "id": "667aeb2ba1932c599b8c7f35"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e5280f",
                                                "code": {
                                                    "code": "IntrinsicMobility",
                                                    "display": "Intrinsic Mobility",
                                                    "id": "664db4772d69832160639c39"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "6660903db1a0524cad2030c2",
                                                    "min": 0,
                                                    "max": 0,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161906,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191ef",
                                    "name": "IntrinsicFatigue",
                                    "title": "Fatigue",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c6",
                                        "name": "IntrinsicFatigue",
                                        "title": "Fatigue",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "IntrinsicFatigue",
                                            "display": "Intrinsic Fatigue",
                                            "id": "66609052b1a0524cad20fe37"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a289ad0e6230a22e52811",
                                                "code": {
                                                    "code": "IntrinsicFatigue",
                                                    "display": "Intrinsic Fatigue",
                                                    "id": "664db4562d6983216061348f"
                                                },
                                                "value": {
                                                    "valueAsString": "0",
                                                    "valueString": "0",
                                                    "valueInteger": 0
                                                },
                                                "interpretation": {
                                                    "id": "66609052b1a0524cad20fe33",
                                                    "min": 0,
                                                    "max": 0,
                                                    "interpretation": "",
                                                    "comment": "",
                                                    "gradientColors": [
                                                        "#24BF06",
                                                        "#24BF06"
                                                    ],
                                                    "associatedColor": "#24BF06",
                                                    "numberOfItems": 0,
                                                    "maxValue": 0,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1717661161910,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": [],
                            "sectionColumns": 3
                        }
                    ]
                },
                {
                    "id": "669e409b3cf7134c11a431c7",
                    "name": "New column",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "7637ca75-d68f-4e85-9202-0a1d702ef709",
                            "title": "Lee Index",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191f0",
                                    "name": "LeeIndex4YearSRDisplay",
                                    "title": "4 Year Survival Rate %",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66600739c918c649aa8ed524",
                                        "name": "LeeIndex4YearSRDisplay",
                                        "title": "4 Year Survival Rate %",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "LeeIndex4YearSRDisplay",
                                            "display": "4 Year Survival Rate",
                                            "id": "667ae711c1a61e02fdf99da7"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "66605cfcb1a0524cad4bb228",
                                                "code": {
                                                    "code": "LeeIndex4YearSRDisplay",
                                                    "display": "4 Year Survival Rate",
                                                    "id": "66600739c918c649aa8ed525"
                                                },
                                                "value": {
                                                    "valueAsString": "36%",
                                                    "valueString": "36%"
                                                },
                                                "interpretation": {
                                                    "id": "666082edb1a0524cad284c29",
                                                    "min": 14,
                                                    "max": 30,
                                                    "values": [
                                                        "36%"
                                                    ],
                                                    "interpretation": "36%",
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 30,
                                                    "minValue": 14
                                                },
                                                "observationDate": 1717666942308,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "interpretation": {
                                            "id": "666082edb1a0524cad284c29",
                                            "min": 14,
                                            "max": 30,
                                            "values": [
                                                "36%"
                                            ],
                                            "interpretation": "36%",
                                            "associatedColor": "#F81703",
                                            "numberOfItems": 0,
                                            "maxValue": 30,
                                            "minValue": 14
                                        },
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191f1",
                                    "name": "LeeIndex10YearSRDisplay",
                                    "title": "10 Year Survival Rate %",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "66600759c918c649aa8f9c24",
                                        "name": "LeeIndex10YearSRDisplay",
                                        "title": "10 Year Survival Rate %",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "LeeIndex10YearSRDisplay",
                                            "display": "10 Year Survival Rate",
                                            "id": "667ae700c1a61e02fdf91c9d"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "66605cfcb1a0524cad4bb22a",
                                                "code": {
                                                    "code": "LeeIndex10YearSRDisplay",
                                                    "display": "10 Year Survival Rate",
                                                    "id": "66600759c918c649aa8f9c25"
                                                },
                                                "value": {
                                                    "valueAsString": "9-17%",
                                                    "valueString": "9-17%"
                                                },
                                                "interpretation": {
                                                    "id": "66608393b1a0524cad32f6c2",
                                                    "min": 14,
                                                    "max": 30,
                                                    "values": [
                                                        "9-17%"
                                                    ],
                                                    "interpretation": "9-17%",
                                                    "associatedColor": "#F81703",
                                                    "numberOfItems": 0,
                                                    "maxValue": 30,
                                                    "minValue": 14
                                                },
                                                "observationDate": 1717666942319,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "interpretation": {
                                            "id": "66608393b1a0524cad32f6c2",
                                            "min": 14,
                                            "max": 30,
                                            "values": [
                                                "9-17%"
                                            ],
                                            "interpretation": "9-17%",
                                            "associatedColor": "#F81703",
                                            "numberOfItems": 0,
                                            "maxValue": 30,
                                            "minValue": 14
                                        },
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "fbc7bb21-15ec-4d3b-87d8-3fd6d4677b01",
                            "title": "Patient goals",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191f2",
                                    "name": "GoalExtendingLife",
                                    "title": "Extending Life",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a8",
                                        "name": "GoalExtendingLife",
                                        "title": "Extending Life",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalExtendingLife",
                                            "display": "Goal Extending Life",
                                            "id": "667b4af8a1932c599b5c62c4"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df4309",
                                                "code": {
                                                    "code": "GoalExtendingLife",
                                                    "display": "Goal Extending Life",
                                                    "id": "6659804af6c9157dc5cb3517"
                                                },
                                                "value": {
                                                    "valueAsString": "50",
                                                    "valueString": "50",
                                                    "valueInteger": 50
                                                },
                                                "interpretation": {
                                                    "id": "665a27b3d0e6230a22e1dc8d",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508095,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191f3",
                                    "name": "GoalReducingPain",
                                    "title": "Reducing or eliminating pain and other symptoms",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a9",
                                        "name": "GoalReducingPain",
                                        "title": "Reducing or eliminating pain and other symptoms",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalReducingPain",
                                            "display": "Reducing or eliminating pain and other symptoms",
                                            "id": "667eaa8251fe293d8ac51c63"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df430d",
                                                "code": {
                                                    "code": "GoalReducingPain",
                                                    "display": "Reducing Pain",
                                                    "id": "66598058f6c9157dc5cd0d71"
                                                },
                                                "value": {
                                                    "valueAsString": "30",
                                                    "valueString": "30",
                                                    "valueInteger": 30
                                                },
                                                "interpretation": {
                                                    "id": "665a27ddd0e6230a22e2a16b",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508111,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191f4",
                                    "name": "GoalMaintainingIndependence",
                                    "title": "Maintaining Independence",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9aa",
                                        "name": "GoalMaintainingIndependence",
                                        "title": "Maintaining Independence",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalMaintainingIndependence",
                                            "display": "Maintaining Independence",
                                            "id": "667b4b1aa1932c599b5ce964"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df430b",
                                                "code": {
                                                    "code": "GoalMaintainingIndependence",
                                                    "display": "Goal Maintaining Independence",
                                                    "id": "664b09d206027618bf9c72e0"
                                                },
                                                "value": {
                                                    "valueAsString": "70",
                                                    "valueString": "70",
                                                    "valueInteger": 70
                                                },
                                                "interpretation": {
                                                    "id": "665a27ebd0e6230a22e3664a",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508104,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191f5",
                                    "name": "GoalReducingOtherSymptoms",
                                    "title": "Preventing negative effects of treatment",
                                    "display": "HBC",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ab",
                                        "name": "GoalReducingOtherSymptoms",
                                        "title": "Preventing negative effects of treatment",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GoalReducingOtherSymptoms",
                                            "display": "Preventing negative effects",
                                            "id": "667eaa5c51fe293d8ac15f0f"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665a251fd0e6230a22df430f",
                                                "code": {
                                                    "code": "GoalReducingOtherSymptoms",
                                                    "display": "Goal Reducing Other Symptoms",
                                                    "id": "664b09f406027618bf9e4f0a"
                                                },
                                                "value": {
                                                    "valueAsString": "70",
                                                    "valueString": "70",
                                                    "valueInteger": 70
                                                },
                                                "interpretation": {
                                                    "id": "665a27f8d0e6230a22e42b2a",
                                                    "min": 0,
                                                    "max": 100,
                                                    "associatedColor": "#9893C9",
                                                    "numberOfItems": 0,
                                                    "maxValue": 100,
                                                    "minValue": 0
                                                },
                                                "observationDate": 1718956508116,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "425eda8e-561a-471b-8bff-30d20c55f5f7",
                            "title": "Clinical frailty scale",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191f6",
                                    "name": "Frailty",
                                    "title": "Current status",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a7",
                                        "name": "Frailty",
                                        "title": "Current status",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "Frailty",
                                            "display": "Frailty",
                                            "id": "667d82e3d04dd55b8ae30c86"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f0be863bbe5014eb1e7c8",
                                                "code": {
                                                    "code": "Frailty",
                                                    "display": "Frailty",
                                                    "id": "664776e206027618bf7fcf0a"
                                                },
                                                "value": {
                                                    "valueAsString": "Managing well",
                                                    "valueString": "Managing well"
                                                },
                                                "observationDate": 1717535142161,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "22d3c508-343c-428f-8d4a-2e24d6f8ec03",
                            "title": "GP Input",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191f7",
                                    "name": "GPKnowledgeOfPatient",
                                    "title": "Knowledge Of Patient",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a3",
                                        "name": "GPKnowledgeOfPatient",
                                        "title": "Knowledge Of Patient",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "GPKnowledgeOfPatient",
                                            "display": "GP Knowledge Of Patient",
                                            "id": "667b4a7fa1932c599b5b1238"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eff4bb43ea86f39b436a2",
                                                "code": {
                                                    "code": "GPKnowledgeOfPatient",
                                                    "display": "GP Knowledge Of Patient",
                                                    "id": "664775e906027618bf77ba21"
                                                },
                                                "value": {
                                                    "valueAsString": "I have fair knowledge about them",
                                                    "valueString": "I have fair knowledge about them"
                                                },
                                                "observationDate": 1717501771885,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191f8",
                                    "name": "GPAnnualVisits",
                                    "title": "GP Annual Visits",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a4",
                                        "name": "GPAnnualVisits",
                                        "title": "GP Annual Visits",
                                        "attributeType": "OBSERVATION_INTEGER",
                                        "dataField": "ObservationInteger",
                                        "observationCode": {
                                            "code": "GPAnnualVisits",
                                            "display": "GP Annual Visits",
                                            "id": "667b4a98a1932c599b5b5590"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eff4bb43ea86f39b436a4",
                                                "code": {
                                                    "code": "GPAnnualVisits",
                                                    "display": "GP Annual Visits",
                                                    "id": "6647761106027618bf78a38b"
                                                },
                                                "value": {
                                                    "valueAsString": "Between 6 times and 12 times",
                                                    "valueString": "Between 6 times and 12 times"
                                                },
                                                "observationDate": 1717501771917,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191f9",
                                    "name": "GPPatientFitnessAssessment",
                                    "title": "Fitness Assessment",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a5",
                                        "name": "GPPatientFitnessAssessment",
                                        "title": "Fitness Assessment",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "GPPatientFitnessAssessment",
                                            "display": "GP Patient Fitness Assessment",
                                            "id": "667b4aaba1932c599b5b98d6"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665eff4bb43ea86f39b436a6",
                                                "code": {
                                                    "code": "GPPatientFitnessAssessment",
                                                    "display": "GP Patient Fitness Assessment",
                                                    "id": "6647763506027618bf7a5f58"
                                                },
                                                "value": {
                                                    "valueAsString": "Weak, not generally Fit",
                                                    "valueString": "Weak, not generally Fit"
                                                },
                                                "observationDate": 1717501771925,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191fa",
                                    "name": "GPPatientSupportNeeds",
                                    "title": "GP Patient Support Needs",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9a6",
                                        "name": "GPPatientSupportNeeds",
                                        "title": "GP Patient Support Needs",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "GPPatientSupportNeeds",
                                            "display": "GP Patient Support Needs",
                                            "id": "667b4abfa1932c599b5bdc1c"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        }
                    ]
                },
                {
                    "id": "669e7368e1f5d461d19bdb8e",
                    "name": "New column",
                    "expandByDefault": false,
                    "sections": [
                        {
                            "id": "0b5a1b3a-9ee2-4a24-8c35-4b038e57b7dd",
                            "title": "Medications, allergies & substance abuse",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191fb",
                                    "name": "MedicationsDescription",
                                    "title": "Medications Description",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ac",
                                        "name": "MedicationsDescription",
                                        "title": "Medications Description",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "MedicationsDescription",
                                            "display": "Medications Description",
                                            "id": "667b4b3ca1932c599b5d7003"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f58b8c918c649aae9457e",
                                                "code": {
                                                    "code": "MedicationsDescription",
                                                    "display": "Medications Description",
                                                    "id": "665efdd5b43ea86f39a84c69"
                                                },
                                                "value": {
                                                    "valueAsString": "",
                                                    "valueString": ""
                                                },
                                                "isMultipleAnswersPermitted": true,
                                                "values": [
                                                    {
                                                        "valueAsString": "Metaformin 500 mg, 2 times day 2",
                                                        "valueString": "Metaformin 500 mg, 2 times day 2"
                                                    }
                                                ],
                                                "observationDate": 1717524664593,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191fc",
                                    "name": "Allergies",
                                    "title": "Allergies",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c3",
                                        "name": "Allergies",
                                        "title": "Allergies",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "Allergies",
                                            "display": "Allergies",
                                            "id": "664c976432ecdd7abc673aa9"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f5924c918c649aaec5659",
                                                "code": {
                                                    "code": "Allergies",
                                                    "display": "Allergies",
                                                    "id": "664c976432ecdd7abc673aa9"
                                                },
                                                "value": {
                                                    "valueAsString": "",
                                                    "valueString": ""
                                                },
                                                "isMultipleAnswersPermitted": true,
                                                "values": [
                                                    {
                                                        "valueAsString": "Pollen",
                                                        "valueString": "Pollen"
                                                    }
                                                ],
                                                "observationDate": 1717524772692,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                },
                                {
                                    "id": "66a10cdb47c69771f74191fd",
                                    "name": "SubstanceAbuse",
                                    "title": "Substance Abuse",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9c4",
                                        "name": "SubstanceAbuse",
                                        "title": "Substance Abuse",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "SubstanceAbuse",
                                            "display": "Substance Abuse",
                                            "id": "667aeb6ea1932c599b8d156d"
                                        },
                                        "value": {},
                                        "scanFromBarCode": false,
                                        "options": [
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a17",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Nicotine",
                                                        "valueString": "Nicotine"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a19",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Alcohol",
                                                        "valueString": "Alcohol"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a1b",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Medication Abuse",
                                                        "valueString": "Medication Abuse"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            },
                                            {
                                                "valueObservation": {
                                                    "id": "6672ba64209c7f20392b3a1d",
                                                    "code": {
                                                        "code": "SubstanceAbuse",
                                                        "display": "Substance Abuse",
                                                        "id": "6672ba64209c7f20392b3a1e"
                                                    },
                                                    "value": {
                                                        "valueAsString": "Other",
                                                        "valueString": "Other"
                                                    },
                                                    "observationDate": 1718794852952,
                                                    "added": false,
                                                    "modified": false,
                                                    "deleted": false
                                                }
                                            }
                                        ],
                                        "isMultipleAnswersPermitted": true,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": true
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        },
                        {
                            "id": "9843dff7-0813-459d-9d91-a3706fe089e7",
                            "title": "MTB Recommendations",
                            "expandByDefault": false,
                            "type": "OPEN",
                            "items": [
                                {
                                    "id": "66a10cdb47c69771f74191fe",
                                    "name": "MTBRecommendations",
                                    "title": "MTB recommendation",
                                    "type": "ATTRIBUTE",
                                    "hidden": false,
                                    "criteriaToHide": [],
                                    "attribute": {
                                        "attributeId": "665a0722d0e6230a22d2c9ad",
                                        "name": "MTBRecommendations",
                                        "title": "MTB recommendation",
                                        "attributeType": "OBSERVATION",
                                        "dataField": "Observation",
                                        "observationCode": {
                                            "code": "MTBRecommendations",
                                            "display": "MTB recommendation",
                                            "id": "66612c6fb1a0524cade67d35"
                                        },
                                        "value": {
                                            "valueObservation": {
                                                "id": "665f01b0b43ea86f39c6a809",
                                                "code": {
                                                    "code": "MTBRecommendations",
                                                    "display": "MTB recommendations",
                                                    "id": "664b414e32ecdd7abcfad04b"
                                                },
                                                "value": {
                                                    "valueAsString": "This is our recommendation",
                                                    "valueString": "This is our recommendation"
                                                },
                                                "observationDate": 1718868707937,
                                                "added": false,
                                                "modified": false,
                                                "deleted": false
                                            }
                                        },
                                        "scanFromBarCode": false,
                                        "observationType": true,
                                        "editable": true,
                                        "choiceList": false
                                    }
                                }
                            ],
                            "criteriaToHide": []
                        }
                    ]
                }
            ],
            "attributes": [],
            "hidePeriodSelector": false,
            "taggedtoMe": false
        }
    ],
    "patientTypeId": "665a0722d0e6230a22d2c919",
    "reportPeriod": {
        "start": 1719238616277,
        "end": 1721779200000
    },
    "attributes": [],
    "hidePeriodSelector": false,
    "taggedtoMe": false
});

export { dashboardMock };
