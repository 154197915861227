import React from "react";
import HTMLFormatter from "../../../Common/HTMLFormatter";

export default class HtmlContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.state = { dummy: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dummy: true });
    }, 500);
  }

  componentDidUpdate() {
    let html = this.props.html;
    if (html) {
      let index = html.lastIndexOf("<script>");
      if (index !== -1) {
        let scripts = html.substring(index + 8, html.lastIndexOf("</script>"));
        if (scripts) {
          var ex = Function(scripts);
          var out = ex();
        }
      }
    }
  }

  render() {
    return (
      <HTMLFormatter>
        <div
          style={this.props.style}
          dangerouslySetInnerHTML={{ __html: this.props.html }}
        />
      </HTMLFormatter>
    );
  }
}
