import { PATIENT_API_ENDPOINTS as API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import StorageService from "./storageService";
import { getAppointmentFilter, getSlotFilter } from "../Shared/Utils";
import { store } from "../State/store/configureStore";
import FeaturesService from "./featuresService";
import moment from "moment";

const PATIENT_APP_HOME_VIEW_TABS = "PATIENT_APP_HOME_VIEW_TABS";

class PatientAppServices {
  static loadCarePlan() {
    return http.request(API_ENDPOINTS.carePlan, "get");
  }

  static loadPatientProfile() {
    return http.request(API_ENDPOINTS.patientProfile, "get");
  }

  static updateProfileAttribute(data) {
    return http.request(API_ENDPOINTS.updateProfileAttribute, "post", data);
  }

  static loadStatusMessages(page) {
    return http
      .request(API_ENDPOINTS.statusMessages + `&page=${page}&size=30`)
      .then(data => {
        if (data && data._embedded) {
          return {
            data:
              page <= data.page.totalPages ? data._embedded.statusMessages : [],
            loadMore: data.page.totalPages > page + 1
          };
        } else {
          return {
            data: [],
            loadMore: false
          };
        }
      });
  }

  static loadProvisionedSurveys() {
    return http.request(API_ENDPOINTS.provisionedSurveys).then(data => {
      return data && data._embedded ? data._embedded.provisionedSurveys : [];
    });
  }

  static loadSubmittedSurveys() {
    return http.request(API_ENDPOINTS.submittedSurveys).then(data => {
      return data
        ? {
            availableSurveys: data.availableSurveys,
            latestSurveyId: data.latestSurveyId
          } || {}
        : {};
    });
  }

  static loadSubmittedSurveyResponsesBySurveyId(surveyId, page = 0, size = 30) {
    return http
      .request(API_ENDPOINTS.surveysResponsesById(surveyId, page, size))
      .then(data => {
        return {
          responses:
            data && data._embedded ? data._embedded.surveyResponses : [],
          totalPages: data.page.totalPages
        };
      });
  }

  // static loadSurveyResponses() {
  //   return http.request(API_ENDPOINTS.surveyResponses).then(data => {
  //     return data && data._embedded ? data._embedded.surveyResponses : [];
  //   });
  // }

  static loadTasks(completed) {
    return http
      .request(API_ENDPOINTS.tasks + (completed ? "?completedOnly=true" : ""))
      .then(response => {
        if (response && response._embedded) {
          return response._embedded.tasks || [];
        }
        return [];
      });
  }

  static loadOpenTasksCount() {
    return http.request(API_ENDPOINTS.tasks).then(response => {
      if (response && response.page) {
        return response.page.totalElements || 0;
      }
      return 0;
    });
  }

  static loadMembers() {
    return http.request();
  }

  static loadSurvey(surveyLink) {
    return http.request(surveyLink);
  }

  static loadInfoPanel() {
    return http.request(API_ENDPOINTS.infoPanel);
  }

  static submitConsent(messageId, consentId, data) {
    return http.request(
      API_ENDPOINTS.consentSubmission(messageId, consentId),
      "post",
      data
    );
  }

  // static blobToFile(theBlob, fileName) {
  //   theBlob.lastModifiedDate = new Date().getTime();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }

  static getHomeViewTabs() {
    let tabs = StorageService.getFromLocalStorage(PATIENT_APP_HOME_VIEW_TABS);
    let userPrivileges =
      store.getState().user && store.getState().user.data
        ? store.getState().user.data.userPrivileges || []
        : [];

    if (!tabs) {
      tabs = [
        {
          id: "dashboard",
          label: "DASHBOARD"
        },

        {
          id: "updates",
          label: "UPDATES"
        },
        {
          id: "tasks",
          label: "TASKS"
        }
      ];

      // if (
      //   FeaturesService.canTasks() &&
      //   userPrivileges.indexOf("HIDE_TASK_MENU") === -1
      // ) {
      //   tabs.push({
      //     id: "tasks",
      //     label: "TASKS"
      //   });
      // }

      if (
        FeaturesService.canDoAppointments() &&
        userPrivileges.indexOf("HIDE_APPOINTMENT_MENU") === -1
      ) {
        tabs.push({
          id: "appointments",
          label: "APPOINTMENTS"
        });
      }

      tabs = tabs.concat([
        {
          id: "progress",
          label: "PROGRESS"
        },
        {
          id: "info",
          label: "INFO"
        }
      ]);
    }

    return tabs;
  }

  storeHomeViewTabs(tabs) {
    StorageService.addToLocalStorage(PATIENT_APP_HOME_VIEW_TABS, tabs);
  }

  // API: Get appointment schedule /api/patient/schedules/{id}
  // Get app allointments for a staff /api/patient/appointments?staffId= etc
  //     Create appointment api/patient/appointments

  static getAppointmentSchedule(scheduleId) {
    return http.request(API_ENDPOINTS.appointmentSchedule(scheduleId));
  }

  static getAppointmentScheduleAnonymous(
    scheduleId,
    patientId,
    userId,
    templateId
  ) {
    return http.request(
      API_ENDPOINTS.appointmentSchedule(scheduleId) +
        `?uid=${userId}&pid=${patientId}&tid=${templateId}`
    );
  }

  static getSlots(
    filter = {},
    scheduleId,
    start,
    end,
    countOnly,
    patientId,
    userId
  ) {
    let api = API_ENDPOINTS.slots + "?page=0&size=2000";
    api += getSlotFilter(filter, scheduleId, start, end, countOnly);
    api += `&uid=${userId}&pid=${patientId}`;

    return http.request(api).then((slots = []) => {
      if (countOnly) {
        slots.forEach(slot => {
          slot.allDay = true;
          slot.start = new Date(slot.statDate);
          slot.end = new Date(slot.statDate);
          slot.title = slot.count + (slot.count === 1 ? " Slot" : " Slots");
        });
      } else {
        slots.forEach(slot => {
          slot.start = new Date(slot.start);
          slot.end = new Date(slot.end);
        });
      }

      return slots;
    });
  }

  static createAppointmentAnonymous = (data, patientId, userId) => {
    return http.request(
      API_ENDPOINTS.createAppointmentFromTemplate() +
        `?uid=${userId}&pid=${patientId}`,
      "post",
      data
    );
  };

  static getGroupCallAppointments(userId, patientId, scheduleId) {
    return http
      .request(API_ENDPOINTS.groupCalls(userId, patientId, scheduleId))
      .then(data => {
        return data && data._embedded ? data._embedded.appointments || [] : [];
      });
  }

  static joinGroupClass = (data, userId, patientId) => {
    return http.request(
      API_ENDPOINTS.joinGroupCall(userId, patientId),
      "post",
      data
    );
  };

  static loadUpcomingAppointments() {
    let start = moment().format("DDMMYYYY");
    let end = moment()
      .add(90, "d")
      .format("DDMMYYYY");
    return http
      .request(
        API_ENDPOINTS.futureAppointments + "?start=" + start + "&end=" + end
      )
      .then(data => {
        let appointments = [];
        if (data && data._embedded) {
          appointments = data._embedded.appointments || [];
        }
        return appointments;
      });
  }

  static loadAllAppointments(start, end) {
    let api = API_ENDPOINTS.appointments + `?`;

    if (start) {
      api += `&start=${start}`;
    }
    if (end) {
      api += `&end=${end}`;
    }

    return http.request(api).then(data => {
      if (data && data._embedded) {
        let appointments = data._embedded.appointments || [];
        appointments.forEach(appointment => {
          appointment.start = new Date(appointment.start);
          appointment.end = new Date(appointment.end);
        });

        return appointments;
      }
      return [];
    });
  }

  static fetchTaskTemplates() {
    return http
      .request(API_ENDPOINTS.taskTemplates + "?page=0&size=100")
      .then(data => {
        if (data && data._embedded) {
          return data._embedded.taskTemplates || [];
        }
        return [];
      });
  }

  static fetchTaskFromTaskTemplate(templateId) {
    return http.request(API_ENDPOINTS.taskFromTaskTemplate(templateId));
  }

  static createTask(task) {
    return http.request(API_ENDPOINTS.tasks, "post", task);
  }

  static completeCarePlanTask(carePlanId, carePlanActionId) {
    let data = {
      carePlanId,
      carePlanActionId,
      status: "COMPLETED"
    };
    return http.request(API_ENDPOINTS.carePlanProgress, "post", data);
  }

  static completeCarePlanTaskChecklist(data, patientId) {
    return http.request(API_ENDPOINTS.carePlanProgressChecklist(patientId), "post", data);
  }

  static loadAdhocSurveysForPatients() {
    let endpoint =
      API_ENDPOINTS.surveys + `?page=${0}&size=${1000}&getOnlyAdHoc=true`;

    return http.request(endpoint).then(data => {
      if (data && data._embedded) {
        return {
          data: data._embedded.surveys || []
        };
      } else {
        return {
          data: []
        };
      }
    });
  }

  static getAppointmentScreening(appointmentRefNumber, mrn, surveyId) {
    let api = API_ENDPOINTS.appointmentScreening(appointmentRefNumber, mrn);
    if (surveyId) {
      api += `&surveyId=${surveyId}`;
    }
    return http.request(api);
  }

  static doAppointmentScreening(appointmentRefNumber, mrn, responseData) {
    return http.request(
      API_ENDPOINTS.appointmentScreening(appointmentRefNumber, mrn),
      "post",
      responseData
    );
  }

  static triggerAction(data) {
    return http.request(API_ENDPOINTS.actions(), "post", data);
  }

  static registerWithings(code) {
    return http.request(API_ENDPOINTS.regWithings(), "post", { code });
  }
}

export default PatientAppServices;
