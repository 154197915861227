import React, { PureComponent } from "react";
import { showLocalizedToaster } from "../../../State/actions/toaster";
import { connect } from "react-redux";
import PatientAppServices from "../../../services/PatientAppServices";
import AnonymousAppointmentScreening from "./AnonymousAppointmentScreening";
import ExternalSurveySubmission from "./ExternalSurveySubmission";

class AnonymousAppointmentScreeningContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadAppointmentScreening();
  }

  loadAppointmentScreening = () => {
    let { mrn, appointmentRefNumber } = this.props;
    this.setState({ loading: true });
    if(mrn){
    PatientAppServices.getAppointmentScreening(appointmentRefNumber, mrn)
      .then(data => {
        this.setState({
          appointment: data.appointment,
          appTheme: data.appTheme,
          loading: false
        });
      })
      .catch(e => {
        this.setState({ loading: false, error: e.message });
      });
    }
  };

  render() {
    let { mrn, appointmentRefNumber, patientId, surveyId } = this.props;
    let { loading, appointment, appTheme, error } = this.state;

    if(mrn){
    return (
      <AnonymousAppointmentScreening
        loadAppointmentScreening={this.loadAppointmentScreening}
        loading={loading}
        appointment={appointment}
        appTheme={appTheme}
        mrn={mrn}
        appointmentRefNumber={appointmentRefNumber}
        error={error}
        patientId={patientId}
        surveyId={surveyId}
      />
    );
  }
  else if(patientId){
    return (
      <ExternalSurveySubmission patientId={patientId} surveyId={surveyId}/>
    )
  }
  }
}

const actions = {
  showToaster: showLocalizedToaster
};

const data = (state, ownProps) => {
  // let params = null;
  // if (ownProps.location && ownProps.location.search) {
  //   params = parseURLQueryParams(ownProps.location.search);
  // }

  let mrn = null;
  let appointmentRefNumber = null;
  let patientId = null;
  let surveyId = null;
  let key = ownProps.match ? ownProps.match.params.key : null;
  let eskey = ownProps.match ? ownProps.match.params.eskey : null;

  if (key) {
    key = atob(key);
    key = key.split("_");
    mrn = key[0];
    appointmentRefNumber = key[1];
    return {
      mrn,
      appointmentRefNumber
    };
  }
  if (eskey) {
    // key = atob(key);
    eskey = eskey.split("_");
    patientId = eskey[0];
    surveyId = eskey[1];
    return {
      patientId,
      surveyId
    };
  }
  
};

export default connect(
  data,
  actions
)(AnonymousAppointmentScreeningContainer);
