import React, { Component } from "react";
import Text from "../Text";
import Messages from "../../Shared/Messages";

class MPSTextAreaWithCounters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...MPSTextAreaWithCounters.computeStateFromProps(props),
      limitReached: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.text) {
      return MPSTextAreaWithCounters.computeStateFromProps(props);
    }
    return null;
  }

  static computeStateFromProps(props) {
    const text = props.value || "";
    return {
      text: text,
      charCount: text.length,
      wordCount: text.split(/\s+/).filter(Boolean).length,
      lineCount: text.split(/\n/).length,
    };
  }

  handleChange = (e) => {
    const newText = e.target.value;
    const newLineCount = newText.split(/\n/).length;
    const newCharCount = newText.length;
    const newWordCount = newText.split(/\s+/).filter(Boolean).length;
    const {noOfLinesInMultiline, noOfWordsInMultiline} = this.props;
    if (
      newLineCount > noOfLinesInMultiline ||
      newWordCount > noOfWordsInMultiline
    ) {
      this.setState({ limitReached: true });
      e.preventDefault();
      return;
    }

    this.setState({
      text: newText,
      charCount: newCharCount,
      wordCount: newWordCount,
      lineCount: newLineCount,
      limitReached: false,
    });

    this.props.onChange(e);
  };

  handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const newText = this.state.text + pastedText;
    const newLineCount = newText.split(/\n/).length;
    const newWordCount = newText.split(/\s+/).filter(Boolean).length;
    const {noOfLinesInMultiline, noOfWordsInMultiline} = this.props;

    if (
      newLineCount > noOfLinesInMultiline ||
      newWordCount > noOfWordsInMultiline
    ) {
      e.preventDefault(); // Stop the paste from happening
      this.setState({ limitReached: true });
      return;
    }

    this.setState({ limitReached: false });
  };

  render() {
    const { text, wordCount, lineCount, limitReached } = this.state;
    const { noOfLinesInMultiline, noOfWordsInMultiline} = this.props;
    return (
      <div>
        <textarea
          value={text}
          onPaste={this.handlePaste}
          onChange={this.handleChange}
          className="form-control mb-1 survey-question-multiline"
          rows={6}
        />
        <div
          className="text-counter"
          style={{ fontSize: "0.85em", color: "gray" }}
        >
          <Text content={Messages.words}/> : {wordCount}/{noOfWordsInMultiline} |
          <Text content={Messages.lines}/>: {lineCount}/{noOfLinesInMultiline}
        </div>
        {limitReached && (
          <div style={{ color: "red", fontWeight: "bold" }}><Text content={Messages.limit_reached}/></div>
        )}
      </div>
    );
  }
}

export default MPSTextAreaWithCounters;
